import React, { Component } from "react";
import styled from "styled-components";
const MarqueeContainer = styled.div`
  width: 100%;
  min-width: 1240px;
  height: 60px;
`;
const MaraqueeItems = styled.div`
  width: 100%;
  white-space: nowrap;
  height: 40px;
  font-size: 20px;
  color: #ffffff;
  float: right;
`;
const MaraqueeText = styled.div`
  background-image: linear-gradient(transparent, #1c2282);
`;
class Marquee extends Component {
  render() {
    return (
      <MarqueeContainer>
        <MaraqueeItems>
          <marquee>
            {this.props.marquee.map((row, index) => {
              return (
                <MaraqueeText
                  key={index}
                  style={{
                    display: "inline",
                    whiteSpace: "nowrap",
                    wordBreak: "keep-all",
                    overflow: "visible",
                    marginRight: "40px",
                  }}
                >
                  {row}
                </MaraqueeText>
              );
            })}
          </marquee>
        </MaraqueeItems>
      </MarqueeContainer>
    );
  }
}
export default Marquee;
