import React, { Component } from "react";
import styled from "styled-components";
import GuestContainer from "./GuestContainer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { accountAction } from "../../../actions/accountAction";
import LOGOPNG from "../../../../src/assets/images/logo.png";
const Appbar = styled.div`
  display: block;
  width: 100%;
  min-width: 750px;
  background-color: rgba(17, 48, 102, 0.9);
  height: 85px;
  margin: auto 0;
  display: flex;
  justify-content: center;
`;
const AppbarContainer = styled.div`
  width: 70%;
  min-width: 750px;
  height: 100%;
`;
const LogoContainer = styled.div`
  width: 250px;
  float: left;
  height: 100%;
`;
const NavLogo = styled.span`
  width: 222px;
  height: 85px;
  background-position: left center;
  background-size: auto 80%;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  cursor: pointer;
  background-image: url(${LOGOPNG});
`;
const LogoutButton = styled.div`
  margin-right: 20px;
  color: #0a2484;
  font-size: 26px;
  cursor: pointer;
  width: 80px;
  height: 40px;
  background-color: #44e9fd;
  text-align: center;
  line-height: 40px;
  font-weight: 600;
  transition: 0.3s;
  &:hover {
    background-color: #03bfd6;
    color: #34468a;
  }
`;
const LoginContainer = styled.div`
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
`;
const AccountButton = styled.div`
  color: #fff;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  margin-left: 10px;
  &:hover {
    color: #3cd1e6;
  }
`;
const AccountText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 26px;
  letter-spacing: 1px;
`;
const AccountTextLabel = styled(AccountText)`
  margin-right: 10px;
  margin-left: 5px;
`;
class NavBar extends Component {
  onhandleLogoClick = () => {
    this.props.history.push("/");
  };
  onhandleRoute = (route) => {
    this.props.history.push(route);
  };
  handleLogoutClick = () => {
    this.props.accountLogout();
    this.props.history.push("/login");
  };
  render() {
    return (
      <Appbar>
        <AppbarContainer>
          <LogoContainer>
            <NavLogo onClick={this.onhandleLogoClick}></NavLogo>
          </LogoContainer>
          <LoginContainer>
            {this.props.token ? (
              <>
                <AccountText>Hi 用户:</AccountText>
                <AccountButton
                  onClick={() => {
                    this.onhandleRoute("/account");
                  }}
                >
                  {this.props.username}
                </AccountButton>
                <AccountTextLabel>,您好</AccountTextLabel>
                {/* <Splitter></Splitter> */}
                <LogoutButton
                  onClick={() => {
                    this.handleLogoutClick();
                  }}
                >
                  登出
                </LogoutButton>
              </>
            ) : (
              <GuestContainer></GuestContainer>
            )}
          </LoginContainer>
        </AppbarContainer>
      </Appbar>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    username: state.account.username,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    accountLogout: (token) => dispatch(accountAction.accountLogout(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
