import React, { Component } from "react";
import styled from "styled-components";
import Board from "./Board";
import Player from "./Player";
import Banner from "./Banner";
import { PredictService } from "../../../services/PredictService";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const Container = styled.div`
  display: block;
  width: 750px;
  margin: 0 auto 100px auto;
  height: auto;
  min-height: 200px;
`;

class Home extends Component {
  state = {
    list: [],
    checkBannerValid: false,
  };
  componentDidMount() {
    PredictService.GetGuruPredictList(this.props.token, "").then((response) => {
      if (response.IsSuccess) {
        this.setState({
          list: response.data.slice(0, 3),
        });
      }
    });
    PredictService.GetMyPredictList(this.props.token, "").then((response) => {
      if (response.IsSuccess) {
        if (response.data.length >= 2) {
          this.setState({ checkBannerValid: true });
        }
      }
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  render() {
    return (
      <Container>
        <Player list={this.state.list}></Player>
        <Board 
          qrcode = {this.props.qrcode}
        // board={this.props.board}
        ></Board>
        {this.state.checkBannerValid && this.props.token ? (
          <Banner
            banner={this.props.banner}
            handleOpenBanner={this.props.handleOpenBanner}
            handleCloseBanner={this.props.handleCloseBanner}
          ></Banner>
        ) : (
          <></>
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(mapStateToProps, null)(withRouter(Home));
