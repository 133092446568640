import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Player from "../Home/Player";
import Dialog from "@material-ui/core/Dialog";
import { connect } from "react-redux";
import Config from "../../../config/config";
import CloseIcon from "@material-ui/icons/Close";
import { PredictService } from "../../../services/PredictService";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DefaultIcon from "../../../../src/assets/images/default-icon.png";
import Prediction_BG1 from "../../../../src/assets/images/prediction_bg01.jpg";
import Prediction_BG3 from "../../../../src/assets/images/prediction_bg03.jpg";
export const moveFromTop = keyframes`
  from {
    -webkit-transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0%); }
`;
const CloseIconContainer = styled(CloseIcon)`
  float: right;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const Close = styled.div`
  position: absolute;
  z-index: 22;
  right: 5px;
`;
const StyledTabs = withStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      height: "60px",
      borderBottom: "3px solid #fff",
      color: "#fff",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#fc442a",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#fc442a",
      height: "5px",
    },
  },
}))(Tabs);
const StyledTab = withStyles((theme) => ({
  root: {
    fontFamily: "PingFangSC-Medium",

    fontSize: "28px",
  },
}))(Tab);
const Container = styled.div`
  width: 1200px;
  margin: 0px auto 100px auto;
  position: relative;
`;
const ItemContainer = styled.div`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 60px;
  min-height: 400px;
`;
const ScoreImage = styled.img`
  width: 150px;
  height: 150px;
  vertical-align: middle;
`;
const MatchImage = styled.img`
  transition: all 0.5s ease-out;
  vertical-align: middle;
  background-size: cover;
  width: 100%;
  min-width: 1200px;
  height: 400px;
`;
const MatchContainer = styled.div`
  margin: 0 auto 40px auto;
  width: 100%;
  min-width: 1200px;
  min-height: 400px;
  position: relative;
  display: block;
  overflow: hidden;
  animation: ${moveFromTop} 800ms ease;
  &:before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: all 1s ease-in;
    transition: all 1s ease-in;
  }
  &:hover:before {
    opacity: 0;
  }
  &:hover ${MatchImage} {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
  &:hover ${ScoreImage} {
    -webkit-transition: transform 0.8s;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform: rotateX(360deg);
    transform: rotateY(360deg);
  }
`;
const MatchDetails = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
`;
const Score = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: visible;
`;
const ScoreMark = styled.div`
  width: 300px;
  margin: 6.2% 0;
`;
const ScoreLeft = styled(ScoreMark)`
  float: left !important;
`;
const ScoreRight = styled(ScoreMark)`
  float: right !important;
`;
const ScoreImageContainer = styled.div`
  width: 100%;
  height: 150px;
  text-align: center;
  line-height: 82px;
`;
const TeamName = styled.span`
  display: block;
  text-align: center;
  line-height: 35px;
  color: #fff;
  width: 150px;
  margin: 20px auto 0 auto;
  font-size: 20px;
  height: 33px;
  background-color: #000000;
`;
const ScoreCenter = styled.div`
  margin: 0 auto;
  display: block;
  width: 60%;
  min-width: 600px;
  float: left !important;
`;
const DateTime = styled.div`
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  font-weight: 700;
  width: 550px;
  margin: 10px auto 0 auto;
`;
const NextButton = withStyles((theme) => ({
  root: {
    background: "#fff",
    border: 0,
    borderRadius: 0,
    fontWeight: 700,
    fontSize: "16px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "#c25864",
    height: 50,
    padding: "0 70px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.67)",
    },
    "&:disabled": {
      color: "#312e2e",
      cursor: "default",
      background: "transparent",
      border: "2px solid #4e4a4a",
      boxShadow: "none",
    },
  },
}))(Button);
const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 5px;
  text-align: center;
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0 },
})(Dialog);
const DialogContainer = styled.div`
  width: 900px;
  background-color: #0e1742;
`;
const PlayerContainer = styled.div`
  min-height: 500px;
  width: 880px;
  margin: 60px auto 0px 20px;
`;
const DetailsContainer = styled.div`
  height: 278px;
  margin-top: 30px;
`;
const SplitterHeight = styled.div`
  height: 30px;
`;
const Splitter = styled.div`
  height: 3px;
  width: 384px;
  background-color: #fff;
  margin: 8px auto 0px auto;
`;
const PredictedTitle = styled.div`
  margin: 0px auto 20px auto;
  width: 340px;
  height: 120px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: 120px;
  color: #fff;
`;

class Predict extends Component {
  state = {
    openDialog: false,
    selectedType: 0,
    matchList: [],
    guruList: [],
  };
  handleChangeTab = (e, newValue) => {
    if (newValue !== this.state.selectedType) {
      this.setState(
        { ...this.setState, selectedType: newValue, matchList: [] },
        () => {
          this.GetPredictList(newValue);
        }
      );
    }
  };
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };
  onhandleWatchPlayer = (match_id) => {
    PredictService.GetGuruPredictList(this.props.token, match_id).then(
      (response) => {
        if (response.IsSuccess) {
          this.setState({
            ...this.state,
            guruList: response.data,
            openDialog: true,
          });
        }
      }
    );
  };
  GetPredictList = (type) => {
    PredictService.GetMatchList(this.props.token, type + 1).then((response) => {
      if (response.IsSuccess) {
        // let sortedList = response.data.sort(
        //   (a, b) =>
        //     this.stringToDate(b.match_time) - this.stringToDate(a.match_time)
        // );
        this.setState({
          matchList: response.data.reverse(),
        });
      }
    });
  };
  stringToDate(_date) {
    var formatLowerCase = "mm/dd".toLowerCase();
    var formatItems = formatLowerCase.split("/");
    var SplitDate = _date.split(" ");
    var TimeItems = SplitDate[1].split(":");
    var dateItems = SplitDate[0].split("/");
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      2020,
      month,
      dateItems[dayIndex],
      TimeItems[0],
      TimeItems[1]
    );
    return formatedDate;
  }
  componentDidMount() {
    if (this.props.token === null) {
      this.props.history.push("/login");
    } else {
      this.GetPredictList(0);
    }
  }
  render() {
    return (
      <>
        <Container>
          <StyledTabs
            value={this.state.selectedType}
            onChange={this.handleChangeTab}
            variant="fullWidth"
          >
            <StyledTab index={0} label={"今日赛事"} value={0} />
            <StyledTab index={1} label={"后续赛事"} value={1} />
            <StyledTab index={2} label={"过往赛事"} value={2} />
          </StyledTabs>
          <ItemContainer>
            {this.state.matchList.map((row, index) => {
              return (
                <MatchContainer key={index}>
                  <MatchImage
                    src={
                      row.is_special_guest === 1
                        ? Prediction_BG1
                        : Prediction_BG3
                    }
                  ></MatchImage>
                  <MatchDetails>
                    <Score>
                      <ScoreLeft>
                        <ScoreImageContainer>
                          <ScoreImage
                            src={
                              row.team_a_url
                                ? Config.apiImgSrc + row.team_a_url
                                : DefaultIcon
                            }
                          ></ScoreImage>
                        </ScoreImageContainer>
                        <TeamName>{row.team_a}</TeamName>
                      </ScoreLeft>
                      <ScoreCenter>
                        <DateTime
                          style={
                            row.is_special_guest === 1
                              ? {
                                  backgroundImage:
                                    "linear-gradient(transparent,transparent, rgba(187, 30, 29, 0.3))",
                                }
                              : {
                                  backgroundImage:
                                    "linear-gradient(transparent,transparent, rgba(57, 57, 57, 0.3))",
                                }
                          }
                        >
                          {row.match_time} {row.team_a} vs {row.team_b} Bo
                          {row.bo}
                        </DateTime>
                        <Splitter></Splitter>
                        <DetailsContainer>
                          <SplitterHeight></SplitterHeight>
                          <PredictedTitle>大神推荐</PredictedTitle>
                          <ButtonContainer>
                            {row.is_special_guest === 1 ? (
                              <NextButton
                                onClick={() => {
                                  this.onhandleWatchPlayer(row.match_id);
                                }}
                                variant="outlined"
                              >
                                马上查看
                              </NextButton>
                            ) : (
                              <NextButton variant="outlined" disabled={true}>
                                敬请期待
                              </NextButton>
                            )}
                          </ButtonContainer>
                        </DetailsContainer>
                      </ScoreCenter>
                      <ScoreRight>
                        <ScoreImageContainer>
                          <ScoreImage
                            src={
                              row.team_b_url
                                ? Config.apiImgSrc + row.team_b_url
                                : DefaultIcon
                            }
                          ></ScoreImage>
                        </ScoreImageContainer>
                        <TeamName>{row.team_b}</TeamName>
                      </ScoreRight>
                    </Score>
                  </MatchDetails>
                </MatchContainer>
              );
            })}
          </ItemContainer>
        </Container>
        <StyledDialog
          open={this.state.openDialog}
          onClose={this.onhandleCloseDialog}
          maxWidth="lg"
        >
          <DialogContainer>
            <Close>
              <CloseIconContainer
                viewBox="4 4 16 16"
                onClick={this.onhandleCloseDialog}
              ></CloseIconContainer>
            </Close>
            <PlayerContainer>
              <Player list={this.state.guruList}></Player>
            </PlayerContainer>
          </DialogContainer>
        </StyledDialog>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Predict));
