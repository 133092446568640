import { AccountService } from "../services/AccountService";
export const LOGIN_BEGIN = "LOGIN_BEGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const GETACCOUNT_SUCCESS = "GETACCOUNT_SUCCESS";
export const GETACCOUNT_BEGIN = "GETACCOUNT_BEGIN";
export const GETBALANCE_SUCCESS = "GETBALANCE_SUCCESS";
export const GETACCOUNT_RECEIVER_SUCCESS = "GETACCOUNT_RECEIVER_SUCCESS";
export const GETACCOUNT_FAILURE = "GETACCOUNT_FAILURE";
export const CHECKTOKEN_BEGIN = "CHECKTOKEN_BEGIN";
export const CHECKTOKEN_SUCCESS = "CHECKTOKEN_SUCCESS";
export const CHECKTOKEN_FAILURE = "CHECKTOKEN_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const GETMESSAGE_SUCCESS = "GETMESSAGE_SUCCESS";
export const LOGOUT_BEGIN = "LOGOUT_BEGIN";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const SET_TOKEN = "SET_TOKEN";
export const Set_INITIAL = "Set_INITIAL";
export const accountAction = {
  accountLogin,
  accountLogout,
  registerAccount,
  getProfile,
  setToken,
  setInitial,
};
function setInitial() {
  return async (dispatch) => {
    dispatch({
      type: Set_INITIAL,
      payload: {},
      loading: false,
    });
  };
}
function setToken(token) {
  return async (dispatch) => {
    dispatch({
      type: SET_TOKEN,
      payload: { token: token },
      loading: false,
    });
    return { isSuccess: true };
  };
}
function accountLogin(phone, password) {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_BEGIN,
      loading: true,
    });
    const response = await AccountService.Login(phone, password);
    if (response.IsSuccess) {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response,
        loading: false,
      });
    } else {
      dispatch({
        type: LOGIN_FAILURE,
        payload: response,
        loading: false,
      });
    }
    return response;
  };
}
function accountLogout() {
  return async (dispatch) => {
    localStorage.removeItem("acctoken");
    dispatch({
      type: LOGOUT_SUCCESS,
      payload: {},
      loading: false,
    });
    return {};
  };
}
function getProfile(token) {
  return (dispatch) => {
    const result = AccountService.GetMemberProfile(token)
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: GETACCOUNT_SUCCESS,
            payload: response,
            loading: false,
          });
        } else {
          dispatch({
            type: GETACCOUNT_FAILURE,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETACCOUNT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}
function registerAccount(
  phoneNumber,
  verifyCode,
  password,
  rpassword,
  invite_code,
  email,
  qq
) {
  return async (dispatch) => {
    const result = await AccountService.Register(
      phoneNumber,
      verifyCode,
      password,
      rpassword,
      invite_code,
      email,
      qq
    )
      .then((response) => {
        if (response.IsSuccess) {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response,
            loading: false,
          });
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: GETACCOUNT_FAILURE,
          payload: error,
          loading: false,
        });
      });
    return result;
  };
}
