import React, { Component } from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
const LoadingContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.25);
  z-index: 1100;
  display: none;
`;
const LoadingIconContainer = styled.div`
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
`;
class LoadingService extends Component {
  render() {
    return (
      <LoadingContainer
        style={this.props.show === true ? { display: "block" } : {}}
      >
        <LoadingIconContainer>
          <CircularProgress size={60}></CircularProgress>
        </LoadingIconContainer>
      </LoadingContainer>
    );
  }
}
export default LoadingService;
