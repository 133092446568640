import React, { Component } from "react";
import styled from "styled-components";
import DoubleMarquee from "react-marquee-double";
const MarqueeContainer = styled.div`
  width: 100%;
  min-width: 750px;
  height: 40px;
  margin-top: 20px;
`;
const MaraqueeItems = styled.div`
  width: 100%;
  white-space: nowrap;
  height: 40px;
  font-size: 25px;
  color: #ffffff;
  float: right;
  overflow: hidden;
`;
const MarqueeText = styled.span`
  padding-right: 40px;
`;
class Marquee extends Component {
  render() {
    return (
      <MarqueeContainer>
        <MaraqueeItems>
          {(() => {
            if (this.props.marquee.length === 0) {
              return <></>;
            } else {
              return (
                <DoubleMarquee
                  direction={"left"}
                  autoStart={true}
                  interval={20}
                  step={1}
                  onStart={() => {}}
                >
                  {this.props.marquee.map((row, index) => {
                    return <MarqueeText key={index}>{row}</MarqueeText>;
                  })}
                </DoubleMarquee>
              );
            }
          })()}
        </MaraqueeItems>
      </MarqueeContainer>
    );
  }
}
export default Marquee;
