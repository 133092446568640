import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { connect } from "react-redux";
import Config from "../../../config/config";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import DefaultIcon from "../../../../src/assets/images/default-icon.png";
import Decoration1 from "../../../../src/assets/images/decoration_01.png";
import Decoration2 from "../../../../src/assets/images/decoration_02.png";
import God1 from "../../../../src/assets/images/god_01.png";
import God2 from "../../../../src/assets/images/god_02.png";
import God3 from "../../../../src/assets/images/god_03.png";
import RecommendBG1 from "../../../../src/assets/images/recommend_bg1.jpg";
import RecommendBG2 from "../../../../src/assets/images/recommend_bg2.jpg";
import Time from "../../../../src/assets/images/recommend_time_bg.png";
import convertHtml from "../../../services/ConvertHtml";

export const moveFromLeft = keyframes`
  from {
    -webkit-transform: translateX(-20%); }
  to {
    -webkit-transform: translateX(0%); }
`;
export const moveFromRight = keyframes`
  from {
    -webkit-transform: translateX(20%); }
  to {
    -webkit-transform: translateX(0%); }
`;
export const moveFromLeftIcon = keyframes`
  from {
    -webkit-transform: translateX(-320%); }
  to {
    -webkit-transform: translateX(0%); }
`;
export const moveFromRightIcon = keyframes`
  from {
    -webkit-transform: translateX(320%); }
  to {
    -webkit-transform: translateX(0%); }
`;
export const moveFromTop = keyframes`
  from {
    -webkit-transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0%); }
`;
const IconLeft = styled(CloseIcon)`
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const IconRight = styled(CloseIcon)`
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  left: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 0px 0 10px;
  &:hover {
    cursor: pointer;
  }
`;
const Container = styled.div`
  width: 100%;
`;
const BoardContainer = styled.div`
  margin: 28px auto 20px auto;
  ${(props) =>
    props.active
      ? `
      `
      : `
      overflow: hidden;
      `}
`;
const PlayerContainer = styled.div`
  background-size: cover;
  height: 370px;
  width: 860px;
  margin-bottom: 60px;
  position: relative;
  animation: ${moveFromTop} 800ms ease;
  ${(props) =>
    props.active
      ? ` 
        &:hover ${PlayerImage} {
          transform: scale3d(1.05, 1.05, 1);
          transition: all 0.8s ease-in;
        }
        `
      : `
      filter: blur(10px);
      transform: scale(0.97,0.8);
      `}
`;
const BorderDecoration = styled.div`
  height: 32px;
  width: 132px;
  background-size: cover;
  position: absolute;
`;
const BorderDecorationLeft = styled(BorderDecoration)`
  background-image: url(${Decoration1});
  top: 15px;
  right: 10px;
`;
const BorderDecorationRight = styled(BorderDecoration)`
  background-image: url(${Decoration2});
  bottom: 15px;
  left: 10px;
`;
const PlayerImage = styled.div`
  height: 379px;
  width: 335px;
  background-size: cover;
  position: absolute;
`;
const PlayerImageLeft = styled(PlayerImage)`
  bottom: 20px;
  left: 0px;
`;
const PlayerImageRight = styled(PlayerImage)`
  background-image: url(${God2});
  bottom: 20px;
  right: 0px;
`;
const Dialog = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
`;
const Splitter = styled.div`
  height: 30px;
`;
const PredictDialogContainer = styled.div`
  width: 476px;
  height: 318px;
  position: relative;
  z-index: 20;
`;
const PredictDialogContainerLeft = styled(PredictDialogContainer)`
  margin: 15px 10px 0px 0px;
  background-color: #1a2285;
  float: right;
  animation: ${moveFromLeft} 2000ms ease;
`;
const PredictDialogContainerRight = styled(PredictDialogContainer)`
  margin: 15px 0px 0px 10px;
  background-color: #fc4942;
  float: left;
  animation: ${moveFromRight} 2000ms ease;
`;
const PredictResultContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 245px;
  height: 90px;
`;
const PredictResultContainerLeft = styled(PredictResultContainer)`
  left: 340px;
`;
const PredictResultContainerRight = styled(PredictResultContainerLeft)`
  left: 35px;
`;
const PredictResult = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  width: 300px;
  text-align: left;
`;
const PlayerDetailContainer = styled.div`
  background-color: white;
  position: absolute;
  top: 280px;
  display: flex;
  width: 200px;
  height: 50px;
`;
const PlayerDetailContainerLeft = styled(PlayerDetailContainer)`
  left: 35px;
`;
const PlayerDetailContainerRight = styled(PlayerDetailContainer)`
  right: 35px;
`;
const PlayerTitle = styled.div`
  color: #fff;
  font-size: 35px;
  position: absolute;
  font-weight: 700;
  top: 9px;
`;
const PlayerTitleLeft = styled(PlayerTitle)`
  left: 342px;
`;
const PlayerTitleRight = styled(PlayerTitle)`
  left: 22px;
`;
const PlayerSplitter = styled.div`
  background-color: #fff;
  position: absolute;
  top: 60px;
  height: 3px;
  width: 105px;
`;
const PlayerSplitterLeft = styled(PlayerSplitter)`
  left: 342px;
`;
const PlayerSplitterRight = styled(PlayerSplitter)`
  left: 22px;
`;
const PlayerName = styled.div`
  color: black;
  font-size: 18px;
  font-weight: 700;
  width: 100px;
  margin: auto 0px auto 10px;
`;
const PlayerScore = styled.div`
  color: black;
  font-size: 14px;
  width: 90px;
  margin: auto 5px;
`;
const PredictDateContainer = styled.div`
  background-image: url(${Time});
  background-size: cover;
  position: absolute;
  top: -30px;
  width: 87px;
  height: 157px;
`;
const PredictDateContainerLeft = styled(PredictDateContainer)`
  left: 0px;
`;
const PredictDateContainerRight = styled(PredictDateContainer)`
  left: 25px;
`;
const PredictDate = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin-top: 35px;
  margin-left: 10px;
`;
const PredictTitle = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  margin-left: 10px;
`;
const TeamImage = styled.img`
  width: 150px;
  height: 150px;
  position: absolute;
`;
const TeamContainer = styled.div`
  width: 515px;
  height: 138px;
  position: absolute;
  display: flex;
  background-color: #000a79;
  top: 110px;
  &:hover ${TeamImage} {
    -webkit-transition: transform 0.8s;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform: rotateX(360deg);
    transform: rotateY(360deg);
  }
`;
const TeamContainerLeft = styled(TeamContainer)`
  right: 0px;
`;
const TeamContainerRight = styled(TeamContainer)`
  left: 0px;
`;
const TeamImageLeft = styled(TeamImage)`
  left: 130px;
  top: -25px;
`;
const TeamImageRight = styled(TeamImage)`
  right: 20px;
  top: -25px;
`;
const MatchText = styled.div`
  position: absolute;
  top: 37px;
  left: 293px;
  color: #fff;
  font-size: 30px;
`;
const PlayerButtonContainer = styled.div`
  position: absolute;
  bottom: 37px;
  width: 300px;
  text-align: center;
`;
const PlayerButtonContainerLeft = styled(PlayerButtonContainer)`
  right: 10px;
`;
const PlayerButtonContainerRight = styled(PlayerButtonContainer)`
  left: 245px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "3.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 70,
    fontSize: 22,
    padding: "0 70px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#fff;",
      color: "#fd5555",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const DialogText = styled.div`
  margin: 30px 20px 20px 20px;
  color: #fff;
  font-size: 22px;
  overflow-y: auto;
  height: 220px;
  width: 424px;
  text-align: justify;
  padding-right: 20px;
`;
const LoginText = styled.div`
  position: absolute;
  font-size: 30px;
  z-index: 24;
  color: white;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.9);
  width: 860px;
  height: 371px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  animation: ${moveFromTop} 800ms ease;
`;
const BoxContainer = styled.div`
  position: relative;
`;
const DialogLeftIcon = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-right: 30px solid #1a2285;
  border-bottom: 12px solid transparent;
  position: absolute;
  right: 484px;
  top: 40px;
  -webkit-animation: ${moveFromLeftIcon} 2000ms ease;
`;
const DialogRightIcon = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-left: 30px solid #fc4942;
  border-bottom: 12px solid transparent;
  position: absolute;
  right: 346px;
  top: 40px;
  -webkit-animation: ${moveFromRightIcon} 2000ms ease;
`;
class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: 0,
      openDialog: "",
    };
  }
  ExpandClick = (activity_id) => {
    if (this.state.checked === activity_id) {
      this.setState({ checked: 0 });
    } else {
      this.setState({ checked: activity_id });
    }
  };
  LoginRequiredClick = () => {};
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: "" });
  };
  onhandleSetDialog = (index) => {
    this.setState({ ...this.state, openDialog: index });
  };
  onhandleLogin = () => {
    this.props.history.push("/login");
  };
  render() {
    return (
      <Container>
        <BoardContainer active={this.props.list.length > 2 ? true : false}>
          {this.props.list.map((row, index) => (
            <BoxContainer key={index}>
              {index % 2 === 0 ? (
                <PlayerContainer
                  active={true}
                  style={{
                    backgroundImage: `url(${RecommendBG1})`,
                  }}
                >
                  <BorderDecorationLeft></BorderDecorationLeft>
                  <PlayerImageLeft
                    style={
                      index % 4 === 0
                        ? {
                            backgroundImage: `url( ${God1})`,
                          }
                        : {
                            backgroundImage: `url( ${God3})`,
                          }
                    }
                  ></PlayerImageLeft>
                  <PlayerTitleLeft>大神推荐</PlayerTitleLeft>
                  <PlayerSplitterLeft></PlayerSplitterLeft>
                  <PlayerDetailContainerLeft>
                    <PlayerName>{row.special_guest_name}</PlayerName>
                    <PlayerScore>推荐率: {row.accuracy}</PlayerScore>
                  </PlayerDetailContainerLeft>
                  <PredictResultContainerLeft>
                    <PredictResult key={index}>
                        比赛项目:  {row.race_title}
                    </PredictResult>
                     {row.prediction[0].type === "局一五杀"
                      ? row.prediction.reverse().slice(0, 1).map((value, index) => {
                          return (
                            <PredictResult key={index}>
                              {value.type}: {value.team}
                            </PredictResult>
                          );
                        })
                      : row.prediction.slice(0, 1).map((value, index) => {
                          return (
                            <PredictResult key={index}>
                              {value.type}: {value.team}
                            </PredictResult>
                          );
                        })}
                    {/* {row.prediction[0].type === "局一五杀"
                      ? row.prediction.map((value, index) => {
                          return (
                            <PredictResult key={index}>
                              {value.type}: {value.team}
                            </PredictResult>
                          );
                        })
                      : row.prediction.reverse().map((value, index) => {
                          return (
                            <PredictResult key={index}>
                              {value.type}: {value.team}
                            </PredictResult>
                          );
                        })} */}
                  </PredictResultContainerLeft>
                  <TeamContainerLeft>
                    <PredictDateContainerLeft>
                      <PredictDate>{row.match_time}</PredictDate>
                      <PredictTitle>(BO{row.bo})</PredictTitle>
                    </PredictDateContainerLeft>
                    <TeamImageLeft
                      src={
                        row.team_a_url
                          ? Config.apiImgSrc + row.team_a_url
                          : DefaultIcon
                      }
                    ></TeamImageLeft>
                    <MatchText>VS</MatchText>
                    <TeamImageRight
                      src={
                        row.team_b_url
                          ? Config.apiImgSrc + row.team_b_url
                          : DefaultIcon
                      }
                    ></TeamImageRight>
                  </TeamContainerLeft>
                  <PlayerButtonContainerLeft>
                    <NextButton
                      disabled={!this.props.token && index !== 0}
                      onClick={() => {
                        this.onhandleSetDialog(index);
                      }}
                      variant="outlined"
                    >
                      大神解析
                    </NextButton>
                  </PlayerButtonContainerLeft>
                  {this.state.openDialog === index &&
                  (this.props.token || index === 0) ? (
                    <Dialog>
                      <DialogLeftIcon></DialogLeftIcon>
                      <PredictDialogContainerLeft>
                        <IconLeft
                          viewBox="4 4 16 16"
                          onClick={
                            this.props.token || index === 0
                              ? this.onhandleCloseDialog
                              : () => {}
                          }
                        ></IconLeft>
                        <Splitter></Splitter>
                        <DialogText className="playerDialog">
                          {convertHtml(row.desc)}
                        </DialogText>
                      </PredictDialogContainerLeft>
                    </Dialog>
                  ) : (
                    <></>
                  )}
                  <BorderDecorationRight></BorderDecorationRight>
                </PlayerContainer>
              ) : (
                <PlayerContainer
                  active={this.props.token || index === 0}
                  style={{
                    backgroundImage: `url(${RecommendBG2})`,
                  }}
                >
                  <BorderDecorationLeft></BorderDecorationLeft>
                  <PlayerImageRight></PlayerImageRight>
                  <PlayerTitleRight>大神推荐</PlayerTitleRight>
                  <PlayerSplitterRight></PlayerSplitterRight>
                  <PlayerDetailContainerRight>
                    <PlayerName>{row.special_guest_name}</PlayerName>
                    <PlayerScore>推荐率: {row.accuracy}</PlayerScore>
                  </PlayerDetailContainerRight>
                  <PredictResultContainerRight>
                    <PredictResult key={index}>
                          比赛项目: {row.race_title}
                    </PredictResult>
                    {row.prediction
                      .sort(function (a, b) {
                        if (a.type === "局一五杀") return 1;
                        else return -1;
                      })
                      .slice(0, 1).map((value, index) => {
                        return (
                          <PredictResult key={index}>
                            {value.type}: {value.team}
                          </PredictResult>
                        );
                      })}
                    {/* {row.prediction
                      .sort(function (a, b) {
                        if (a.type === "大局独赢") return 1;
                        else return -1;
                      })
                      .map((value, index) => {
                        return (
                          <PredictResult key={index}>
                            {value.type}: {value.team}
                          </PredictResult>
                        );
                      })} */}
                  </PredictResultContainerRight>
                  <TeamContainerRight>
                    <PredictDateContainerRight>
                      <PredictDate>{row.match_time}</PredictDate>
                      <PredictTitle>(BO{row.bo})</PredictTitle>
                    </PredictDateContainerRight>
                    <TeamImageLeft
                      src={
                        row.team_a_url
                          ? Config.apiImgSrc + row.team_a_url
                          : DefaultIcon
                      }
                    ></TeamImageLeft>
                    <MatchText>VS</MatchText>
                    <TeamImageRight
                      src={
                        row.team_b_url
                          ? Config.apiImgSrc + row.team_b_url
                          : DefaultIcon
                      }
                    ></TeamImageRight>
                  </TeamContainerRight>
                  <PlayerButtonContainerRight>
                    <NextButton
                      disabled={!this.props.token && index !== 0}
                      onClick={() => {
                        this.onhandleSetDialog(index);
                      }}
                      variant="outlined"
                    >
                      大神解析
                    </NextButton>
                  </PlayerButtonContainerRight>
                  {this.state.openDialog === index &&
                  (this.props.token || index === 0) ? (
                    <Dialog>
                      <DialogRightIcon></DialogRightIcon>
                      <PredictDialogContainerRight>
                        <IconRight
                          viewBox="4 4 16 16"
                          onClick={
                            this.props.token || index === 0
                              ? this.onhandleCloseDialog
                              : () => {}
                          }
                        ></IconRight>
                        <Splitter></Splitter>
                        <DialogText className="playerDialog">
                          {convertHtml(row.desc)}
                        </DialogText>
                      </PredictDialogContainerRight>
                    </Dialog>
                  ) : (
                    <></>
                  )}
                  <BorderDecorationRight></BorderDecorationRight>
                </PlayerContainer>
              )}
            </BoxContainer>
          ))}
          {this.props.token || this.props.list.length < 1 ? (
            <></>
          ) : (
            <>
              <BoxContainer>
                <LoginText
                  onClick={() => {
                    this.onhandleLogin();
                  }}
                >
                  欲知更多大神推荐,请登录查看
                </LoginText>
                <PlayerContainer
                  active={false}
                  style={{
                    backgroundImage: `url(${RecommendBG2})`,
                  }}
                >
                  <PlayerImageRight></PlayerImageRight>
                  <PlayerTitleRight>大神推荐</PlayerTitleRight>
                  <PlayerSplitterRight></PlayerSplitterRight>
                  <TeamContainerRight>
                    <TeamImageLeft src={DefaultIcon}></TeamImageLeft>
                    <MatchText>VS</MatchText>
                    <TeamImageRight src={DefaultIcon}></TeamImageRight>
                  </TeamContainerRight>
                </PlayerContainer>
              </BoxContainer>
              <BoxContainer>
                <LoginText
                  onClick={() => {
                    this.onhandleLogin();
                  }}
                >
                  欲知更多大神推荐,请登录查看
                </LoginText>
                <PlayerContainer
                  active={false}
                  style={{
                    backgroundImage: `url(${RecommendBG1})`,
                  }}
                >
                  <PlayerImageLeft
                    style={{
                      backgroundImage: `url( ${God3})`,
                    }}
                  ></PlayerImageLeft>
                  <PlayerTitleLeft>大神推荐</PlayerTitleLeft>
                  <PlayerSplitterLeft></PlayerSplitterLeft>
                  <TeamContainerLeft>
                    <TeamImageLeft src={DefaultIcon}></TeamImageLeft>
                    <MatchText>VS</MatchText>
                    <TeamImageRight src={DefaultIcon}></TeamImageRight>
                  </TeamContainerLeft>
                </PlayerContainer>
              </BoxContainer>
            </>
          )}
        </BoardContainer>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(mapStateToProps, null)(withRouter(Player));
