import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import QRCode from "../../../../src/assets/images/qrcode.png";
import Config from "../../../config/config";
const Container = styled.div`
  width: 670px;
  margin: 0 auto 0px auto;
`;
const BoardContainer = styled.div`
  background-color: rgba(92, 95, 167, 0.52);
  width: 100%;
  margin-top: 20px;
  position: relative;
`;
const Header = styled.div`
  text-align: center;
  padding-top: 30px;
  color: #fff;
  font-size: 30px;
  margin: 25px 20px 20px 20px;
`;
const ItemList = styled.div`
  text-align: center;
  padding-top: 10px;
  color: #fff;
`;
const Item = styled.div`
  font-size: 30px;
  height: 85px;
  margin: 0px 20px;
  line-height: 40px;
`;
const BoardItem = styled.div`
  padding-top: 24px;
`;
const QRContainer = styled.div`
    padding: 60px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const QRImage = styled.img`
  width:580px;
`;
class Board extends Component {
  render() {
    return (
      <Container>
        <BoardContainer>
        <QRContainer>
            <QRImage  src={Config.apiImgSrc+this.props.qrcode}></QRImage>
          </QRContainer>
          {/* <Header>
            <Grid container>
              <Grid item xs={2}>
                排名
              </Grid>
              <Grid item xs={6}>
                名称
              </Grid>
              <Grid item xs={4}>
                推荐率
              </Grid>
            </Grid>
          </Header>
          <ItemList>
            {this.props.board.map((row, index) => {
              return (
                <Item
                  key={index}
                  style={
                    index % 2 === 0
                      ? { backgroundColor: "#172190" }
                      : { backgroundColor: "transparent" }
                  }
                >
                  <Grid container>
                    <Grid item xs={2}>
                      <BoardItem>{row.rank}</BoardItem>
                    </Grid>
                    <Grid item xs={6}>
                      <BoardItem>{row.username}</BoardItem>
                    </Grid>
                    <Grid item xs={4}>
                      <BoardItem> {row.accuracy}</BoardItem>
                    </Grid>
                  </Grid>
                </Item>
              );
            })}
          </ItemList> */}
        </BoardContainer>
      </Container>
    );
  }
}
export default Board;
