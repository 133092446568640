import Config from "../config/config";
import { ApiService } from "./ApiService";
export const WeiboService = {
  WeiboLogin,
  GetToken,
  GetProfile,
  PostImagePoster,
  PostImagePredict,
};
function WeiboLogin(redPath) {
  if(Config.from==="m"){
    window.open(
      Config.weiboUrl +
        "/2/oauth2/authorize?client_id=" +
        Config.weiboClientID +
        "&redirect_uri=" +
        Config.baseUrl +
        redPath +
        "&response_type=code",
      ""
    );
  }
  else{
  window.open(
    Config.weiboUrl +
      "/2/oauth2/authorize?client_id=" +
      Config.weiboClientID +
      "&redirect_uri=" +
      Config.baseUrl +
      redPath +
      "&response_type=code",
    "_self"
  );
  }
}
function GetToken(code) {
  const result = ApiService.post("/getToken", {
    code: code,
    redirectUrl: Config.baseUrl,
    weiboClientID: Config.weiboClientID,
    weiboClientSecret: Config.weiboClientSecret
  });

  return result;
}

function GetProfile(token, uid) {
  const result = ApiService.post("/getProfile", {
    token: token,
    uid: uid,
    weiboClientID: Config.weiboClientID,
    weiboClientSecret: Config.weiboClientSecret
  });

  return result;
}

function PostImagePredict(
  token,
  weiboID,
  weiboImg,
  profileName,
  phoneNum,
  date,
  result2,
  text,
  winRate,
  matchName,
  winMatch,
  matchEnd,
  profileID,
  matchID,
  linkUrl
) {
  const result = ApiService.post("/postImagePredict", {
    token: token,
    weiboID: weiboID,
    weiboImg: weiboImg,
    profileName: profileName,
    phoneNum: phoneNum,
    date: date,
    result2: result2,
    text: text,
    winRate: winRate,
    winMatch: winMatch,
    matchName: matchName,
    matchEnd: matchEnd,
    profileID: profileID,
    matchID: matchID,
    redirectUrl: linkUrl,
    weiboClientID: Config.weiboClientID,
    weiboClientSecret: Config.weiboClientSecret
  });
  return result;
}

function PostImagePoster(
  token,
  weiboID,
  weiboImg,
  profileName,
  phoneNum,
  text,
  winRate,
  evaluate,
  profileID,
  linkUrl
) {
  const result = ApiService.post("/postImagePoster", {
    token: token,
    weiboID: weiboID,
    weiboImg: weiboImg,
    profileName: profileName,
    phoneNum: phoneNum,
    text: text,
    winRate: winRate,
    evaluate: evaluate,
    profileID: profileID,
    redirectUrl: linkUrl,
    weiboClientID: Config.weiboClientID,
    weiboClientSecret: Config.weiboClientSecret
  });
  return result;
}
