import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { RewardService } from "../../../services/RewardService";
import RewardDialog from "./RewardDialog";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import { accountAction } from "../../../actions/accountAction";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
import AchievementDone from "../../../../src/assets/images/achievement_done.png";
import AchievementNotYet from "../../../../src/assets/images/achievement_notyet.png";
export const fadeIn = keyframes`
  from {
    opacity:0; }
  to {
    -opacity:1; }
`;
const Container = styled.div`
  display: block;
  width: 680px;
  margin: 30px auto 100px auto;
  background-color: #1a2285;
  color: white;
  position: relative;
`;
const Splitter = styled.div`
  height: 6px;
  width: 100px;
  background-color: #fff;
  margin: 10px auto;
`;
const Title = styled.div`
  font-size: 40px;
  padding-top: 40px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const ContainerBorder = styled.img`
  width: 500px;
  height: 25px;
  position: absolute;
  bottom: -27px;
  left: 85px;
`;
const AffiliateContainer = styled.div`
  color: #fff;
  width: 660px;
  height: 650px;
  text-align: center;
  background-color: #040a78;
  margin: 20px auto;
`;
const AchieveList = styled.div`
  width: 630px;
  height: 550px;
  margin: 0 auto;
`;
const AchieveItem = styled.div`
  width: 275px;
  height: 153px;
  float: left;
  margin: 15px 20px 0px 20px;
  background-size: cover;
  animation: ${fadeIn} ease ${(props) => props.calTime};
  ${(props) =>
    props.active
      ? `
      background-image: url(${AchievementDone});
      color: #fff;
    `
      : `
      background-image: url(${AchievementNotYet});
      color: #adaaaa;
    `}
`;
const AchieveText = styled.div`
  text-align: center;
  font-size: 20px;
  height: 75px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Reminder = styled.div`
  color: #8e95ef;
  padding: 20px;
  width: 600px;
  margin: 0 auto;
  text-align: center;
  font-size: 22px;
  line-height: 40px;
`;
const AchieveButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "3.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 75,
    fontSize: 25,
    padding: "0 100px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const StyledDialog = withStyles({
  root: {
    boxShadow: "none",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0, boxShadow: "none" },
})(Dialog);
const AchieveInfo = styled.div`
  width: 300px;
  height: 100px;
  margin: 55px auto 0 auto;
  border: solid 2px #a9a7a7;
`;
const AchieveButtonTitle = styled.div`
  text-align: center;
  color: #a9a7a7;
  padding-top: 15px;
  font-size: 25px;
`;
const AchieveButtonText = styled.div`
  text-align: center;
  color: #a9a7a7;
  padding-top: 5px;
  font-size: 20px;
`;
class AchievementList extends Component {
  state = {
    openDialog: false,
    rewardList: { list: [], receivable: "" },
    rewardData: "",
  };
  componentDidMount() {
    if (this.props.token === null) {
      this.props.history.push("/login");
    } else {
      RewardService.GetRewardList(this.props.token).then((response) => {
        if (response.IsSuccess) {
          this.setState({
            ...this.state,
            rewardList: response.data,
          });
        }
      });
    }
  }
  handleTakeReward = () => {
    RewardService.TakeReward(this.props.token, "1").then(async (response) => {
      if (response.IsSuccess) {
        this.setState({
          rewardData: response.data,
        });
        await this.props.getProfile(this.props.token);
        this.setState({ ...this.state, openDialog: true });
      } else {
        this.props.enqueueSnackbar("领取失败", {
          variant: "error",
        });
      }
    });
  };
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };
  render() {
    return (
      <Container style={{ height: "950px" }}>
        <Title>成就列表</Title>
        <Splitter></Splitter>
        <AffiliateContainer>
          <Reminder>达成全部成就可向客服领取奖励(客服联系方式请见活动说明页)</Reminder>
          <AchieveList>
            {this.state.rewardList.list.map((row, index) => {
              return (
                <AchieveItem
                  active={row.is_achieved === 1}
                  key={index}
                  calTime={(index + 1.8) * 1.1 + "s"}
                >
                  <AchieveText>{row.name}</AchieveText>
                </AchieveItem>
              );
            })}
          </AchieveList>
          {/* {this.state.rewardList.receivable === 1 ? (
            <AchieveButtonContainer>
              <NextButton
                onClick={this.handleTakeReward}
                variant="outlined"
                disabled={
                  this.props.achievement_received === 1
                    ? true
                    : false
                }
              >
                 {this.props.achievement_received === 1
                  ? "已经领取"
                  : "点击领取"}
              </NextButton>
            </AchieveButtonContainer>
          ) : (
            <AchieveInfo>
              <AchieveButtonTitle>条件未达成</AchieveButtonTitle>
              <AchieveButtonText>
                *已达成 {this.state.rewardList.achieved}/6 成就
              </AchieveButtonText>
            </AchieveInfo>
          )} */}
        </AffiliateContainer>
        <ContainerBorder
         src={Decoration04}
        ></ContainerBorder>
        <StyledDialog
          open={this.state.openDialog}
          onClose={this.onhandleCloseDialog}
          fullWidth={true}
          maxWidth={false}
        >
          <RewardDialog
            rewardData={this.state.rewardData}
            onhandleCloseDialog={this.onhandleCloseDialog}
          ></RewardDialog>
        </StyledDialog>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    achievement_received: state.account.achievement_received
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(AchievementList)));
