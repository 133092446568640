
export const SHOW_REGISTER = "SHOW_REGISTER";

  const initialState = {
    showRegister: false,
  };
  
  export function uiReducer(state = initialState, action){
    switch (action.type) {
      case SHOW_REGISTER:
        return {
          ...state,
          showRegister: action.payload
        };
      default:
        return state;
    }
  }
  