import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
const HeaderBar = styled.div`
  width: 100%;
  height: 90px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const ContainerWrap = styled.div`
  width: 650px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 650px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
  min-height: 500px;
`;
const Title = styled.div`
  font-size: 40px;
  padding-top: 30px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 6px;
  width: 100px;
  background-color: #fff;
  margin: 10px auto;
`;
const TableContainer = styled.div`
  user-select: text;
  width: 100%;
  margin-top: 50px;
  min-height: 340px;
`;
const Row = styled.div`
  height: 200px;
  padding-top: 40px;
`;
const Column = styled.div`
  width: 100%;
  height: 60px;
`;
const ColumnTitle = styled.div`
  color: #fff;
  width: 120px;
  font-size: 25px;
  float: left;
  padding: 0px 10px 0px 50px;
`;
const ColumnValue = styled.div`
  color: #fff;
  font-size: 25px;
  float: left;
`;
const BottomPagination = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
}))(Pagination);

const BoardImageBottom = styled.img`
  width: 500px;
  height: 25px;
  margin-left: 80px;
`;
const PaginationContainer = styled.div`
  padding-top: 20px;
  height: 100px;
  width: 650px;
`;
function PrizeRecordList(props) {
  const handleChangePage = (event, newPage) => {
    props.onhandleChangePage(newPage);
  };
  return (
    <ContainerWrap>
      <Container>
        <HeaderBar>
          <Icon viewBox="4 4 16 16" onClick={props.onhandleCloseDialog}></Icon>
          <Title>获奖记录</Title>
          <Splitter></Splitter>
        </HeaderBar>
        <TableContainer>
          {props.rows.map((row, index) => {
            return (
              <Row
                key={index}
                style={
                  index % 2 === 0
                    ? { backgroundColor: "#040a78" }
                    : { backgroundColor: "transparent" }
                }
              >
                <Column>
                  <ColumnTitle>兑换奖品</ColumnTitle>
                  <ColumnValue> {row.title}</ColumnValue>
                </Column>
                <Column>
                  <ColumnTitle>获奖时间</ColumnTitle>
                  <ColumnValue> {row.received_time}</ColumnValue>
                </Column>
                <Column>
                  <ColumnTitle>附注</ColumnTitle>
                  <ColumnValue> {row.remark}</ColumnValue>
                </Column>
              </Row>
            );
          })}
        </TableContainer>
        <PaginationContainer>
            <BottomPagination
              count={props.total}
              page={props.page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              size="small"
              siblingCount={0}
            />
          </PaginationContainer>
      </Container>
      <BoardImageBottom
       src={Decoration04}
      ></BoardImageBottom>
    </ContainerWrap>
  );
}
export default PrizeRecordList;
