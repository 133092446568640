import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import StartPredict from "./StartPredict";
import PredictPost from "./PredictPost";
import { connect } from "react-redux";
import Config from "../../../config/config";
import { PredictService } from "../../../services/PredictService";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { WeiboService } from "../../../services/WeiboService";
import { CommonService } from "../../../services/CommonService";
import DefaultIcon from "../../../../src/assets/images/default-icon.png";
import Prediction_BG1 from "../../../../src/assets/images/prediction_bg01.jpg";
import Prediction_BG2 from "../../../../src/assets/images/prediction_bg02.jpg";
import Prediction_BG3 from "../../../../src/assets/images/prediction_bg03.jpg";
import Decoration1 from "../../../../src/assets/images/decoration_01.png";
import Decoration2 from "../../../../src/assets/images/decoration_02.png";
export const moveFromTop = keyframes`
  from {
    -webkit-transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0%); }
`;
const Container = styled.div`
  width: 1200px;
  margin: 0px auto 100px auto;
  position: relative;
`;
const ItemContainer = styled.div`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 60px;
  min-height: 400px;
`;
const StyledTabs = withStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      height: "60px",
      borderBottom: "3px solid #fff",
      color: "#fff",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#fc442a",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#fc442a",
      height: "5px",
    },
  },
}))(Tabs);
const StyledTab = withStyles((theme) => ({
  root: {
    fontFamily: "PingFangSC-Medium",

    fontSize: "28px",
  },
}))(Tab);
const MatchImage = styled.img`
  transition: all 0.5s ease-out;
  vertical-align: middle;
  background-size: cover;
  width: 100%;
  min-width: 1200px;
  height: 400px;
`;
const ScoreImage = styled.img`
  width: 150px;
  height: 150px;
  vertical-align: middle;
`;
const MatchContainer = styled.div`
  margin: 0 auto 40px auto;
  width: 100%;
  min-width: 1200px;
  min-height: 400px;
  position: relative;
  display: block;
  overflow: hidden;
  animation: ${moveFromTop} 800ms ease;
  &:before {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: all 1s ease-in;
    transition: all 1s ease-in;
  }
  &:hover:before {
    opacity: 0;
  }
  &:hover ${MatchImage} {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
  }
  &:hover ${ScoreImage} {
    -webkit-transition: transform 0.8s;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform: rotateX(360deg);
    transform: rotateY(360deg);
  }
`;
const MatchDetails = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
`;
const Score = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: visible;
`;
const ScoreMark = styled.div`
  width: 300px;
  margin: 6.2% 0;
`;
const ScoreLeft = styled(ScoreMark)`
  float: left !important;
`;
const ScoreRight = styled(ScoreMark)`
  float: right !important;
`;
const ScoreImageContainer = styled.div`
  width: 100%;
  height: 150px;
  text-align: center;
  line-height: 82px;
`;
const TeamName = styled.span`
  display: block;
  text-align: center;
  line-height: 35px;
  color: #fff;
  width: 150px;
  margin: 20px auto 0 auto;
  font-size: 20px;
  height: 33px;
  background-color: #000000;
`;
const ScoreCenter = styled.div`
  margin: 0 auto;
  display: block;
  width: 60%;
  min-width: 600px;
  float: left !important;
`;
const DateTime = styled.div`
  font-size: 24px;
  text-align: center;
  line-height: 50px;
  font-weight: 700;
  width: 550px;
  margin: 10px auto 0 auto;
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0 },
})(Dialog);
const DetailsContainer = styled.div`
  height: 278px;
  margin-top: 30px;
`;
const SplitterHeight = styled.div`
  height: 30px;
`;
const Splitter = styled.div`
  height: 3px;
  width: 384px;
  background-color: #fff;
  margin: 8px auto 0px auto;
`;
const PredictedContainer = styled.div`
  width: 450px;
  height: 195px;
  position: relative;
  border: 1px solid #fff;
  margin: 0px auto 20px auto;
  background-color: rgba(17, 36, 139, 0.49);
`;
const PredictedTitle = styled.div`
  background-color: white;
  position: absolute;
  top: -15px;
  left: 150px;
  width: 150px;
  height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
  color: #403f3f;
`;
const PredictedInfo = styled.div`
  color: #fff;
  font-size: 22px;
  margin-top: 40px;
  text-align: center;
`;
// const PredictedResult = styled.div`
//   color: #ddd9a6;
//   font-size: 20px;
//   margin-top: 15px;
//   justify-content: space-around;
//   display: flex;
// `;
const PredictedResult = styled.div`
  color: #ddd9a6;
  font-size: 20px;
  margin-top: 55px;
`;
const ShareContainer = styled.div`
  color: #fff;
  margin: 30px auto 0px auto;
  width: 140px;
  height: 30px;
  display: flex;
  justify-content: space-around;
`;
const ShareIcon = styled.i`
  color: white;
  cursor: pointer;
`;
const BorderDecorationLeft = styled.div`
  background-image: url(${Decoration1});
  height: 32px;
  width: 132px;
  background-size: cover;
  position: absolute;
  top: 15px;
  right: 10px;
`;
const BorderDecorationRight = styled.div`
  background-image: url(${Decoration2});
  height: 32px;
  width: 132px;
  background-size: cover;
  position: absolute;
  bottom: 15px;
  left: 10px;
`;
// const PredictResult = styled.span`
//   background-color: ${(props) => props.colorAcitve};
//   color: #fff;
//   padding: 8px 8px;
//   width: 190px;
//   text-align: center;
// `;
const PredictLabel = styled.div`
  color: #fff;
  padding: 8px 8px;
  text-align: center;
`;
const PredictResult = styled.div`
  background-color: ${(props) => props.colorAcitve};
  color: #fff;
  padding: 8px 8px;
  width: 190px;
  text-align: center;
  display: inline-block;
`;
class MyPredict extends Component {
  state = {
    show: false,
    openDialog: false,
    selectedType: 0,
    matchList: [],
  };
  componentDidMount() {
    if (this.props.token === null) {
      this.props.history.push("/login");
    } else {
      this.setState({
        show: true,
      });
      var splitUrl = window.location.href.split("/");
      var lastParam = splitUrl[splitUrl.length - 1];
      var checkCodeValues = lastParam.includes("code");
      if (checkCodeValues) {
        this.setState({
          ...this.state,
          openDialog: true,
        });
      }
      this.GetMyPredictList(0);
      this.setState({ show: false });
    }
  }
  handleChangeTab = (e, newValue) => {
    if (newValue !== this.state.selectedType) {
      this.setState(
        { ...this.setState, selectedType: newValue, matchList: [] },
        () => {
          this.GetMyPredictList(newValue);
        }
      );
    }
  };
  getWeiBoProfile = (match_id) => {
    WeiboService.WeiboLogin("/mypredict?matchid=" + match_id);
  };
  handleShareWechat = (match_id) => {
    window.open(
      "/wechatshare?url=" +
        Config.wechatSharUrl +
        "/record/" +
        this.props.id +
        "/" +
        match_id,
      ""
    );
    CommonService.SharePostSuccess(this.props.token);
  };
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };
  onhandlePredictPost = () => {
    PredictService.GetmyPredict().then((response) => {
      if (response.IsSuccess) {
        this.setState({
          ...this.state,
          openDialog: true,
        });
      }
    });
  };
  GetMyPredictList = (type) => {
    PredictService.GetMatchList(this.props.token, type + 1).then((response) => {
      if (response.IsSuccess) {
        // response.data.sort(
        //   (a, b) =>
        //     this.stringToDate(b.match_time) - this.stringToDate(a.match_time)
        // );
        this.setState({
          matchList: response.data.reverse(),
        });
      }
    });
  };
  stringToDate(_date) {
    var formatLowerCase = "mm/dd".toLowerCase();
    var formatItems = formatLowerCase.split("/");
    var SplitDate = _date.split(" ");
    var TimeItems = SplitDate[1].split(":");
    var dateItems = SplitDate[0].split("/");
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      2020,
      month,
      dateItems[dayIndex],
      TimeItems[0],
      TimeItems[1]
    );
    return formatedDate;
  }
  render() {
    return (
      <>
        <Container>
          <StyledTabs
            value={this.state.selectedType}
            onChange={this.handleChangeTab}
            variant="fullWidth"
          >
            <StyledTab index={0} label={"今日赛事"} value={0} />
            <StyledTab index={1} label={"后续赛事"} value={1} />
          </StyledTabs>
          <ItemContainer>
            {this.state.matchList.map((row, index) => {
              return (
                <MatchContainer key={index}>
                  <MatchImage
                    src={
                      row.pushable === 1 && row.is_pushed === 2
                        ? Prediction_BG1
                        : row.is_pushed === 1
                        ? Prediction_BG2
                        : Prediction_BG3
                    }
                  ></MatchImage>
                  <BorderDecorationLeft></BorderDecorationLeft>
                  <MatchDetails>
                    <Score>
                      <ScoreLeft>
                        <ScoreImageContainer>
                          <ScoreImage
                            src={
                              row.team_a_url
                                ? Config.apiImgSrc + row.team_a_url
                                : DefaultIcon
                            }
                          ></ScoreImage>
                        </ScoreImageContainer>
                        <TeamName>{row.team_a}</TeamName>
                      </ScoreLeft>
                      <ScoreCenter>
                        <DateTime
                          style={
                            row.pushable === 1 && row.is_pushed === 2
                              ? {
                                  backgroundImage:
                                    "linear-gradient(transparent,transparent, rgba(187, 30, 29, 0.3))",
                                }
                              : row.is_pushed === 1
                              ? {
                                  backgroundImage:
                                    "linear-gradient(transparent,transparent, rgba(3, 21, 121, 0.3))",
                                }
                              : {
                                  backgroundImage:
                                    "linear-gradient(transparent,transparent, rgba(57, 57, 57, 0.3))",
                                }
                          }
                        >
                          {row.match_time} {row.team_a} vs {row.team_b} Bo
                          {row.bo}
                        </DateTime>
                        <Splitter></Splitter>
                        {row.pushable === 1 && row.is_pushed === 2 ? (
                          <DetailsContainer>
                            <StartPredict
                              row={row}
                              selectedType={this.state.selectedType}
                              GetMyPredictList={this.GetMyPredictList}
                            ></StartPredict>
                          </DetailsContainer>
                        ) : row.is_pushed === 1 ? (
                          <DetailsContainer>
                            <SplitterHeight></SplitterHeight>
                            <PredictedContainer>
                              <PredictedTitle>我的应援</PredictedTitle>
                              <PredictedResult>
                              <PredictLabel>
                                比赛项目: {row.race_title}
                              </PredictLabel>
                                {row.my_order
                                  .sort(function (a, b) {
                                    if (a.type_id.toString() === "2") return 1;
                                    else return -1;
                                  })
                                  .slice(0, 1).map((value, index) => {
                                    return (
                                      <PredictLabel  key={index}>
                                        <PredictResult
                                          colorAcitve={
                                            row.predict_result.toString() === "-1" ||
                                            row.match_result[0].team_id.toString() === "-1" ||
                                            row.match_result[1].team_id.toString() === "-1"
                                              ? "transparent"
                                              : row.predict_result.toString() === "1"
                                              ? "#8c000e"
                                              : (value.type_id.toString() ===  row.match_result[0].type_id.toString() &&
                                                  value.team_id.toString() === row.match_result[0].team_id.toString() )
                                                ||
                                                (
                                                value.type_id.toString() ===  row.match_result[1].type_id.toString() &&
                                                value.team_id.toString() === row.match_result[1].team_id.toString() )
                                              ? "#8c000e"
                                              : "#000201"
                                          }
                                        >
                                        {value.team_id.toString()===row.team_a_id.toString()?row.team_a:row.team_b}
                                        </PredictResult>
                                      </PredictLabel>
                                    );
                                  })}
                              </PredictedResult>
                            </PredictedContainer>
                            <ShareContainer>
                              分享到
                              <ShareIcon
                                className="fa fa-weibo"
                                style={{ fontSize: "30px", marginTop: "-5px" }}
                                onClick={() => {
                                  this.getWeiBoProfile(row.match_id);
                                }}
                              ></ShareIcon>
                              <ShareIcon
                                className="fa fa-wechat"
                                style={{ fontSize: "28px", marginTop: "-2px" }}
                                onClick={() => {
                                  this.handleShareWechat(row.match_id);
                                }}
                              ></ShareIcon>
                            </ShareContainer>
                          </DetailsContainer>
                        ) : (
                          <DetailsContainer>
                            <StartPredict
                              disabled={true}
                              row={row}
                            ></StartPredict>
                          </DetailsContainer>
                        )}
                      </ScoreCenter>
                      <ScoreRight>
                        <ScoreImageContainer>
                          <ScoreImage
                            src={
                              row.team_b_url
                                ? Config.apiImgSrc + row.team_b_url
                                : DefaultIcon
                            }
                          ></ScoreImage>
                        </ScoreImageContainer>
                        <TeamName>{row.team_b}</TeamName>
                      </ScoreRight>
                    </Score>
                  </MatchDetails>
                  <BorderDecorationRight></BorderDecorationRight>
                </MatchContainer>
              );
            })}
          </ItemContainer>
        </Container>
        <StyledDialog
          open={this.props.weiboName && this.state.openDialog ? true : false}
          // open={true}
          onClose={this.onhandleCloseDialog}
          maxWidth="lg"
        >
          <PredictPost
            onhandleCloseDialog={this.onhandleCloseDialog}
          ></PredictPost>
        </StyledDialog>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    id: state.account.id,
    username: state.account.username,
    winRate: state.account.winRate,
    weiboName: state.account.weiboName,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MyPredict));
