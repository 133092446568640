import React, { Component } from "react";
import styled from "styled-components";
import Header from "./Header/index";
import { Router, Route, Switch, Redirect } from "react-router";
import { MainHistory } from "../../history/History";
import Home from "./Home";
import ActivityRules from "./ActivityRules";
import MyPredict from "./MyPredict";
import Predict from "./Predict";
import Account from "./Account";
import Reward from "./Reward";
import { connect } from "react-redux";
import { accountAction } from "../../actions/accountAction";
import { weiboAction } from "../../actions/weiboAction";
import Loading from "./Loading";
import Common from "../../utils/Common";
import Footer from "./Footer";
import Login from "./Login";
import Hidden from "@material-ui/core/Hidden";
import { CommonService } from "../../services/CommonService";
import BGIcon1PNG from "../../../src/assets/images/bg_icon_1.png";
import BGIcon2PNG from "../../../src/assets/images/bg_icon_2.png";
import BGIcon3PNG from "../../../src/assets/images/bg_icon_3.png";
import AboutUs from "./AboutUs";
const Main = styled.div`
  display: block;
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  padding-top: 10px;
  overflow: hidden;
  position: relative;
`;
const LeftDecoration = styled.div`
  background-image: url(${BGIcon1PNG});
  height: 636px;
  width: 139px;
  background-size: cover;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
`;
const RightDecoration = styled.div`
  background-image: url(${BGIcon3PNG});
  height: 344px;
  width: 136px;
  background-size: cover;
  position: absolute;
  right: 0px;
  top: 650px;
  z-index: -1;
`;
const DotDecro = styled.div`
  background-image: url(${BGIcon2PNG});
  height: 40px;
  width: 165px;
  background-size: cover;
  position: absolute;
  z-index: -1;
`;
class Web extends Component {
  state = {
    isIpRestrict: true,
    // board: [],
    // marquee: [],
    qrcode: "",
    main_pictures: [],
  };
  updateInterval = null;
  tickCount = 0;
  async componentDidMount() {
    Common.loadScript("/js/config.js", () => {
      Common.loadScript("/js/iovation.js?v=1601548638642", () => {
        // do mail api stuff here
      });
    });
    //get marquee
    // this.callGetMarquee();
    // this.FireTimerToCheckMarquee();
    //get banner
    CommonService.GetBasicElement().then((response) => {
      if (response) {
        if (response.IsSuccess) {
          this.setState({
            // board: response.data.user_rank,
            main_pictures: response.data.banners,
            qrcode: response.data.qr_code?response.data.qr_code.path:""
          });
          this.setState({ ...this.state, isIpRestrict: false });
        }
      }
    });
    const localStorageToken = localStorage.getItem("acctoken");
    if (localStorageToken != null && this.props.token == null) {
      await this.props.setToken(localStorageToken);
      await this.props.getProfile(localStorageToken);
    } else {
      if (this.checkIsInAuthPage()) {
        MainHistory.push("/");
      }
    }
    var splitUrl = window.location.href.split("/");
    var lastParam = splitUrl[splitUrl.length - 1];
    //check promote queryString
    var checkPromoteValues1 = lastParam.includes("?a=");
    var checkPromoteValues2 = lastParam.includes("?u=");
    if (checkPromoteValues1 || checkPromoteValues2) {
      var splitPromoteCode = window.location.href.split("=");
      var promoteCode = splitPromoteCode[splitPromoteCode.length - 1];
      MainHistory.push("/" + promoteCode);
    }
    //check weibo
    var checkCodeValues = lastParam.includes("code");
    if (checkCodeValues) {
      var splitCode = window.location.href.split("=");
      var code = splitCode[splitCode.length - 1];
      const resultWeiboToken = await this.props.getWeiboToken(code);
      if (resultWeiboToken.IsSuccess) {
        const resultWeiboProfile = await this.props.getWeiboProfile(
          resultWeiboToken.data.access_token,
          resultWeiboToken.data.uid
        );
        if (resultWeiboProfile.IsSuccess) {
        } else {
          // alert(resultWeiboProfile.msg.message);
          MainHistory.push("/");
        }
      } else {
        // alert(resultWeiboToken.msg.message);
        MainHistory.push("/");
      }
    }
  }
  checkIsInAuthPage = () => {
    const authPages = ["/predict", "/mypredict", "/account", "/reward"];
    let isInAuthPage = false;
    for (let page of authPages) {
      if (MainHistory.location.pathname.includes(page) === true) {
        isInAuthPage = true;
        break;
      }
    }
    return isInAuthPage;
  };
  // FireTimerToCheckMarquee() {
  //   this.updateInterval = setInterval(() => {
  //     this.callGetMarquee();
  //   }, 60000);
  // }
  // callGetMarquee = () => {
  //   CommonService.GetBroadCast().then((response) => {
  //     if (response) {
  //       if (response.IsSuccess) {
  //         this.setState({
  //           marquee: response.data,
  //         });
  //       }
  //     }
  //   });
  // };
  render() {
    return (
      <Router history={MainHistory}>
        {(() => {
          if (this.state.isIpRestrict) {
            return <Loading show={true} opacity={1}></Loading>;
          } else {
            return (
              <>
                <Header
                  // marquee={this.state.marquee}
                  main_pictures={this.state.main_pictures}
                ></Header>
                <Main>
                  <Hidden lgDown={true}>
                    <DotDecro style={{ top: "6px", left: "0px" }}></DotDecro>
                    <LeftDecoration></LeftDecoration>
                    <DotDecro style={{ top: "1000px", left: "0px" }}></DotDecro>
                  </Hidden>
                  <Switch>
                    {/* <Route
                      exact
                      path={"/"}
                      render={(props) => <Home 
                        // board={this.state.board}
                           qrcode={this.state.qrcode} 
                        />}
                    /> */}
                    <Route exact path={"/rules"} component={ActivityRules} />
                    {/* <Route exact path={"/predict"} component={Predict} /> */}
                    <Route exact path={"/mypredict"} component={MyPredict} />
                    <Route exact path={"/account"} component={Account} />
                    <Route exact path={"/reward"} component={Reward} />
                    <Route exact path={"/aboutus"} component={AboutUs} />
                    <Route
                      exact
                      path={["/login", "/:code", "/"]}
                      render={(props) => <Login />}
                    />
                    <Redirect to={"/"} />
                  </Switch>
                  <Hidden lgDown={true}>
                    <DotDecro style={{ top: "165px", right: "0px" }}></DotDecro>
                    <RightDecoration></RightDecoration>
                    <DotDecro
                      style={{ top: "1200px", right: "0px" }}
                    ></DotDecro>
                  </Hidden>
                </Main>
                <Footer></Footer>
              </>
            );
          }
        })()}
      </Router>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (token) => dispatch(accountAction.setToken(token)),
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
    getWeiboToken: (code) => dispatch(weiboAction.getWeiboToken(code)),
    getWeiboProfile: (token, uid) =>
      dispatch(weiboAction.getWeiboProfile(token, uid)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Web);
