import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
import Decoration03 from "../../../../src/assets/images/decoration_03.png";
import RuleImg2 from "../../../../src/assets/images/rule-img2.png";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { SHOW_REGISTER } from "../../../reducers/uiReducer";

const Container = styled.div`
  display: block;
  width: 1200px;
  margin: 0px auto 100px auto;
  min-height: 1000px;
  background-color: #1a2285;
  color: white;
  position: relative;
`;

const RulesContainer = styled.div`
  color: #fff;
  padding: 0 50px 25px 50px;
  text-align: center;
`;

const RulesTitle = styled.div`
  font-size: 34px;
  padding-top: 20px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 4px;
  width: 80px;
  background-color: #fff;
  margin: 8px auto;
`;
const RulesContent = styled.div`
  padding: 20px 0;
  font-size: 12px;
  text-align: left;
  color: #a5a5a5;
`;

const RuleTitle = styled.div`
  padding-left: 10px;
  font-size: 21px;
  color: #fff;
  margin-bottom: -7px;
`;
const ListItems = styled.ol``;

const ListItem = styled.li`
  margin-bottom: 10px;
  font-size: 21px;
  color: #fff;
`;
const ContainerBorder = styled.img`
  width: 950px;
  height: 35px;
  position: absolute;
  bottom: -35px;
  left: 100px;
`;
const Spin = styled.img`
  width: 85px;
  height: 7px;
  margin-left: 26px;
`;
const RulesImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;
const RuleImages = styled.img`
  width: 700px;
  height: 228px;
`;
const RuleImageText = styled.div`
  text-align: center;
  margin: 20px 0px;
  font-size: 21px;
  color: #fff;
`;
const RegisterButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 80px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 50,
    padding: "0 80px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const SpecialText = styled.span`
  color: #3cd1e6;
`;
class ActivityRules extends Component {
  componentDidMount() {}
  onhandleRoute = (route) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.props.history.push(route);
  };
  handleRegister = () =>{
    this.props.openRegisterModel(true)
    this.props.history.push("/login");
  }
  render() {
    return (
      <Container>
        <RulesContainer>
          <RulesTitle>活动说明</RulesTitle>
          <Splitter></Splitter>
          <RulesContent>
            <RuleTitle> 参与方式</RuleTitle>
            <Spin src={Decoration03}></Spin>
            <ListItems>
              <ListItem>
                用户注册时需填写正确信息并通过验证，才能参加应援并领取奖励。
              </ListItem>
              <ListItem>
                在”我的应援”中，可针对自己喜欢的队伍表达支持并应援。
              </ListItem>
              <ListItem>
                有三个主要成就等待水友完成，完成活动任务即可领取随机<SpecialText>不朽冥灵皮肤</SpecialText>。
              </ListItem>
              <ListItem>
                邀请五名好友、完成三项活动任务各有一项奖励可领取，且只能领取一次，不可重复领取。
              </ListItem>
              <ListItem>
                领奖请加Q群并找管理员或客服进行验证领奖，群号：203333090，客服QQ号：2563015167、1485900655
              </ListItem>
            </ListItems>
            <RulesImageContainer>
                <RuleImages src={RuleImg2}></RuleImages>
            </RulesImageContainer>
            <RuleImageText>应援喜欢的队伍，轻松获取华丽皮肤！</RuleImageText>
            <br />
            <RuleTitle> 注意事项</RuleTitle>
            <Spin src={Decoration03}></Spin>
            <ListItems>
              <ListItem>
                参加者需同意提供正确的个人信息，不可冒用或盗用任何第三人身分信息。
              </ListItem>
              <ListItem>
                本网站保有对活动规则之最终解释权力。如出现不可抗力或情势变更的因素，本网站有权终止或修改活动内容。
              </ListItem>
            </ListItems>
            {!this.props.token&&<RegisterButtonContainer>
              <NextButton onClick={this.handleRegister} variant="outlined">
                立即注册开始应援
              </NextButton>
            </RegisterButtonContainer>}
          </RulesContent>
          <ContainerBorder
           src={Decoration04}
          ></ContainerBorder>
        </RulesContainer>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openRegisterModel: (payload) => {
      dispatch({ type: SHOW_REGISTER, payload });
    },
};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivityRules));

