import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import PrizeRecordList from "./PrizeRecordList";
import AchievementList from "./AchievementList";
import Affiliate from "./Affiliate";
import { connect } from "react-redux";
import { RewardService } from "../../../services/RewardService";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
const Container = styled.div`
  width: 1200px;
  margin: 0px auto 100px auto;
  position: relative;
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "none",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0, boxShadow: "none" },
})(Dialog);

const StyledTabs = withStyles((theme) => ({
  root: {
    "& .MuiTabs-flexContainer": {
      height: "60px",
      borderBottom: "3px solid #fff",
      color: "#fff",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      color: "#fc442a",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#fc442a",
      height: "5px",
    },
  },
}))(Tabs);
const StyledTab = withStyles((theme) => ({
  root: {
    fontFamily: "PingFangSC-Medium",

    fontSize: "28px",
  },
}))(Tab);
class Reward extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      selectedType: 0,
      recordList: { data: [], last_page: 0 },
      page: 1,
      tabClick: false,
    };
  }

  componentDidMount() {
    if (this.props.token === null) {
      this.props.history.push("/login");
    }
    const updateScrollTab = () => {
      if (window.pageYOffset < 600 && !this.state.tabClick) {
        this.setState({ ...this.state, selectedType: 0 });
      } else {
        this.setState({ ...this.state, selectedType: 2 });
      }
      if (window.pageYOffset > 600) {
        this.setState({ ...this.state, tabClick: false });
      }
    };
    window.addEventListener(
      "scroll",
      function () {
        updateScrollTab();
      },
      false
    );
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", () => {}, false);
  }
  handleChangeTab = (e, newValue) => {
    this.setState({ ...this.state, selectedType: newValue });
    if (newValue === 0) {
      window.scrollTo(0, 0);
    }
    if (newValue === 1) {
      this.onhandleWatchPrizeRecord();
    } else if (newValue === 2) {
      window.scrollTo(0, document.body.scrollHeight);
      this.setState({ ...this.state, tabClick: true });
    }
  };
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, selectedType: 0, openDialog: false });
  };
  onhandleWatchPrizeRecord = () => {
    this.watchPrizeRecord();
    this.setState({
      openDialog: true,
    });
  };
  watchPrizeRecord = () => {
    var page = this.state.page;
    var token = this.props.token;
    RewardService.PrizeRecord(token, page, "5").then((response) => {
      if (response.IsSuccess) {
        if (response.data.data)
          response.data.data.sort(
            (a, b) =>
              this.stringToDate(b.received_time) -
              this.stringToDate(a.received_time)
          );
        this.setState({
          ...this.state,
          recordList: response.data,
          page: parseInt(response.data.current_page),
        });
      }
    });
  };
  handleChangePage = (page) => {
    this.setState({ ...this.state, page: page }, () => {
      this.watchPrizeRecord();
    });
  };
  stringToDate(_date) {
    var formatLowerCase = "yyyy-mm-dd".toLowerCase();
    var formatItems = formatLowerCase.split("-");
    var SplitDate = _date.split(" ");
    var TimeItems = SplitDate[1].split(":");
    var dateItems = SplitDate[0].split("-");
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      yearIndex,
      month,
      dateItems[dayIndex],
      TimeItems[0],
      TimeItems[1]
    );
    return formatedDate;
  }
  render() {
    return (
      <Container>
        <StyledTabs
          value={this.state.selectedType}
          onChange={this.handleChangeTab}
          variant="fullWidth"
        >
          <StyledTab index={0} label={"成就一览"} value={0} />
          <StyledTab index={1} label={"获奖记录"} value={1} />
          <StyledTab index={2} label={"好友邀请"} value={2} />
        </StyledTabs>

        <AchievementList></AchievementList>

        <Affiliate></Affiliate>

        <StyledDialog
          open={this.state.openDialog}
          onClose={this.onhandleCloseDialog}
          fullWidth={true}
          maxWidth={false}
        >
          <PrizeRecordList
            page={this.state.page}
            rows={this.state.recordList.data}
            total={this.state.recordList.last_page}
            onhandleChangePage={this.handleChangePage}
            onhandleCloseDialog={this.onhandleCloseDialog}
          ></PrizeRecordList>
        </StyledDialog>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Reward));
