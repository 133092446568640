import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
import Decoration03 from "../../../../src/assets/images/decoration_03.png";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { SHOW_REGISTER } from "../../../reducers/uiReducer";

const Container = styled.div`
  display: block;
  width: 1200px;
  margin: 0px auto 100px auto;
  min-height: 700px;
  background-color: #1a2285;
  color: white;
  position: relative;
`;

const AboutsContainer = styled.div`
  color: #fff;
  padding: 0 50px 25px 50px;
  text-align: center;
`;

const AboutsTitle = styled.div`
  font-size: 34px;
  padding-top: 20px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 4px;
  width: 80px;
  background-color: #fff;
  margin: 8px auto;
`;
const AboutsContent = styled.div`
  padding: 20px 0;
  font-size: 12p;
  text-align: left;
  color: #a5a5a5;
`;

const AboutTitle = styled.div`
  padding-top: 20px;
  width: 900px;
  margin: 0 auto;
`;
const AboutText = styled.div`
  margin-top:20px;
  text-indent: 50px;
  font-size: 21px;
  color: #fff;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 1.6;
`;
const ContainerBorder = styled.img`
  width: 950px;
  height: 35px;
  position: absolute;
  bottom: -35px;
  left: 100px;
`;
const RegisterButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 80px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 50,
    padding: "0 80px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
class AboutUs extends Component {
  componentDidMount() {}
  onhandleRoute = (route) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.props.history.push(route);
  };
  handleRegister = () =>{
    this.props.openRegisterModel(true)
    this.props.history.push("/login");
  }
  render() {
    return (
      <Container>
        <AboutsContainer>
          <AboutsTitle>关于我们</AboutsTitle>
          <Splitter></Splitter>
          <AboutsContent>
            <AboutTitle>
            <AboutText>
              DOTA 2 TI国际邀请赛应援活动旨在推广电竞游戏及DOTA 2比赛给更多的国内水友，支持喜欢的队伍完成应援和简单的任务，即可获得英雄传世及不朽冥灵皮肤，让更多水友进入DOTA2游戏的世界，体验沙场上与队友共同迈向胜利的乐趣。
            </AboutText>
            <AboutText> 
              2020年，电竞赛事受到疫病影响减少举办，但在线观看人数却持续创下新高，表示全球电竞观众数量仍不断激增，且更多水友愈发懂得以赞美及欣赏的文明态度来观赛。
            </AboutText>
            <AboutText> 
              2021年DOTA 2的年度世界大赛，欢迎各位旧雨新欢持续关注DOTA 2 TI国际邀请赛各场赛事，来参加应援活动领取皮肤，就让我们邀请更多好友一起加入DOTA 2的行列吧！
            </AboutText>
            </AboutTitle>
            {!this.props.token&&<RegisterButtonContainer>
              <NextButton onClick={this.handleRegister} variant="outlined">
                立即注册开始应援
              </NextButton>
            </RegisterButtonContainer>}
          </AboutsContent>
          <ContainerBorder
           src={Decoration04}
          ></ContainerBorder>
        </AboutsContainer>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    openRegisterModel: (payload) => {
      dispatch({ type: SHOW_REGISTER, payload });
    },
};
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AboutUs));
