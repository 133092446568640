import { ApiService } from "./ApiService";
import config from "../config/config";
export const AccountService = {
  Login,
  Register,
  GetMemberProfile,
  ForgetPassword,
  RegisterMrCat,
  GetHistoryPredictList,
  ChangePassword,
  GetPhoneVerificationCode,
  GetProfile,
  GetPost,
};
function Login(phone, password) {
  const result = ApiService.post(
    config.api + "/lol_login/login",
    {
      phone: phone,
      password: password,
    },
    true
  );
  result.then((response) => {
    if (response.IsSuccess) {
      localStorage.setItem("acctoken", response.data);
    }
  });

  return result;
}
function GetMemberProfile(token) {
  const result = ApiService.post(
    config.api + "/lol_user/info",
    {
      token,
      race_id: config.raceID,
    },
    true
  );
  return result;
}
function Register(phone, code, password, rpassword, invite_code, email, qq) {
  const result = ApiService.post(
    config.api + "/lol_login/register",
    {
      phone: phone,
      code: code,
      password: password,
      rpassword: rpassword,
      invite_code: invite_code,
      email: email,
      qq: qq
    },
    true
  );
  result.then((response) => {
    if (response.IsSuccess) {
      localStorage.setItem("acctoken", response.data);
    }
  });
  return result;
}
function ForgetPassword(phone, code, password, rpassword) {
  const result = ApiService.post(config.api + "/lol_login/forget", {
    phone: phone,
    code: code,
    password: password,
    rpassword: rpassword,
  });
  return result;
}
function GetHistoryPredictList(token, page, limit) {
  const result = ApiService.post(config.api + "/lol_user/historyOrders", {
    race_id: config.raceID,
    token: token,
    page: page,
    limit: limit,
  });
  return result;
}
function ChangePassword(token, old_password, new_password, rpassword) {
  const result = ApiService.post(config.api + "/lol_login/editPassword", {
    token: token,
    old_password: old_password,
    new_password: new_password,
    rpassword: rpassword,
  });
  return result;
}
function GetPhoneVerificationCode(mobile, event) {
  const result = ApiService.post(config.api + "/lol_login/sendSmsCode", {
    mobile: mobile,
    event: event,
  });
  return result;
}
function RegisterMrCat(token, username, password, rpassword) {
  const result = ApiService.post(config.api + "/lol_login/registerForM", {
    token: token,
    username: username,
    password: password,
    rpassword: rpassword,
    machine: window.IGLOO.getBlackbox().blackbox,
  });
  return result;
}
function GetProfile(user_id) {
  const result = ApiService.post(config.api + "/lol_user/shareInfo", {
    user_id: user_id,
    race_id: config.raceID,
  });
  return result;
}
function GetPost(user_id, match_id) {
  const result = ApiService.post(config.api + "/lol_user/shareOrder", {
    user_id: user_id,
    match_id: match_id,
    race_id: config.raceID,
  });
  return result;
}
