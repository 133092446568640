import {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_FAILURE,
  LOGOUT_BEGIN,
  GETACCOUNT_BEGIN,
  GETACCOUNT_SUCCESS,
  CHECKTOKEN_SUCCESS,
  CHECKTOKEN_BEGIN,
  GETACCOUNT_FAILURE,
  SET_TOKEN,
  Set_INITIAL,
} from "../actions/accountAction";
import {
  RETRIEVEWEIBOPROFILE,
  RETRIEVEWEIBOPROFILE_SUCCESS,
  RETRIEVEWEIBOPROFILE_FAILURE,
  RETRIEVEWEIBOTOKEN,
  RETRIEVEWEIBOTOKEN_SUCCESS,
  RETRIEVEWEIBOTOKEN_FAILURE,
} from "../actions/weiboAction";
const initialState = {
  token: null,
  id: "",
  username: "",
  winRate: "",
  evaluation: "",
  weiboToken: "",
  weiboID: "",
  avatar_url: "",
  weiboName: "",
  weiboImage: "",
  achievement_received: "",
  mrcat_username: "",
  error: null,
};
export function accountReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.data,
      };
    case CHECKTOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.data.token,
      };
    case GETACCOUNT_SUCCESS:
      return {
        ...state,
        id: action.payload.data.user_id,
        username: action.payload.data.username,
        winRate: action.payload.data.accuracy,
        evaluation: action.payload.data.reputation,
        avatar_url: action.payload.data.avatar_url,
        achievement_received: action.payload.data.achievement_received,
        mrcat_username: action.payload.data.mrcat_username,
      };
    case GETACCOUNT_FAILURE:
      return {
        token: initialState.token,
        id: initialState.id,
        username: initialState.phoneNum,
        winRate: initialState.winRate,
        evaluation: initialState.evaluation,
        weiboName: initialState.weiboName,
        avatar_url: initialState.avatar_url,
        weiboImage: initialState.weiboImage,
        weiboToken: initialState.weiboToken,
        achievement_received: initialState.achievement_received,
        mrcat_username: initialState.mrcat_username,
      };
    case LOGOUT_FAILURE:
    case LOGIN_FAILURE:
    case RETRIEVEWEIBOPROFILE_FAILURE:
    case RETRIEVEWEIBOTOKEN_FAILURE:
      return { ...state, error: action.payload };
    case LOGIN_BEGIN:
    case RETRIEVEWEIBOPROFILE:
    case RETRIEVEWEIBOTOKEN:
    case LOGOUT_BEGIN:
    case CHECKTOKEN_BEGIN:
    case GETACCOUNT_BEGIN:
      return { ...state, loading: action.loading };
    case Set_INITIAL:
    case LOGOUT_SUCCESS:
      return {
        token: initialState.token,
        id: initialState.id,
        username: initialState.phoneNum,
        winRate: initialState.winRate,
        evaluation: initialState.evaluation,
        weiboName: initialState.weiboName,
        avatar_url: initialState.avatar_url,
        weiboImage: initialState.weiboImage,
        weiboToken: initialState.weiboToken,
        achievement_received: initialState.achievement_received,
        mrcat_username: initialState.mrcat_username,
      };
    case RETRIEVEWEIBOTOKEN_SUCCESS:
      return {
        ...state,
        weiboToken: action.payload.data.access_token,
        weiboID: action.payload.data.uid,
      };
    case RETRIEVEWEIBOPROFILE_SUCCESS:
      return {
        ...state,
        weiboName: action.payload.data.name,
        weiboImage: action.payload.data.avatar_large,
      };
    default:
      return state;
  }
}
