import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import RankBG from "../../../../src/assets/images/rank_bg.png";
import QRCode from "../../../../src/assets/images/qrcode.png";
import Config from "../../../config/config";
const Container = styled.div`
  width: 100%;
  min-height: 620px;
`;
const BoardContainer = styled.div`
  background-image: url(${RankBG});
  height: 590px;
  width: 300px;
  background-size: cover;
  float: right;
  position: relative;
`;
const Header = styled.div`
  text-align: center;
  padding-top: 70px;
  color: #fff;
  font-size: 20px;
  margin: 0px 20px;
`;
const ItemList = styled.div`
  text-align: center;
  padding-top: 10px;
  color: #fff;
`;
const Item = styled.div`
  font-size: 16px
  height: 42px;
  margin: 0px 20px;
  line-height: 34px;
`;
const BoardItem = styled.div`
    padding: 5px;
`;
const QRContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
`;
const QRImage = styled.img`
  width:280px;
`;
class Board extends Component {
  render() {
    return (
      <Container>
        <BoardContainer>
          <QRContainer>
            <QRImage src={Config.apiImgSrc+this.props.qrcode}></QRImage>
          </QRContainer>
          {/* <Header>
            <Grid container>
              <Grid item xs={2}>
                排名
              </Grid>
              <Grid item xs={6}>
                名称
              </Grid>
              <Grid item xs={4}>
                推荐率
              </Grid>
            </Grid>
          </Header>
          <ItemList>
            {this.props.board
              .sort((a, b) => a.rank - b.rank)
              .map((row, index) => {
                return (
                  <Item
                    key={index}
                    style={
                      index % 2 === 0
                        ? { backgroundColor: "#172190" }
                        : { backgroundColor: "transparent" }
                    }
                  >
                    <Grid container>
                      <Grid item xs={2}>
                        <BoardItem>{row.rank}</BoardItem>
                      </Grid>
                      <Grid item xs={6}>
                        <BoardItem>{row.username}</BoardItem>
                      </Grid>
                      <Grid item xs={4}>
                        <BoardItem> {row.accuracy}</BoardItem>
                      </Grid>
                    </Grid>
                  </Item>
                );
              })}
          </ItemList> */}
        </BoardContainer>
      </Container>
    );
  }
}
export default Board;
