import React, { Component } from "react";
import styled from "styled-components";
import { accountAction } from "../../actions/accountAction";
import { connect } from "react-redux";
import Config from "../../config/config";
import { AccountService } from "../../services/AccountService";
import PopUpShare from "../../../src/assets/images/popup_share_titlebg.jpg";
import PopUpShareBG from "../../../src/assets/images/popup_share_bg_M.jpg";
import DefaultAvatar from "../../../src/assets/images/default-avatar.jpg";
const Container = styled.div`
  width: 750px;
  margin: 0 auto;
  position: relative;
`;
const BackgroundImage = styled.img`
  width: 750px;
`;
const Code = styled.div`
  color: #fff;
  font-size: 50px;
  position: absolute;
  top: 545px;
  left: 250px;
  z-index: 20;
`;
const Percentage = styled.div`
  font-size: 50px;
  position: absolute;
  left: 300px;
  z-index: 20;
  font-weight: 700;
  ${(props) =>
    props.active
      ? `
      color: #00ff63; 
      top: 650px;
      `
      : `
      color: #FFFF66;
      top: 648px;
      `}
`;
const Title = styled.div`
  color: #bfae8b;
  font-size: 38px;
  position: absolute;
  top: 770px;
  z-index: 20;
  font-weight: 700;
  width: 590px;
  left: 78px;
  text-align: center;
`;
const Date = styled.span`
  font-size: 28px;
`;
const RampageContainer = styled.div`
  background-image: url(${PopUpShare});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 950px;
  left: 70px;
  z-index: 20;
  width: 600px;
  text-align: center;
  min-height: 72px;
  background-color: ${(props) => props.colorAcitve};
`;
const Rampage = styled.div`
  color: white;
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  background: -webkit-linear-gradient(#fb6066, #ff7d70, #ffe3a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;
const WinnerContainer = styled.div`
  background-image: url(${PopUpShare});
  background-size: auto;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 1120px;
  left: 70px;
  z-index: 20;
  width: 600px;
  text-align: center;
  height: 82px;
  background-color: ${(props) => props.colorAcitve};
`;
const Winner = styled.div`
  color: white;
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  background: -webkit-linear-gradient(#fb6066, #ff7d70, #ffe3a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;
const UserAvatar = styled.img`
  background-color: white;
  position: absolute;
  height: 280px;
  width: 280px;
  top: 81px;
  border-radius: 50%;
  left: 236px;
`;
const Splitter = styled.div`
  height: 8px;
  width: 185px;
  background-color: #fff;
  margin: 8px auto;
  position: absolute;
  top: 495px;
  left: 277px;
`;
const EvaluateSplitter = styled.div`
  height: 5px;
  width: 566px;
  background-color: #514945;
  margin: 8px auto;
  position: absolute;
  top: 745px;
  left: 90px;
`;
const PosterTitle = styled.div`
  font-size: 56px;
  color: #fff;
  position: absolute;
  left: 230px;
  top: 410px;
  font-weight: 700;
`;
const UserIDLabel = styled.div`
  font-size: 50px;
  color: #fff;
  position: absolute;
  left: 110px;
  top: 545px;
`;
const UserWinRateLabel = styled.div`
  font-size: 50px;
  color: #fff;
  position: absolute;
  left: 110px;
  top: 650px;
`;
class PredictPoster extends Component {
  state = {
    account: "",
  };
  componentDidMount() {
    document.title = "电猫赛讯";
    //check post
    var splitUrl = window.location.href.split("/");
    if (splitUrl.length === 6) {
      if (splitUrl[3].includes("record")) {
        let userid = splitUrl[4];
        let matchId = splitUrl[5];
        this.getProfile(userid, matchId);
      }
    }
  }
  getProfile = (userid, matchId) => {
    AccountService.GetPost(userid, matchId).then((response) => {
      if (response) {
        if (response.IsSuccess && response.data.length > 0) {
          this.setState({
            account: response.data[0],
          });
        }
      }
    });
  };
  render() {
    return (
      <Container>
        <BackgroundImage
          src={PopUpShareBG}
        ></BackgroundImage>
        {this.state.account ? (
          <UserAvatar
            src={
              this.state.account.avatar_url === ""
                ? DefaultAvatar
                : Config.apiImgSrc + this.state.account.avatar_url
            }
          ></UserAvatar>
        ) : (
          <></>
        )}
        <PosterTitle>召唤师手册</PosterTitle>
        <Splitter></Splitter>
        <UserIDLabel>代号:</UserIDLabel>
        <Code>{this.state.account ? this.state.account.username : ""}</Code>
        <EvaluateSplitter></EvaluateSplitter>
        <Title>  
          {this.state.account.race_title}
          <br/>
          <Date>
            {this.state.account ? this.state.account.match_time : ""} (Bo {this.state.account ? this.state.account.bo : ""})
          </Date>
        </Title>
        
        {this.state.account.prediction ? (
          <>
            <RampageContainer
              // colorAcitve={
              //   this.state.account.predict_result.toString() === "-1" ||
              //   this.state.account.match_result[0].team_id.toString() === "-1" ||
              //   this.state.account.match_result[1].team_id.toString() === "-1"
              //     ? "transparent"
              //     : this.state.account.predict_result.toString() === "1"
              //     ? "#8c000e"
              //     : this.state.account.prediction.find(predict => predict.type_id === "2").team_id ===
              //       this.state.account.match_result.find(predict => predict.type_id === "2").team_id
              //     ? "#8c000e"
              //     : "#000201"
              // }
            >
              <Rampage>
               {this.state.account ? this.state.account.team_a : ""} vs {this.state.account ? this.state.account.team_b : ""}
                {/* {this.state.account
                  ? "局一五杀 : "
                  : ""}
                {this.state.account
                  ? this.state.account.prediction.find(predict => predict.type_id === "2").team_id.toString()===this.state.account.team_a_id.toString()?this.state.account.team_a:this.state.account.team_b
                  : ""} */}
              </Rampage>
            </RampageContainer>
            <WinnerContainer
               colorAcitve={
                this.state.account.predict_result.toString() === "-1" ||
                this.state.account.match_result[0].team_id.toString() === "-1" ||
                this.state.account.match_result[1].team_id.toString() === "-1"
                  ? "transparent"
                  : this.state.account.predict_result.toString() === "1"
                  ? "#8c000e"
                  : this.state.account.prediction.find(predict => predict.type_id === "1").team_id ===
                    this.state.account.match_result.find(predict => predict.type_id === "1").team_id
                  ? "#8c000e"
                  : "#000201"
              }
            >
              <Winner>
              {this.state.account
                  ? "我的应援 : "
                  : ""}
                {this.state.account
                  ? this.state.account.prediction.find(predict => predict.type_id === "1").team_id.toString()===this.state.account.team_a_id.toString()?this.state.account.team_a:this.state.account.team_b
                  : ""}
              </Winner>
            </WinnerContainer>
          </>
        ) : (
          <></>
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    weiboName: state.account.weiboName,
    weiboPic: state.account.weiboImage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PredictPoster);
