import React, { Component } from "react";
import styled from "styled-components";
import { accountAction } from "../../actions/accountAction";
import { connect } from "react-redux";
import Config from "../../config/config";
import { AccountService } from "../../services/AccountService";
import PopUpShare from "../../../src/assets/images/popup_share_titlebg.jpg";
import PopUpShareBG from "../../../src/assets/images/popup_share_bg_M.jpg";
import DefaultAvatar from "../../../src/assets/images/default-avatar.jpg";
const Container = styled.div`
  width: 750px;
  margin: 0 auto;
  position: relative;
`;
const BackgroundImage = styled.img`
  width: 750px;
`;
const Code = styled.div`
  color: #fff;
  font-size: 50px;
  position: absolute;
  top: 580px;
  left: 250px;
  z-index: 20;
`;
const Percentage = styled.div`
  position: absolute;
  left: 300px;
  z-index: 20;
  font-weight: 700;
  ${(props) =>
    props.active
      ? `
      top: 693px;
      color: #00ff63; 
      font-size: 90px;
      `
      : `
      top: 715px;
      color: #FFFF66;
      font-size: 60px;
      `}
`;
const EvaluateContainer = styled.div`
  background-image: url(${PopUpShare});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  top: 980px;
  left: 70px;
  z-index: 20;
  width: 600px;
  height: 230px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Evaluate = styled.div`
  color: white;
  text-align: center;
  font-size: 65px;
  font-weight: 700;
  background: -webkit-linear-gradient(
    #fb6066,
    #ff7d70,
    #ffe3a1,
    #fb6066,
    #ff7d70,
    #ffe3a1
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;
const UserAvatar = styled.img`
  background-color: white;
  position: absolute;
  height: 280px;
  width: 280px;
  top: 81px;
  border-radius: 50%;
  left: 236px;
`;
const Splitter = styled.div`
  height: 8px;
  width: 185px;
  background-color: #fff;
  margin: 8px auto;
  position: absolute;
  top: 495px;
  left: 277px;
`;
const EvaluateSplitter = styled.div`
  height: 5px;
  width: 566px;
  background-color: #514945;
  margin: 8px auto;
  position: absolute;
  top: 900px;
  left: 90px;
`;
const PosterTitle = styled.div`
  font-size: 56px;
  color: #fff;
  position: absolute;
  left: 230px;
  top: 410px;
  font-weight: 700;
`;
const UserIDLabel = styled.div`
  font-size: 50px;
  color: #fff;
  position: absolute;
  left: 110px;
  top: 580px;
`;
const UserWinRateLabel = styled.div`
  font-size: 50px;
  color: #fff;
  position: absolute;
  left: 110px;
  top: 730px;
`;
class ProfilePoster extends Component {
  state = {
    account: "",
  };
  componentDidMount() {
    document.title = "电猫赛讯-推荐-点击查看我的应援吧";
    //check profile
    var splitUrl = window.location.href.split("/");
    if (splitUrl.length === 5) {
      if (splitUrl[3].includes("profile")) {
        let userid = splitUrl[4];
        this.getProfile(userid);
      }
    }
  }
  getProfile = (id) => {
    AccountService.GetProfile(id).then((response) => {
      if (response) {
        if (response.IsSuccess) {
          this.setState({
            account: response.data,
          });
        }
      }
    });
  };
  render() {
    return (
      <Container>
        <BackgroundImage
          src={PopUpShareBG}
        ></BackgroundImage>
        {this.state.account ? (
          <UserAvatar
            src={
              this.state.account.avatar_url === ""
                ? DefaultAvatar
                : Config.apiImgSrc + this.state.account.avatar_url
            }
          ></UserAvatar>
        ) : (
          <></>
        )}
        <PosterTitle>召唤师手册</PosterTitle>
        <Splitter></Splitter>
        <UserIDLabel>代号:</UserIDLabel>
        <Code>{this.state.account ? this.state.account.username : ""}</Code>
        <UserWinRateLabel>推荐率: </UserWinRateLabel>
        <Percentage
          active={this.state.account.accuracy === "推荐场次不足" ? false : true}
        >
          {this.state.account ? this.state.account.accuracy : ""}
        </Percentage>
        <EvaluateSplitter></EvaluateSplitter>
        <EvaluateContainer>
          <Evaluate>
            {this.state.account ? this.state.account.reputation : ""}
          </Evaluate>
        </EvaluateContainer>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    weiboName: state.account.weiboName,
    weiboPic: state.account.weiboImage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePoster);
