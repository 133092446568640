import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { connect } from "react-redux";
import Config from "../../../config/config";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { withRouter } from "react-router-dom";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
import DefaultIcon from "../../../../src/assets/images/default-icon.png";
import God1 from "../../../../src/assets/images/god_01.png";
import God2 from "../../../../src/assets/images/god_02.png";
import God3 from "../../../../src/assets/images/god_03.png";
import RecommendBG1 from "../../../../src/assets/images/recommend_bg1_M.jpg";
import RecommendBG2 from "../../../../src/assets/images/recommend_bg2_M.jpg";
import Time from "../../../../src/assets/images/recommend_time_bg.png";
import convertHtml from "../../../services/ConvertHtml";
export const moveFromTop = keyframes`
  from {
    -webkit-transform: translateY(20%); }
  to {
    -webkit-transform: translateY(0%); }
`;
export const IconFromTop = keyframes`
  from {
    -webkit-transform: translateY(580%); }
  to {
    -webkit-transform: translateY(0%); }
`;
const Icon = styled(CloseIcon)`
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
const BoardContainer = styled.div`
  margin: 50px auto 20px auto;
`;
const PlayerContainer = styled.div`
  height: 670px;
  width: 670px;
  background-color: #fb443e;
  margin-bottom: 40px;
  position: relative;
  ${(props) =>
    props.active
      ? ` `
      : `
      -webkit-filter: blur(7px);
      -moz-filter: blur(7px);
      -o-filter: blur(7px);
      -ms-filter: blur(7px);
      filter: blur(7px);
      `}
`;
const PlayerImage = styled.div`
  height: 379px;
  width: 335px;
  background-size: cover;
  position: absolute;
`;
const PlayerImageRight = styled(PlayerImage)`
  background-image: url(${God2});
  top: 0px;
  right: 0px;
`;
const PlayerImageLeft = styled(PlayerImage)`
  top: 0px;
  left: 0px;
`;
const Dialog = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0px;
  top: 0px;
`;
const PredictDialogContainerWrap = styled.div`
  width: 650px;
  height: 430px;
  position: relative;
  margin: 0 auto;
`;
const Splitter = styled.div`
  height: 30px;
`;
const PredictDialogContainer = styled.div`
  width: 650px;
  height: 430px;
  position: relative;
  margin: 110px auto 0px auto;
  background-color: #1a2285;
  animation: ${moveFromTop} 1000ms ease;
`;
const BoardImageBottom = styled.img`
  width: 400px;
  height: 14px;
  margin-left: 80px;
  position: absolute;
  animation: ${IconFromTop} 1000ms ease;
`;
const PredictResultContainer = styled.div`
  position: absolute;
  bottom: 120px;
  width: 550px;
  height: 75px;
  left: 63px;
  display: flex;
  justify-content: space-between;
`;
const PredictResult = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  width: 275px;
  text-align: center;
`;
const PlayerDetailContainer = styled.div`
  background-color: white;
  position: absolute;
  display: flex;
  width: 290px;
  height: 60px;
`;
const PlayerDetailContainerLeft = styled(PlayerDetailContainer)`
  top: 160px;
  left: 30px;
`;
const PlayerDetailContainerRight = styled(PlayerDetailContainer)`
  top: 160px;
  right: 30px;
`;
const PlayerTitle = styled.div`
  color: #fff;
  font-size: 50px;
  position: absolute;
  font-weight: 700;
`;
const PlayerTitleLeft = styled(PlayerTitle)`
  top: 45px;
  right: 60px;
`;
const PlayerTitleRight = styled(PlayerTitle)`
  top: 45px;
  left: 60px;
`;
const PlayerSplitter = styled.div`
  background-color: #fff;
  position: absolute;
  height: 5px;
  width: 140px;
  top: 118px;
`;
const PlayerSplitterLeft = styled(PlayerSplitter)`
  right: 90px;
`;
const PlayerSplitterRight = styled(PlayerSplitter)`
  left: 90px;
`;
const PlayerName = styled.div`
  color: black;
  font-size: 22px;
  font-weight: 700;
  width: 145px;
  margin: auto 0px auto 10px;
`;
const PlayerScore = styled.div`
  color: black;
  font-size: 22px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PredictDateContainer = styled.div`
  width: 95px;
  height: 172px;
  position: absolute;
  top: -25px;
  left: 40px;
  background-image: url(${Time});
  background-size: cover;
`;
const PredictDate = styled.div`
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  text-align: center;
  margin: 25px 0px 0px 10px;
`;
const PredictTitle = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin: 10px 0px 0px 10px;
`;
const TeamContainer = styled.div`
  width: 610px;
  height: 170px;
  position: absolute;
  top: 270px;
  left: 28px;
  display: flex;
`;
const Team = styled.img`
  width: 160px;
  height: 160px;
  position: absolute;
`;
const TeamLeft = styled(Team)`
  top: -25px;
  left: 177px;
`;
const TeamRight = styled(Team)`
  top: -25px;
  left: 400px;
`;
const MatchText = styled.div`
  position: absolute;
  top: 37px;
  left: 348px;
  color: #fff;
  font-size: 30px;
`;
const PlayerButtonContainer = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "3.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 75,
    fontSize: 25,
    padding: "0 100px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const DialogText = styled.div`
  margin: 30px 20px 20px 20px;
  color: #fff;
  font-size: 30px;
  overflow-y: auto;
  height: 320px;
  width: 600px;
  text-align: justify;
  padding-right: 20px;
`;
const LoginText = styled.div`
  position: absolute;
  font-size: 30px;
  z-index: 24;
  color: white;
  left: -10px;
  top: -9px;
  background: rgba(0, 0, 0, 0.9);
  width: 550px;
  height: 688px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding-left: 140px;
`;
const BoxContainer = styled.div`
  position: relative;
`;
class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: 0,
      openDialog: "",
    };
  }
  ExpandClick = (activity_id) => {
    if (this.state.checked === activity_id) {
      this.setState({ checked: 0 });
    } else {
      this.setState({ checked: activity_id });
    }
  };
  LoginRequiredClick = () => {};
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: "" });
  };
  onhandleSetDialog = (index) => {
    this.setState({ ...this.state, openDialog: index });
  };
  onhandleLogin = () => {
    this.props.history.push("/login");
  };
  render() {
    return (
      <Container>
        <BoardContainer>
          {this.props.list.map((row, index) => (
            <BoxContainer key={index}>
              {this.props.token || index === 0 ? (
                <></>
              ) : (
                <LoginText
                  onClick={() => {
                    this.onhandleLogin();
                  }}
                >
                  欲知更多大神推荐,请登录查看
                </LoginText>
              )}
              <PlayerContainer
                active={this.props.token || index === 0}
                style={
                  index % 2 === 0
                    ? {
                        backgroundImage: `url(${RecommendBG1})`,
                      }
                    : {
                        backgroundImage: `url(${RecommendBG2})`,
                      }
                }
              >
                {index % 2 === 0 ? (
                  <>
                    <PlayerImageLeft
                      style={
                        index % 4 === 0
                          ? {
                              backgroundImage: `url( ${God1})`,
                            }
                          : {
                              backgroundImage: `url( ${God3})`,
                            }
                      }
                    ></PlayerImageLeft>
                    <PlayerTitleLeft>大神推荐</PlayerTitleLeft>
                    <PlayerSplitterLeft></PlayerSplitterLeft>
                    <PlayerDetailContainerRight>
                      <PlayerName>{row.special_guest_name}</PlayerName>
                      <PlayerScore>推荐率: {row.accuracy}</PlayerScore>
                    </PlayerDetailContainerRight>
                  </>
                ) : (
                  <>
                    <PlayerImageRight></PlayerImageRight>
                    <PlayerTitleRight>大神推荐</PlayerTitleRight>
                    <PlayerSplitterRight></PlayerSplitterRight>
                    <PlayerDetailContainerLeft>
                      <PlayerName>{row.special_guest_name}</PlayerName>
                      <PlayerScore>推荐率: {row.accuracy}</PlayerScore>
                    </PlayerDetailContainerLeft>
                  </>
                )}
                <TeamContainer
                  style={
                    index % 2 === 0
                      ? { backgroundColor: "#000a79" }
                      : { backgroundColor: "#fb422e" }
                  }
                >
                  <PredictDateContainer>
                    <PredictDate>{row.match_time}</PredictDate>
                    <PredictTitle>(BO{row.bo})</PredictTitle>
                  </PredictDateContainer>
                  <TeamLeft
                    src={
                      row.team_a_url
                        ? Config.apiImgSrc + row.team_a_url
                        : DefaultIcon
                    }
                  ></TeamLeft>
                  <MatchText>VS</MatchText>
                  <TeamRight
                    src={
                      row.team_b_url
                        ? Config.apiImgSrc + row.team_b_url
                        : DefaultIcon
                    }
                  ></TeamRight>
                </TeamContainer>
                <PredictResultContainer>
                <PredictResult key={index}>
                    {row.race_title}
                  </PredictResult>
                  {row.prediction
                    .sort(function (a, b) {
                      if (a.type === "局一五杀") return 1;
                      else return -1;
                    })
                    .slice(0, 1).map((value, index) => {
                      return (
                        <PredictResult key={index}>
                          {value.type}: {value.team}
                        </PredictResult>
                      );
                    })}
                </PredictResultContainer>
                <PlayerButtonContainer>
                  <NextButton
                    onClick={() => {
                      this.onhandleSetDialog(index);
                    }}
                    variant="outlined"
                  >
                    大神解析
                  </NextButton>
                </PlayerButtonContainer>

                {this.state.openDialog === index &&
                (this.props.token || index === 0) ? (
                  <Dialog>
                    <PredictDialogContainerWrap>
                      <PredictDialogContainer>
                        <Icon
                          viewBox="4 4 16 16"
                          onClick={this.onhandleCloseDialog}
                        ></Icon>
                        <Splitter></Splitter>
                        <DialogText className="playerDialog">
                          {convertHtml(row.desc)}
                        </DialogText>
                      </PredictDialogContainer>
                      <BoardImageBottom src={Decoration04}></BoardImageBottom>
                    </PredictDialogContainerWrap>
                  </Dialog>
                ) : (
                  <></>
                )}
              </PlayerContainer>
            </BoxContainer>
          ))}
          {this.props.token || this.props.list.length < 1 ? (
            <></>
          ) : (
            <>
              <BoxContainer>
                <LoginText
                  onClick={() => {
                    this.onhandleLogin();
                  }}
                >
                  欲知更多大神推荐,请登录查看
                </LoginText>
                <PlayerContainer
                  active={false}
                  style={{
                    backgroundImage: `url(${RecommendBG2})`,
                  }}
                >
                  <PlayerImageRight></PlayerImageRight>
                  <PlayerTitleRight>大神推荐</PlayerTitleRight>
                  <PlayerSplitterRight></PlayerSplitterRight>
                  <TeamContainer style={{ backgroundColor: "#fb422e" }}>
                    <TeamLeft src={DefaultIcon}></TeamLeft>
                    <MatchText>VS</MatchText>
                    <TeamRight src={DefaultIcon}></TeamRight>
                  </TeamContainer>
                </PlayerContainer>
              </BoxContainer>
              <BoxContainer>
                <LoginText
                  onClick={() => {
                    this.onhandleLogin();
                  }}
                >
                  欲知更多大神推荐,请登录查看
                </LoginText>
                <PlayerContainer
                  active={false}
                  style={{
                    backgroundImage: `url(${RecommendBG1})`,
                  }}
                >
                  <PlayerImageLeft
                    style={{
                      backgroundImage: `url( ${God3})`,
                    }}
                  ></PlayerImageLeft>
                  <PlayerTitleLeft>大神推荐</PlayerTitleLeft>
                  <PlayerSplitterLeft></PlayerSplitterLeft>
                  <TeamContainer style={{ backgroundColor: "#000a79" }}>
                    <TeamLeft src={DefaultIcon}></TeamLeft>
                    <MatchText>VS</MatchText>
                    <TeamRight src={DefaultIcon}></TeamRight>
                  </TeamContainer>
                </PlayerContainer>
              </BoxContainer>
            </>
          )}
        </BoardContainer>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(mapStateToProps, null)(withRouter(Player));
