import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const BottomNavBarContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width: 750px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.85);
  border-top: 3px solid #1a2285;
  z-index: 999;
`;
const NavItems = styled.ul`
  width: 100%;
  height: 100%;
  margin-block-start: 12px;
  padding-inline-start: 0px;
`;
const NavItem = styled.li`
  position: static;
  float: left;
  width: 25%;
  text-align: center;
  display: block;
  font-size: 20px;
  margin-top: 8px;
`;
const NavText = styled.div`
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  &:hover {
    color: #e6e6e6;
  }
  ${(props) =>
    props.active
      ? `
      color: #3cd1e6;
      &:hover {
        color: #3cd1e6;
      }
    `
      : `
    
    `}
`;
class BottomNavBar extends Component {
  state = {
    activePage: "",
    navBarItem: [
      { name: "我的应援", route: "/mypredict" },
      { name: "活动说明", route: "/rules" },
      { name: "奖励兑换", route: "/reward" },
      { name: "关于我们", route: "/aboutus" },
    ],
  };
  componentDidMount() {
    this.setState({
      activePage: this.props.location.pathname,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activePage !== this.props.location.pathname) {
      this.setState({
        activePage: this.props.location.pathname,
      });
    }
  }
  activePage = this.props.location.pathname;
  onhandleRoute = (route) => {
    this.props.history.push(route);
  };
  render() {
    return (
      <BottomNavBarContainer>
        <NavItems>
          {this.state.navBarItem.map((row, index) => {
            return (
              <React.Fragment key={index}>
                <NavItem>
                  <NavText
                    onClick={() => {
                      this.onhandleRoute(row.route);
                    }}
                    active={this.state.activePage === row.route}
                  >
                    {row.name}
                  </NavText>
                </NavItem>
              </React.Fragment>
            );
          })}
        </NavItems>
      </BottomNavBarContainer>
    );
  }
}
export default connect(null, null)(withRouter(BottomNavBar));
