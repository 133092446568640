import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { accountAction } from "../../../actions/accountAction";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { WeiboService } from "../../../services/WeiboService";
import { withSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import LoadingService from "../../Web/LoadingService";
import { CommonService } from "../../../services/CommonService";
import PopUpShare from "../../../../src/assets/images/popup_share_titlebg.jpg";
import PopUpShareBG from "../../../../src/assets/images/popup_share_bg.png";
import Config from "../../../config/config";
import { RewardService } from "../../../services/RewardService";
const CloseIconContainer = styled(CloseIcon)`
  width: 30px !important;
  height: 30px !important;
  float: right;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const Close = styled.div`
  position: absolute;
  z-index: 22;
  right: 5px;
`;
const ContainerWrap = styled.div`
  width: 600px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 600px;
  height: 850px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;

const PostContainer = styled.div`
  width: 600px;
  min-height: 400px;
  position: relative;
  margin-bottom: 20px;
`;
const ImageContainer = styled.div`
  width: 600px;
  margin: 0 auto;
`;
const UserName = styled.div`
  color: #fff;
  position: absolute;
  top: 320px;
  left: 207px;
  font-size: 25px;
`;
const UserId = styled.div`
  color: #fff;
  position: absolute;
  top: 364px;
  left: 131px;
  font-size: 24px;
`;
const UserWinRate = styled.div`
  position: absolute;
  left: 413px;
  ${(props) =>
    props.active
      ? `
      font-size: 36px;
      font-weight: 700;
      top: 351px;
      color: #00ff63; 
      `
      : `
      top: 361px;
      font-size: 25px;
      font-weight: 600;
      color: #FFFF66;
      `}
`;
const EvaluateContainer = styled.div`
  background-image: url(${PopUpShare});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 443px;
  left: 77px;
  z-index: 20;
  text-align: center;
  width: 440px;
`;
const UserEvaluate = styled.div`
  color: white;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  background: -webkit-linear-gradient(#fb6066, #ff7d70, #ffe3a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  min-height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: 600px;
`;
const TextContainer = styled.div`
  padding: 10px 10px 0px 20px;
`;
const ButtonContainer = styled.div`
  padding: 10px 10px 0px 20px;
`;
const UserAvatar = styled.img`
  background-color: white;
  position: absolute;
  height: 155px;
  width: 155px;
  top: 47px;
  border-radius: 50%;
  left: 223px;
`;
const Splitter = styled.div`
  height: 4px;
  width: 90px;
  background-color: #fff;
  margin: 8px auto;
  position: absolute;
  top: 270px;
  left: 254px;
`;
const EvaluateSplitter = styled.div`
  height: 5px;
  width: 420px;
  background-color: #514945;
  margin: 8px auto;
  position: absolute;
  top: 420px;
  left: 90px;
`;
const PosterTitle = styled.div`
  font-size: 30px;
  color: #fff;
  position: absolute;
  left: 222px;
  top: 230px;
  font-weight: 700;
`;
const UserNameLabel = styled.div`
  font-size: 24px;
  color: #fff;
  position: absolute;
  left: 50px;
  top: 319px;
`;
const UserIDLabel = styled.div`
  font-size: 24px;
  color: #fff;
  position: absolute;
  left: 50px;
  top: 363px;
`;
const UserWinRateLabel = styled.div`
  font-size: 24px;
  color: #fff;
  position: absolute;
  left: 325px;
  top: 363px;
`;
const TextArea = styled.textarea`
  outline: none;
  width: 550px;
  height: 100px;
  resize: none;
  font-size: 20px;
  font-family: "PingFangTC-Medium";
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 50,
    fontSize: 25,
    padding: "0 80px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
class Poster extends Component {
  state = {
    text: "",
    loading: false,
  };
  handleTextChange = (e) => {
    this.setState({ ...this.state, text: e.target.value });
  };
  componentDidMount() {}
  handleUploadPost = () => {
    this.setState({ loading: true });
    const token = this.props.weiboToken;
    const text = this.state.text;
    const weiboID = this.props.weiboID;
    const weiboImg = this.props.weiboPic;
    const profileName = this.props.weiboName;
    const username = this.props.username;
    const id = this.props.id;
    const winRate = this.props.winRate;
    const evaluate = this.props.evaluation;
    var linkUrl = Config.baseUrl
    RewardService.GetAffiliate(this.props.token).then((response) => {
      if (response.IsSuccess) {
        if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "development") {
          linkUrl = response.data.url
        }
        WeiboService.PostImagePoster(
          token,
          weiboID,
          weiboImg,
          profileName,
          username,
          text,
          winRate,
          evaluate,
          id,
          linkUrl
        ).then((response) => {
          if (response.IsSuccess) {
            CommonService.ShareProfileSuccess(this.props.token).then((response) => {
              if (response.IsSuccess) {
                this.props.enqueueSnackbar("分享成功", {
                  variant: "success",
                });
              } else {
                this.props.enqueueSnackbar("系统出错： " + response.msg, {
                  variant: "error",
                });
              }
            });
            this.setState({ loading: false });
            this.props.onhandleClosePosterDialog();
          } else {
            this.setState({ loading: false });
            this.props.enqueueSnackbar("分享失败： " + response.msg, {
              variant: "error",
            });
          }
        });
      }
    });
  };
  render() {
    return (
      <ContainerWrap>
        <Container>
          <LoadingService show={this.state.loading}></LoadingService>
          <PostContainer>
            <Close>
              <CloseIconContainer
                viewBox="4 4 16 16"
                onClick={this.props.onhandleClosePosterDialog}
              ></CloseIconContainer>
            </Close>
            <ImageContainer>
              <PosterTitle>召唤师手册</PosterTitle>
              <Splitter></Splitter>
              <UserNameLabel>召唤师名称:</UserNameLabel>
              <UserName>{this.props.weiboName}</UserName>
              <UserIDLabel>代号:</UserIDLabel>
              <UserId>{this.props.username}</UserId>
              <UserWinRateLabel>推荐率: </UserWinRateLabel>
              <UserWinRate
                active={this.props.winRate === "推荐场次不足" ? false : true}
              >
                {this.props.winRate === "推荐场次不足"
                  ? "推荐场次不足"
                  : this.props.winRate}
              </UserWinRate>
              <EvaluateSplitter></EvaluateSplitter>
              <EvaluateContainer>
                <UserEvaluate>{this.props.evaluation}</UserEvaluate>
              </EvaluateContainer>
              <UserAvatar src={this.props.weiboPic}></UserAvatar>
              <Image src={PopUpShareBG}></Image>
            </ImageContainer>
          </PostContainer>
          <TextContainer>
            <TextArea
              placeholder="说些什么..."
              value={this.state.text}
              onChange={this.handleTextChange}
            ></TextArea>
          </TextContainer>
          <ButtonContainer>
            <NextButton onClick={this.handleUploadPost} variant="outlined">
              确定
            </NextButton>
          </ButtonContainer>
        </Container>
      </ContainerWrap>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    weiboToken: state.account.weiboToken,
    weiboName: state.account.weiboName,
    username: state.account.username,
    id: state.account.id,
    winRate: state.account.winRate,
    evaluation: state.account.evaluation,
    weiboPic: state.account.weiboImage,
    weiboID: state.account.weiboID,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Poster));
