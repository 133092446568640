import React, { Component } from "react";
import styled from "styled-components";
import Swiper from "swiper";
import "swiper/css/swiper.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Config from "../../../config/config";
const SliderContainer = styled.div`
  width: 100%;
  min-width: 1240px;
  font-family: "Microsoft Yahei";
`;
const SwiperWrapper = styled.ul`
`;
const SwiperSlide = styled.li`
  position: relative;
  height: 500px;
  overflow: hidden;
`;
const SwiperImage = styled.img`
  height:100%;
  max-height: 500px;
  display: block;
  max-width: 1920px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  margin: 0 auto;
`;
class Slider extends Component {
  state = {};

  componentDidMount() {
    new Swiper("#lol_slider", {
      loop: true,
      speed: 600,
      autoplay: {
        delay: 6000,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  }

  render() {
    return (
      <SliderContainer>
        <div className="swiper-wrap">
          <div className="swiper discuss-swiper" id="lol_slider">
            <SwiperWrapper className="swiper-wrapper">
              {this.props.main_pictures.sort(function (a, b) {
                      if (a.order_id<b.order_id) return -1;
                      else return 1;
                    }).map((row, index) => {
                return (
                  <SwiperSlide className="swiper-slide" key={index}>
                    <SwiperImage
                      className="swiperImg"
                      alt="Carousel Image"
                      src={Config.apiImgSrc + row.path}
                    />
                  </SwiperSlide>
                );
              })}
            </SwiperWrapper>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </SliderContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(mapStateToProps, null)(withRouter(Slider));
