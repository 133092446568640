import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
const HeaderBar = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const ContainerWrap = styled.div`
  width: 850px;
  height: 400px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 850px;
  height: 350px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const Title = styled.div`
  font-size: 28px;
  padding-top: 40px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 4px;
  width: 80px;
  background-color: #fff;
  margin: 8px auto;
`;
const BoardImageBottom = styled.img`
  width: 650px;
  height: 30px;
  margin-left: 80px;
`;
const RefContainer = styled.div`
  margin-top: 70px;
`;
const RefTitle = styled.div`
  color: #fff;
  text-align: center;
  font-size: 24px;
`;
const RefText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 22px;
  margin-top: 10px;
  user-select: text;
`;
const RefRemind = styled.div`
  color: #3bc1e2;
  text-align: center;
  margin-top: 20px;
  font-size: 16px;
`;
const AchieveButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 35px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 50,
    padding: "0 80px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
function RewardDialog(props) {
  return (
    <ContainerWrap>
      <Container>
        <HeaderBar>
          <Icon viewBox="4 4 16 16" onClick={props.onhandleCloseDialog}></Icon>
          <Title>奖励领取</Title>
          <Splitter></Splitter>
        </HeaderBar>
        <RefContainer>
          <RefTitle>
            恭喜获得{props.rewardData === "" ? "" : props.rewardData.title}
          </RefTitle>
          {/* <RefText>
            卡号 : {props.rewardData === "" ? "" : props.rewardData.card_no}
            &nbsp;&nbsp;&nbsp;
            密码 ：{props.rewardData === "" ? "" : props.rewardData.password}
          </RefText> */}
          <RefRemind>*领奖请加入QQ群719238149，管理员确认成就完成后即会发奖。</RefRemind>
        </RefContainer>
        <AchieveButtonContainer>
          <NextButton onClick={props.onhandleCloseDialog} variant="outlined">
            确定
          </NextButton>
        </AchieveButtonContainer>
      </Container>
      <BoardImageBottom
        src={Decoration04}
      ></BoardImageBottom>
    </ContainerWrap>
  );
}
export default RewardDialog;
