import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import { PredictService } from "../../../services/PredictService";
import { withSnackbar } from "notistack";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
const Container = styled.div`
  width: 100%;
  display: table;
  font-family: "PingFangTC-Medium";
`;
const Dialog = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(80 78 78 / 40%);
  left: 0px;
  top: 0px;
`;
const PredictDialogContainerWrap = styled.div`
  width: 650px;
  height: 470px;
  position: relative;
  margin: 0 auto;
`;
const PredictDialogContainer = styled.div`
  width: 650px;
  height: 470px;
  position: relative;
  margin: 50px auto 0px auto;
  background-color: #1a2285;
`;
const BoardImageBottom = styled.img`
  width: 400px;
  height: 14px;
  margin-left: 80px;
  position: absolute;
`;
const NextButton = withStyles((theme) => ({
  root: {
    background: "#fff",
    border: 0,
    borderRadius: 0,
    fontWeight: 700,
    fontSize: 22,
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "#c25864",
    height: 55,
    padding: "0 60px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.67)",
    },
    "&:disabled": {
      color: "#4e4e4e",
      cursor: "default",
      background: "transparent",
      border: "2px solid #444444",
      boxShadow: "none",
    },
  },
}))(Button);
const ButtonContainer = styled.div`
  width: 100%;
  padding-top: 10px;
  text-align: center;
`;
const Form = styled.div`
  width: 450px;
  margin: 0px auto 0 auto;
`;
const SelectWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
  display: flex;
`;
const SelectLabel = styled.div`
  color: white;
  width: 200px;
  margin-top: 18px;
  font-size: 26px;
`;
const Label = styled.div`
  color: white;
  width: 140px;
  margin-top: 18px;
  font-size: 26px;
`;
const TextLabel = styled.div`
  color: white;
  margin-top: 18px;
  font-size: 26px;
`;
const CustomFormControl = withStyles((theme) => ({
  root: {
    margin: "15px 0 0 0",
    width: "100%",
    float: "left",
    "&:disabled": {
      margin: "0px 0 0 0",
    },
    "& fieldset": {
      border: "none",
    },
    "& fieldset:hover": {
      border: "none",
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
}))(FormControl);
const CustomSelect = withStyles((theme) => ({
  root: {
    "&:focus": {
      border: "none",
    },
    "&:active": {
      border: "none",
    },
  },
  icon: {
    fill: "white",
  },
  select: {
    border: "#fff 2px solid",
    height: "40px",
    direction: "ltr",
    "&:focus": {
      border: "#fff 2px solid",
    },
    "&:active": {
      border: "#fff 2px solid",
    },
    "&:disabled": {
      color: "#4e4e4e",
      border: "#444444 2px solid",
    },
  },
}))(Select);
const Splitter = styled.div`
  height: 30px;
`;
const PredictedContainer = styled.div`
  width: 550px;
  height: 200px;
  position: relative;
  border: 1px solid #fff;
  margin: 40px auto 10px auto;
  background-color: rgba(17, 36, 139, 0.49);
`;
const PredictedTitle = styled.div`
  background-color: white;
  position: absolute;
  top: -28px;
  left: 185px;
  width: 180px;
  height: 55px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 52px;
  color: #403f3f;
`;
// const PredictDetails = styled.div`
//   margin-top: 70px;
//   display: flex;
//   justify-content: space-around;
// `;
const PredictDetails = styled.div`
  margin-top: 70px;
`;
const PredictedInfo = styled.div`
  color: #fff;
  font-size: 30px;
  margin-top: 10px;
  text-align: center;
`;
const PredictRef = styled.div`
  color: #46b3e2;
  font-size: 25px;
  text-align: center;
  margin-top: 20px;
`;
const DialogButton = withStyles((theme) => ({
  root: {
    borderWidth: "3.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 60,
    fontSize: 25,
    padding: "0 80px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const DialogButtonContainer = styled.div`
  padding-top: 40px;
  width: 480px;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
`;
class StartPredict extends Component {
  state = {
    winner: "",
    openDialog: false,
  };
  handleWinnerChange = (e) => {
    this.setState({ winner: e.target.value });
  };
  onhandleStartPredict = () => {
    this.setState({ openDialog: true });
  };
  onhandleClosePredict = () => {
    this.setState({ openDialog: false });
  };
  onhandleConfirmPredict = () => {
    PredictService.StartPredict(
      this.props.token,
      this.props.row.match_id,
      this.state.winner
    ).then((response) => {
      if (response.IsSuccess) {
        this.props.enqueueSnackbar("应援成功", {
          variant: "success",
        });
        this.props.GetMyPredictList(this.props.selectedType);
      } else {
        this.props.enqueueSnackbar("应援失败", {
          variant: "error",
        });
      }
    });
    this.setState({ openDialog: false });
  };
  render() {
    return (
      <Container>
        <Form>
          <SelectWrapper className="select_wrapper">
            <Label>比赛项目 : </Label>
            <TextLabel>{this.props.row.race_title}</TextLabel>
            {/* <CustomFormControl
              variant="outlined"
              disabled={this.props.disabled ? true : false}
            >
              <CustomSelect
                disabled={this.props.disabled ? true : false}
                native
                inputProps={{
                  name: "age",
                }}
                style={{
                  fontSize: "18px",
                  border: "none",
                  color: "#fff",
                }}
                onChange={this.handleRampageChange}
                value={this.state.rampage}
              >
                <option value="" disabled>
                  请选择队伍
                </option>
                <option value={this.props.row.team_a_id}>
                  {this.props.row.team_a}
                </option>
                <option value={this.props.row.team_b_id}>
                  {this.props.row.team_b}
                </option>
              </CustomSelect>
            </CustomFormControl> */}
          </SelectWrapper>
          <SelectWrapper className="select_wrapper">
            <CustomFormControl
              variant="outlined"
              disabled={this.props.disabled ? true : false}
            >
              <CustomSelect
                native
                inputProps={{
                  name: "age",
                }}
                style={{
                  fontSize: "18px",
                  border: "none",
                  color: "#fff",
                }}
                onChange={this.handleWinnerChange}
                disabled={this.props.disabled ? true : false}
                value={this.state.winner}
              >
                <option value="" disabled>
                  请选择队伍
                </option>
                <option value={this.props.row.team_a_id}>
                  {this.props.row.team_a}
                </option>
                <option value={this.props.row.team_b_id}>
                  {this.props.row.team_b}
                </option>
              </CustomSelect>
            </CustomFormControl>
          </SelectWrapper>
        </Form>
        <ButtonContainer>
          <NextButton
            onClick={this.onhandleStartPredict}
            disabled={
              this.props.disabled ||
              this.state.winner === "" 
                ? true
                : false
            }
          >
            确定应援
          </NextButton>
        </ButtonContainer>
        {this.state.openDialog ? (
          <Dialog>
            <PredictDialogContainerWrap>
              <PredictDialogContainer>
                <Splitter></Splitter>
                <PredictedContainer>
                  <PredictedTitle>我的应援</PredictedTitle>
                  <PredictDetails>
                    <PredictedInfo>
                      比赛项目 : {this.props.row.race_title}
                    </PredictedInfo>
                    <PredictedInfo>
                      {this.state.winner.toString() ===
                      this.props.row.team_a_id.toString()
                        ? this.props.row.team_a
                        : this.props.row.team_b}
                    </PredictedInfo>
                  </PredictDetails>
                </PredictedContainer>
                <PredictRef>*一经确定这无法修改</PredictRef>
                <DialogButtonContainer>
                  <DialogButton
                    onClick={this.onhandleClosePredict}
                    variant="outlined"
                  >
                    取消
                  </DialogButton>
                  <DialogButton
                    onClick={this.onhandleConfirmPredict}
                    variant="outlined"
                  >
                    确定
                  </DialogButton>
                </DialogButtonContainer>
              </PredictDialogContainer>
              <BoardImageBottom
               src={Decoration04}
              ></BoardImageBottom>
            </PredictDialogContainerWrap>
          </Dialog>
        ) : (
          <></>
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(StartPredict));
