import { WeiboService } from "../services/WeiboService";
export const RETRIEVEWEIBOPROFILE = "RETRIEVEWEIBOPROFILE";
export const RETRIEVEWEIBOPROFILE_SUCCESS = "RETRIEVEWEIBOPROFILE_SUCCESS";
export const RETRIEVEWEIBOPROFILE_FAILURE = "RETRIEVEWEIBOPROFILE_FAILURE";
export const RETRIEVEWEIBOTOKEN = "RETRIEVEWEIBOTOKEN";
export const RETRIEVEWEIBOTOKEN_SUCCESS = "RETRIEVEWEIBOTOKEN_SUCCESS";
export const RETRIEVEWEIBOTOKEN_FAILURE = "RETRIEVEWEIBOTOKEN_FAILURE";

export const weiboAction = {
  getWeiboToken,
  getWeiboProfile,
};
function getWeiboToken(code) {
  return async (dispatch) => {
    dispatch({
      type: RETRIEVEWEIBOTOKEN,
      loading: true,
    });
    const response = await WeiboService.GetToken(code);
    if (response.IsSuccess) {
      dispatch({
        type: RETRIEVEWEIBOTOKEN_SUCCESS,
        payload: response,
        loading: false,
      });
    } else {
      dispatch({
        type: RETRIEVEWEIBOTOKEN_FAILURE,
        payload: response,
        loading: false,
      });
    }
    return response;
  };
}
function getWeiboProfile(token, uid) {
  return async (dispatch) => {
    dispatch({
      type: RETRIEVEWEIBOPROFILE,
      loading: true,
    });
    const response = await WeiboService.GetProfile(token, uid);
    if (response.IsSuccess) {
      dispatch({
        type: RETRIEVEWEIBOPROFILE_SUCCESS,
        payload: response,
        loading: false,
      });
    } else {
      dispatch({
        type: RETRIEVEWEIBOPROFILE_FAILURE,
        payload: response,
        loading: false,
      });
    }
    return response;
  };
}
