import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import PasswordIconPNG from "../../../assets/images/lock.png";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { AccountService } from "../../../services/AccountService";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
const HeaderBar = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const ContainerWrap = styled.div`
  width: 850px;
  height: 480px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 850px;
  height: 420px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const ChangePasswordForm = styled.div`
  margin: 25px auto;
  width: 630px;
`;
const ChangePasswordTitle = styled.div`
  font-size: 28px;
  padding-top: 20px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 4px;
  width: 80px;
  background-color: #fff;
  margin: 8px auto;
`;
const ChangePasswordButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 50,
    padding: "0 80px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const TextFiledWrap = styled.div`
  width: 520px;
  height: 65px;
`;
const TextLabel = styled.div`
  font-size: 20px;
  color: #fff;
  float: left;
  width: 100px;
  margin: 25px 20px 0px 0px;
`;
const CustomTextField = withStyles({
  root: {
    width: "400px",
    height: "45px",
    margin: 0,
    marginTop: "20px",
    background: "transparent",
    float: "left",

    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      color: "#fff",
      height: "6px",
    },

    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        borderWidth: "1.2px",
        height: "45px",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "1.2px",
        height: "45px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "1.2px",
        height: "45px",
      },
    },
  },
})(TextField);
const ErrorText = styled.div`
  text-align: left;
  height: 30px;
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  width: 400px;
  margin: 10px auto 0 120px;
`;
const BoardImageBottom = styled.img`
  width: 650px;
  height: 30px;
  margin-left: 80px;
`;
class ChangePassword extends Component {
  state = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    errMsg: "",
  };
  handleSubmitChangePassword = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      this.handleChangePasswordClick();
    }
  };
  componentDidMount() {
    window.addEventListener("keydown", this.handleSubmitChangePassword);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleSubmitChangePassword);
  }
  handleOldPasswordChange = (e) => {
    this.setState({ ...this.state, oldPassword: e.target.value });
  };
  handleNewPasswordChange = (e) => {
    this.setState({ ...this.state, newPassword: e.target.value });
  };
  handleConfirmPasswordChange = (e) => {
    this.setState({ ...this.state, confirmPassword: e.target.value });
  };
  handleChangePasswordClick = () => {
    const token = this.props.token;
    const oldPassword = this.state.oldPassword;
    const newPassword = this.state.newPassword;
    const confirmPassword = this.state.confirmPassword;
    if (oldPassword === "") {
      this.setState({ errMsg: "请输入旧密码" });
      return;
    }
    if (newPassword === "") {
      this.setState({ errMsg: "请输入新密码" });
      return;
    }
    if (
      newPassword.length < 6 ||
      newPassword.length > 12 ||
      newPassword.match(/[^A-Za-z0-9]+/g)
    ) {
      this.setState({ valType: false, errMsg: "密码需为6-12位英数区分大小写" });
      return;
    }
    if (confirmPassword === "") {
      this.setState({ errMsg: "请输入新确认密码" });
      return;
    }
    if (newPassword !== confirmPassword) {
      this.setState({ valType: false, errMsg: "密码不相符，请再试一次" });
      return;
    }
    AccountService.ChangePassword(
      token,
      oldPassword,
      newPassword,
      confirmPassword
    ).then((response) => {
      if (response.IsSuccess) {
        this.props.enqueueSnackbar("更改密码成功", {
          variant: "success",
        });
        this.props.onhandleCloseDialog();
      } else {
        this.setState({
          errMsg: response.msg,
        });
      }
    });
  };
  render() {
    return (
      <ContainerWrap>
        <Container>
          <HeaderBar>
            <Icon
              viewBox="4 4 16 16"
              onClick={this.props.onhandleCloseDialog}
            ></Icon>
            <ChangePasswordTitle>更改密码</ChangePasswordTitle>
            <Splitter></Splitter>
          </HeaderBar>
          <ChangePasswordForm>
            <TextFiledWrap>
              <TextLabel>旧密码</TextLabel>
              <CustomTextField
                onChange={this.handleOldPasswordChange}
                value={this.state.oldPassword}
                placeholder="请输入旧密码"
                type="password"
                variant="outlined"
                InputLabelProps={{ shrink: true, focused: false }}
                inputProps={{
                  maxLength: 12,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ height: "26px", width: "30px" }}
                    >
                      <img src={PasswordIconPNG} alt=""></img>
                    </InputAdornment>
                  ),
                }}
              />
            </TextFiledWrap>
            <TextFiledWrap>
              <TextLabel>新密码</TextLabel>
              <CustomTextField
                onChange={this.handleNewPasswordChange}
                value={this.state.newPassword}
                placeholder="请输入新密码"
                type="password"
                variant="outlined"
                InputLabelProps={{ shrink: true, focused: false }}
                inputProps={{
                  maxLength: 12,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ height: "26px", width: "30px" }}
                    >
                      <img src={PasswordIconPNG} alt=""></img>
                    </InputAdornment>
                  ),
                }}
              />
            </TextFiledWrap>
            <TextFiledWrap>
              <TextLabel>确认新密码</TextLabel>
              <CustomTextField
                onChange={this.handleConfirmPasswordChange}
                value={this.state.confirmPassword}
                placeholder="请输入新确认密码"
                type="password"
                variant="outlined"
                InputLabelProps={{ shrink: true, focused: false }}
                inputProps={{
                  maxLength: 12,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ height: "26px", width: "30px" }}
                    >
                      <img src={PasswordIconPNG} alt=""></img>
                    </InputAdornment>
                  ),
                }}
              />
            </TextFiledWrap>
            <ErrorText>{this.state.errMsg}</ErrorText>
            <ChangePasswordButtonContainer>
              <NextButton
                onClick={this.handleChangePasswordClick}
                variant="outlined"
              >
                确定
              </NextButton>
            </ChangePasswordButtonContainer>
          </ChangePasswordForm>
        </Container>
        <BoardImageBottom
         src={Decoration04}
        ></BoardImageBottom>
      </ContainerWrap>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(ChangePassword)));
