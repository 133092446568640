import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { WeiboService } from "../../../services/WeiboService";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import LoadingService from "../LoadingService";
import { PredictService } from "../../../services/PredictService";
import PopUpShare from "../../../../src/assets/images/popup_share_titlebg.jpg";
import PopUpShareBG from "../../../../src/assets/images/popup_share_bg2.png";
import Config from "../../../config/config";
import { RewardService } from "../../../services/RewardService";
import { CommonService } from "../../../services/CommonService";
const CloseIconContainer = styled(CloseIcon)
`
  float: right;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const Close = styled.div `
  position: absolute;
  z-index: 22;
  right: 5px;
`;
const ContainerWrap = styled.div `
  width: 450px;
  height: 670px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div `
  width: 450px;
  height: 670px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const PostContainer = styled.div `
  width: 450px;
  min-height: 360px;
  position: relative;
`;
const ImageContainer = styled.div `
  width: 450px;
  margin: 0 auto;
`;
const UserName = styled.div `
  color: #fff;
  position: absolute;
  top: 240px;
  left: 146px;
  font-size: 19px;
`;
const UserId = styled.div `
  color: #fff;
  position: absolute;
  top: 272px;
  left: 90px;
  font-size: 19px;
`;
const UserWinRate = styled.div `
  position: absolute;
  font-size: 18px;
  left: 315px;
  ${(props) =>
    props.active
      ? `
      font-weight: 700;
      color: #00ff63;
      top: 272px;
       `
      : `
      color: #FFFF66;
      font-weight: 400;
      top: 270px;
      `}
`;
const Date = styled.div`
  position: absolute;
  color: #a99d86;
  font-size: 16px;
  font-weight: 700;
  top: 345px;
  left: 30px;
  width: 390px;
  text-align: center;
`;
const EvaluateContainer = styled.div`
  background-image: url(${PopUpShare});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 352px;
  left: 30px;
  z-index: 20;
  text-align: center;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Result = styled.div`
  position: absolute;
  top: 380px;
  left: 30px;
  z-index: 20;
  text-align: center;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
  ${(props) =>
    props.active
      ? `
      background: -webkit-linear-gradient(#fb6066, #ff7d70, #ffe3a1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `
      : `
      color: white;
    `}
`;
// const Result = styled.div`
//   text-align: center;
//   font-size: 18px;
//   font-weight: 700;
//   display: flex;
//   justify-content: space-evenly;
//   width: 100%;
//   ${(props) =>
//     props.active
//       ? `
//       background: -webkit-linear-gradient(#fb6066, #ff7d70, #ffe3a1);
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;
//     `
//       : `
//       color: white;
//     `}
// `;
const Image = styled.img`
  width: 450px;
`;
const TextContianer = styled.div`
  padding: 10px 10px 0px 20px;
`;
const ButtonContainer = styled.div`
  padding: 10px 10px 0px 20px;
`;
const TextArea = styled.textarea`
  outline: none;
  width: 400px;
  height: 100px;
  resize: none;
  font-size: 20px;
  font-family: "PingFangTC-Medium";
  border: 2px solid #fff;
  background-color: transparent;
  color: #fff;
`;
const UserAvatar = styled.img`
  background-color: white;
  position: absolute;
  height: 112px;
  width: 114px;
  top: 38px;
  border-radius: 50%;
  left: 169px;
`;
const Splitter = styled.div`
  height: 4px;
  width: 80px;
  background-color: #fff;
  margin: 8px auto;
  position: absolute;
  top: 205px;
  left: 185px;
`;
const EvaluateSplitter = styled.div`
  height: 3px;
  width: 330px;
  background-color: #514945;
  margin: 8px auto;
  position: absolute;
  top: 305px;
  left: 58px;
`;
const PosterTitle = styled.div`
  font-size: 26px;
  color: #fff;
  position: absolute;
  left: 173px;
  top: 170px;
  font-weight: 700;
`;
const MatchTitle = styled.div`
  font-size: 21px;
  color: #fff;
  position: absolute;
  left: 37px;
  top: 305px;
  font-weight: 700;
  width: 380px;
  text-align: center;
`;
const UserNameLabel = styled.div`
  font-size: 20px;
  color: #fff;
  position: absolute;
  left: 36px;
  top: 238px;
`;
const UserIDLabel = styled.div`
  font-size: 20px;
  color: #fff;
  position: absolute;
  left: 36px;
  top: 270px;
`;
const UserWinRateLabel = styled.div`
  font-size: 20px;
  color: #fff;
  position: absolute;
  left: 245px;
  top: 270px;
`;
// const PredictResult = styled.div`
//   height: 30px;
//   margin: 0px 5px;
//   text-align: center;
//   width: 240px;
// `;
const PredictResult = styled.div`
  text-align: center;
`;
const PredictText = styled.span`
  background-color: ${(props) => props.colorAcitve};
  padding: 3px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 50,
    padding: "0 80px",
    fontSize: "20px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
class PredictPost extends Component {
  state = {
    text: "",
    loading: false,
    matchDetails: { prediction: [] },
  };
  componentDidMount() {
    var splitUrl = window.location.href.split("/");
    var lastParam = splitUrl[splitUrl.length - 1];
    var checkCodeValues = lastParam.includes("matchid");
    var splitArray = [];
    if (checkCodeValues) {
      window.location.href.split("&").forEach(function (value) {
        splitArray.push(value.split("="));
      });
    }
    var splitMatchID = splitArray[0][splitArray.length - 1];
    PredictService.GetMyPredictList(this.props.token, splitMatchID).then(
      (response) => {
        if (response.IsSuccess) {
          this.setState({
            matchDetails: response.data[0],
          });
        }
      }
    );
  }
  handleTextChange = (e) => {
    this.setState({ ...this.state, text: e.target.value });
  };
  handleUploadPost = () => {
    this.setState({ loading: true });
    const token = this.props.weiboToken;
    const text = this.state.text;
    const weiboID = this.props.weiboID;
    const weiboImg = this.props.weiboPic;
    const profileName = this.props.weiboName;
    const username = this.props.username;
    const id = this.props.id;
    const matchId = this.state.matchDetails.match_id;
    const date = this.state.matchDetails.match_time + " ";
    const matchTeam =
      this.state.matchDetails.team_a + " vs " + this.state.matchDetails.team_b;
    const matchName = this.state.matchDetails.race_title;
    const matchTitle = " Bo" + this.state.matchDetails.bo;
    const matchDetails = date + matchTeam + matchTitle;
    const result2 =
    this.state.matchDetails.prediction.find(predict => predict.type_id === "1").team_id.toString() === this.state.matchDetails.team_a_id.toString()
      ? this.state.matchDetails.team_a
      : this.state.matchDetails.team_b;
    const winRate =
      this.props.winRate === "推荐场次不足"
        ? "推荐场次不足"
        : this.props.winRate;
    const winMatch =
        this.state.matchDetails.match_result.find(predict => predict.type_id === "1").team_id.toString() ===
        this.state.matchDetails.prediction.find(predict => predict.type_id === "1").team_id.toString()
        ? true
        : false;
    const matchEnd =
      this.state.matchDetails.predict_result.toString() === "-1" ||
      this.state.matchDetails.match_result[0].team_id.toString() === "-1" 
      // || this.state.matchDetails.match_result[1].team_id.toString() === "-1"
        ? false
        : true;
    var linkUrl = Config.baseUrl
    RewardService.GetAffiliate(this.props.token).then((response) => {
      if (response.IsSuccess) {
        if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "development") {
          linkUrl = response.data.url
        }
        WeiboService.PostImagePredict(
          token,
          weiboID,
          weiboImg,
          profileName,
          username,
          matchDetails,
          result2,
          text,
          winRate,
          matchName,
          winMatch,
          matchEnd,
          id,
          matchId,
          linkUrl
        ).then((response) => {
          if (response.IsSuccess) {
            this.props.enqueueSnackbar("分享成功", {
              variant: "success",
            });
            CommonService.SharePostSuccess(this.props.token);
            this.setState({ loading: false });
            this.props.onhandleCloseDialog();
          } else {
            this.props.enqueueSnackbar("分享失败： " + response.msg, {
              variant: "error",
            });
            this.setState({ loading: false });
          }
        });
        }
  });
  };
  render() {
    return (
      <ContainerWrap>
        <Container>
          <LoadingService show={this.state.loading}></LoadingService>
          <PostContainer>
            <Close>
              <CloseIconContainer
                viewBox="4 4 16 16"
                onClick={this.props.onhandleCloseDialog}
              ></CloseIconContainer>
            </Close>
            <ImageContainer>
              <UserNameLabel>召唤师名称:</UserNameLabel>
              <UserName>{this.props.weiboName}</UserName>
              <UserIDLabel>代号: </UserIDLabel>
              <UserId>{this.props.username}</UserId>
              <UserWinRateLabel>应援率: </UserWinRateLabel>
              <UserWinRate
                active={this.props.winRate === "应援场次不足" ? false : true}
              >
                {this.state.matchDetails === "" ? "" : this.props.winRate}
              </UserWinRate>

              <Date>
                {this.state.matchDetails === ""
                  ? ""
                  : this.state.matchDetails.match_time}
                {this.state.matchDetails === ""
                  ? ""
                  : this.state.matchDetails.team_a}
                vs
                {this.state.matchDetails === ""
                  ? ""
                  : this.state.matchDetails.team_b}
                Bo
                {this.state.matchDetails === ""
                  ? ""
                  : this.state.matchDetails.bo}
              </Date>
              <MatchTitle>比赛项目: {this.state.matchDetails.race_title}</MatchTitle>
                <Result
                  active={
                    this.state.matchDetails.predict_result === -1 ? true : false
                  }
                >
                  {this.state.matchDetails.prediction
                    .sort(function (a, b) {
                      if (a.type_id.toString() === "2") return 1;
                      else return -1;
                    })
                    .slice(0, 1).map((value, index) => {
                      return (
                        <PredictResult
                          key={index}
                        >
                         <PredictText
                          colorAcitve={
                            this.state.matchDetails.predict_result.toString() === "-1" ||
                            this.state.matchDetails.match_result[0].team_id.toString() === "-1" ||
                            this.state.matchDetails.match_result[1].team_id.toString() === "-1"
                              ? "transparent"
                              : this.state.matchDetails.predict_result.toString() === "1"
                              ? "#8c000e"
                              : (value.type_id.toString() ===  this.state.matchDetails.match_result[0].type_id.toString() &&
                                  value.team_id.toString() === this.state.matchDetails.match_result[0].team_id.toString() )
                                ||
                                (
                                value.type_id.toString() ===  this.state.matchDetails.match_result[1].type_id.toString() &&
                                value.team_id.toString() === this.state.matchDetails.match_result[1].team_id.toString() )
                              ? "#8c000e"
                              : "#000201"
                          }
                          >
                            {value.team_id.toString()===this.state.matchDetails.team_a_id.toString()?this.state.matchDetails.team_a:this.state.matchDetails.team_b}
                          </PredictText>
                        </PredictResult>
                      );
                    })}
                </Result>
              <UserAvatar src={this.props.weiboPic}></UserAvatar>
              <Image src={PopUpShareBG}></Image>
            </ImageContainer>
          </PostContainer>
          <TextContianer>
            <TextArea
              placeholder="说些什么..."
              value={this.state.text}
              onChange={this.handleTextChange}
            ></TextArea>
          </TextContianer>
          <ButtonContainer>
            <NextButton onClick={this.handleUploadPost} variant="outlined">
              确定
            </NextButton>
          </ButtonContainer>
        </Container>
      </ContainerWrap>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    winRate: state.account.winRate,
    weiboToken: state.account.weiboToken,
    weiboName: state.account.weiboName,
    weiboPic: state.account.weiboImage,
    weiboID: state.account.weiboID,
    username: state.account.username,
    id: state.account.id,
  };
};
export default connect(mapStateToProps, null)(withSnackbar(PredictPost));