import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const BottomPagination = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
}))(Pagination);

const InnerWrap = styled.div`
  width: 100%;
  margin-top: 8px;
  box-sizing: border-box;
  padding-bottom: 5px;
`;
const PaginationContainer = styled.div`
  margin: 60px auto 20px auto;
`;
const TableContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
// const Row = styled.div`
//   height: 200px;
//   padding-top: 40px; ;
// `;
const Row = styled.div`
  height: 200px;
  padding-top: 40px; ;
`;
const Column = styled.div`
  width: 100%;
  height: 60px;
`;
// const ColumnTitle = styled.div`
//   color: #fff;
//   width: 80px;
//   font-size: 25px;
//   float: left;
//   padding: 0px 10px 0px 50px;
// `;
const ColumnTitle = styled.div`
  color: #fff;
  width: 120px;
  font-size: 25px;
  float: left;
  padding: 0px 10px 0px 50px;
`;
const ColumnValue = styled.div`
  color: #fff;
  font-size: 25px;
  float: left;
`;
const PredictResult = styled.div`
`;
// const PredictResult = styled.div`
//   width: 230px;
//   float: left;
// `;
// const PredictItem = styled.div`
//   float: right;
//   background-color: ${(props) => props.colorAcitve};
//   color: #fff;
//   padding: 0px 6px;
//   margin: 0px 10px;
//   width: 130px;
//   text-align: center;
// `;
const PredictItem = styled.div`
  float: right;
  background-color: ${(props) => props.colorAcitve};
  color: #fff;
  padding: 0px 6px;
  width: 130px;
  text-align: center;
`;
function RecordList(props) {
  const handleChangePage = (event, newPage) => {
    props.onhandleChangePage(newPage);
  };
  return (
    <InnerWrap>
      <TableContainer>
        {props.rows.map((row, index) => {
          return (
            <Row
              key={index}
              style={
                index % 2 === 0
                  ? { backgroundColor: "#040a78" }
                  : { backgroundColor: "transparent" }
              }
            >
              <Column>
                <ColumnTitle>时间</ColumnTitle>
                <ColumnValue>{row.match_time}</ColumnValue>
              </Column>
              <Column>
                <ColumnTitle>比赛项目</ColumnTitle>
                <ColumnValue>
                  {row.race_title}
                </ColumnValue>
              </Column>
              <Column>
                <ColumnTitle>组合</ColumnTitle>
                <ColumnValue>
                  {row.team_a} 对 {row.team_b}
                </ColumnValue>
              </Column>
              {/* <Column> */}
                {/* <ColumnTitle>结果</ColumnTitle>
                <ColumnValue>
                {row.prediction
                    .sort(function (a, b) {
                      if (a.type_id.toString() === "2") return 1;
                      else return -1;
                    }).slice(0, 1)
                    .map((value, index) => {
                      return (
                        <PredictResult key={index}>
                          <PredictItem
                           colorAcitve={
                            row.predict_result.toString() === "-1" ||
                            row.match_result[0].team_id.toString() === "-1" ||
                            row.match_result[1].team_id.toString() === "-1"
                              ? "transparent"
                              : row.predict_result.toString() === "1"
                              ? "#8c000e"
                              : (value.type_id.toString() ===  row.match_result[0].type_id.toString() &&
                                  value.team_id.toString() === row.match_result[0].team_id.toString() )
                                ||
                                (
                                value.type_id.toString() ===  row.match_result[1].type_id.toString() &&
                                value.team_id.toString() === row.match_result[1].team_id.toString() )
                              ? "#8c000e"
                              : "#000201"
                          }
                          >
                            {value.team_id.toString()===row.team_a_id.toString()?row.team_a:row.team_b}
                          </PredictItem>
                        </PredictResult>
                      );
                    })} */}
                  {/* {row.prediction
                    .sort(function (a, b) {
                      if (a.type_id.toString() === "1") return 1;
                      else return -1;
                    })
                    .map((value, index) => {
                      return (
                        <PredictResult key={index}>
                         {value.type_id === "1" ? "独赢" : "五杀"}:
                          <PredictItem
                           colorAcitve={
                            row.predict_result.toString() === "-1" ||
                            row.match_result[0].team_id.toString() === "-1" ||
                            row.match_result[1].team_id.toString() === "-1"
                              ? "transparent"
                              : row.predict_result.toString() === "1"
                              ? "#8c000e"
                              : (value.type_id.toString() ===  row.match_result[0].type_id.toString() &&
                                  value.team_id.toString() === row.match_result[0].team_id.toString() )
                                ||
                                (
                                value.type_id.toString() ===  row.match_result[1].type_id.toString() &&
                                value.team_id.toString() === row.match_result[1].team_id.toString() )
                              ? "#8c000e"
                              : "#000201"
                          }
                          >
                            {value.team_id.toString()===row.team_a_id.toString()?row.team_a:row.team_b}
                          </PredictItem>
                        </PredictResult>
                      );
                    })} */}
                {/* </ColumnValue> */}
              {/* </Column> */}
            </Row>
          );
        })}
      </TableContainer>
      <PaginationContainer>
        <BottomPagination
          count={props.total}
          page={props.page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          size="small"
        />
      </PaginationContainer>
    </InnerWrap>
  );
}
export default RecordList;
