import React, { Component } from "react";
import styled from "styled-components";
import Slick from "react-slick";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Config from "../../../config/config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const SliderContainer = styled.div`
  height: 480px;
  overflow:hidden;
`;
const SlideImage = styled.img`
  height: 470px;
  width: 750px;
  border: none;
  margin: 0 auto;
`;
const settings = {
  dots: true,
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "0",
  slidesToShow: 1,
  arrows: false,
  autoplay: true,
  useTransform: false,
  speed: 200,
  appendDots: (dots) => (
    <div
      style={{
        marginBottom: "30px",
      }}
    >
      <ul className="mobile-slider-pagination" style={{ margin: "0px" }}>
        {" "}
        {dots}{" "}
      </ul>
    </div>
  ),
  customPaging: (a) => {
    return (
      <div
        style={{
          border: "2px solid #fff",
          borderRadius: "50%",
          width: "12px",
          height: "12px",
        }}
      ></div>
    );
  },
  beforeChange: (current, next) => {},
};
class Slider extends Component {
  render() {
    return (
      <SliderContainer>
      <Slick {...settings}>
      {this.props.main_pictures.sort(function (a, b) {
                      if (a.order_id<b.order_id) return -1;
                      else return 1;
                    }).map((row, index) => {
          return (
            <SlideImage
              src={Config.apiImgSrc + row.path}
              key={index}
            ></SlideImage>
          );
        })}
      </Slick>
      </SliderContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(mapStateToProps, null)(withRouter(Slider));
