import React, { Component } from "react";
import styled from "styled-components";

const FooterText = styled.div`
  width: 100%;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  justify-content: center;
  background-color: black;
  min-width: 1240px;
`;
class Footer extends Component {
  render() {
    return (
      <FooterText>
        Copyright © 2020 Dian Mao Sai Xun Incorporated. All Rights Reserved.
      </FooterText>
    );
  }
}
export default Footer;
