import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { accountAction } from "../../../actions/accountAction";
import Config from "../../../config/config";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CommonService } from "../../../services/CommonService";
import { withSnackbar } from "notistack";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
const ContainerWrap = styled.div`
  width: 1000px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 1000px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const HeaderBar = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  position: absolute;
  right: 0px;
  color: #bec3de;
  margin: 10px 10px 0 0;
  border: solid 2px #bec3de;
  &:hover {
    cursor: pointer;
  }
`;
const Title = styled.div`
  font-size: 28px;
  padding-top: 20px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 4px;
  width: 80px;
  background-color: #fff;
  margin: 8px auto;
`;
const BoardImageBottom = styled.img`
  width: 650px;
  height: 30px;
  margin-left: 80px;
`;
const ConfirmButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding-bottom: 20px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 50,
    padding: "0 80px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const ProfileImageContainer = styled.div`
  width: 895px;
  margin: 45px auto 0px auto;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  box-sizing: border-box;
`;
const AvatarImg = styled.img`
  width: 120px;
  height: 120px;
`;
const ProfileImage = styled.div`
  background-color: white;
  width: 120px;
  height: 120px;
  float: left;
  border-radius: 6px;
  border: 4px solid transparent;
  margin-bottom: 20px;
  &:hover {
    border: 4px solid #37bfd9;
    cursor: pointer;
  }
  ${(props) =>
    props.active
      ? `
      border: 4px solid #37bfd9;
    `
      : `
    
    `}
`;
class ChangeProfileImage extends Component {
  state = {
    selected: 0,
    image: [],
  };
  handleSubmitProfileImage = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      this.handleUpdateProfileImage();
    }
  };
  componentDidMount() {
    CommonService.GetAvatarImage(this.props.token).then((response) => {
      if (response.IsSuccess) {
        this.setState(
          {
            image: response.data,
          },
          () => {
            if (this.props.avatar_url === "") {
              this.setState({
                selected: 1,
              });
            } else {
              for (var i = 0; i < this.state.image.length; i++) {
                if (this.props.avatar_url === this.state.image[i].avatar_url) {
                  this.setState({
                    selected: this.state.image[i].avatar_id,
                  });
                }
              }
            }
          }
        );
      }
    });
    window.addEventListener("keydown", this.handleSubmitProfileImage);
  }
  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleSubmitProfileImage);
  }
  handleChangeProfileImage = (value) => {
    this.setState({ ...this.state, selected: value });
  };
  handleUpdateProfileImage = () => {
    const selected = this.state.selected;
    const token = this.props.token;
    CommonService.ChangeProfileImage(token, selected).then(async (response) => {
      if (response.IsSuccess) {
        this.props.enqueueSnackbar(response.msg, {
          variant: "success",
        });
        await this.props.getProfile(token);
        this.props.onhandleCloseDialog();
      }
    });
  };
  render() {
    return (
      <ContainerWrap>
        <Container>
          <HeaderBar>
            <Icon
              viewBox="4 4 16 16"
              onClick={this.props.onhandleCloseDialog}
            ></Icon>
            <Title>头像选择</Title>
            <Splitter></Splitter>
          </HeaderBar>
          <ProfileImageContainer>
            {this.state.image.map((row, index) => {
              return (
                <ProfileImage
                  key={index}
                  active={this.state.selected === row.avatar_id ? true : false}
                  onClick={() => {
                    this.handleChangeProfileImage(row.avatar_id);
                  }}
                >
                  <AvatarImg
                    src={Config.apiImgSrc + row.avatar_url}
                  ></AvatarImg>
                </ProfileImage>
              );
            })}
          </ProfileImageContainer>

          <ConfirmButtonContainer>
            <NextButton
              onClick={this.handleUpdateProfileImage}
              variant="outlined"
            >
              确定
            </NextButton>
          </ConfirmButtonContainer>
        </Container>
        <BoardImageBottom
         src={Decoration04}
        ></BoardImageBottom>
      </ContainerWrap>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    avatar_url: state.account.avatar_url,
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(ChangeProfileImage)));
