import { ApiService } from "./ApiService";
import config from "../config/config";
export const PredictService = {
  GetGuruPredictList,
  GetMatchList,
  GetMyPredictList,
  StartPredict,
};

function GetMatchList(token, type) {
  const result = ApiService.post(config.api + "/lol_match/matchList", {
    token: token,
    type: type,
    race_id: config.raceID,
  });
  return result;
}
function GetGuruPredictList(token, match_id) {
  const result = ApiService.post(config.api + "/lol_user/specialGuestOrders", {
    token: token,
    match_id: match_id,
    race_id: config.raceID
  });
  return result;
}
function GetMyPredictList(token, match_id) {
  const result = ApiService.post(config.api + "/lol_user/userOrders", {
    token: token,
    match_id: match_id,
    race_id: config.raceID,
  });
  return result;
}

function StartPredict(token, match_id, win) {
  const result = ApiService.post(config.api + "/lol_match/doPrediction", {
    token: token,
    match_id: match_id,
    win: win,
  });
  return result;
}
