import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { AccountService } from "../../../services/AccountService";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
import MrcatRegisterLogoPNG from "../../../../src/assets/images/mrcatregisterlogo.png";
import { accountAction } from "../../../actions/accountAction";
import Loading from "../../Web/LoadingService";
const HeaderBar = styled.div`
  width: 100%;
  height: 125px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const ContainerWrap = styled.div`
  width: 850px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 850px;
  height: 500px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const RegisterForm = styled.div`
  margin: 10px auto;
  width: 630px;
`;
const RegisterTitle = styled.div`
  font-size: 28px;
  padding-top: 40px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const TitleSpan = styled.span`
  color: #ff0000;
`;
const Splitter = styled.div`
  height: 4px;
  width: 80px;
  background-color: #fff;
  margin: 8px auto;
`;
const RegisterButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 50,
    padding: "0 80px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const TextFiledWrap = styled.div`
  width: 520px;
  height: 65px;
`;
const TextLabel = styled.div`
  font-size: 20px;
  color: #fff;
  float: left;
  width: 100px;
  margin: 25px 20px 0px 0px;
`;
const CustomTextField = withStyles({
  root: {
    width: "400px",
    height: "45px",
    margin: 0,
    marginTop: "20px",
    background: "transparent",
    float: "left",

    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      color: "#fff",
      height: "6px",
    },

    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        borderWidth: "1.2px",
        height: "45px",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "1.2px",
        height: "45px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "1.2px",
        height: "45px",
      },
    },
  },
})(TextField);
const ErrorText = styled.div`
  text-align: left;
  height: 30px;
  color: #fff;
  line-height: 20px;
  font-size: 14px;
  width: 400px;
  margin: 10px auto 0 120px;
`;
const BoardImageBottom = styled.img`
  width: 650px;
  height: 30px;
  margin-left: 80px;
`;
const MrCatRegisterLogo = styled.img`
  width: 110px;
  position: absolute;
  left: 20px;
  top: 20px;
`;
const RegisterSuccessTitle = styled.div`
  font-size: 28px;
  padding-top: 40px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 50px;
`;
const RefText = styled.div`
  width: 454px;
  margin: 60px auto;
  font-size: 30px;
  color: #fff;
  letter-spacing: 6px;
`;
const RegisterSuccessButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
const UsernameLabel = styled.div`
  float: left;
  margin-left: 12px;
  margin-top: 21px;
  width: 120px;
  color: #6c72d0;
  font-size: 0.875rem;
`;
const RegisterSuccessButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#dddeed",
    color: "#b03744",
    fontSize: "16px",
    height: 50,
    padding: "0 50px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      backgroundColor: "#b03744",
      color: "#dddeed",
    },
  },
}))(Button);
const RefBottom = styled.div`
  margin: 20px auto;
  width: 500px;
  text-align: center;
  color: #fff;
`;
class MrCatRegister extends Component {
  state = {
    step: 1,
    username: "",
    password: "",
    confirmPassword: "",
    errMsg: "",
    successMsg: "",
    mrCatToken: "",
    show: false,
  };
  componentDidMount() {}
  handleUsernameChange = (e) => {
    this.setState({ ...this.state, username: e.target.value });
  };
  handleVerifyCodeChange = (e) => {
    this.setState({ ...this.state, verCode: e.target.value });
  };
  handlePasswordChange = (e) => {
    this.setState({ ...this.state, password: e.target.value });
  };
  handleConfirmPasswordChange = (e) => {
    this.setState({ ...this.state, confirmPassword: e.target.value });
  };
  handleMrCatWebsite = () => {
    window.open("https://www.mct188.com/cn/?do=login_token&goto=&token="+this.state.mrCatToken, "");
  };
  handleRegisterClick = () => {
    const username = this.state.username;
    const password = this.state.password;
    const confirmPassword = this.state.confirmPassword;
    if (!this.props.token) {
      this.setState({ errMsg: "请登录" });
      return;
    }
    if (username === "") {
      this.setState({ errMsg: "请输入用户名" });
      return;
    }
    if ( 
        username.length < 8 ||
        username.length > 16 ||
        !username.match(/([0-9].*[a-z])|([a-z].*[0-9])/)||
        username.match(/[A-Z]+/)
    )
    {
      this.setState({ errMsg: "用户名需为8-16码小写英文与数字" });
      return;
    }
    if (password === "") {
      this.setState({ errMsg: "请输入密码" });
      return;
    }
    if (confirmPassword === "") {
      this.setState({ errMsg: "请输入确认密码" });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({ errMsg: "密码不一致" });
      return;
    }
    this.setState({
      errMsg: "",
      successMsg: "",
      show: true
    });
    AccountService.RegisterMrCat(
      this.props.token,
      this.state.username,
      this.state.password,
      this.state.confirmPassword
    ).then((result) => {
      if (result.IsSuccess) {
        this.props.getProfile(this.props.token)
        this.setState({
          step: 2,
          mrCatToken: result.data,
          show: false,
          errMsg: "",
        });
      } else {
        this.setState({ errMsg: result.msg, successMsg: "",show: false });
      }
    });
  };
  render() {
    return (
      <ContainerWrap>
        <Loading show={this.state.show}></Loading>
        <Container>
          <HeaderBar>
            <Icon
              viewBox="4 4 16 16"
              onClick={this.props.onhandleCloseDialog}
            ></Icon>
            <MrCatRegisterLogo
              src={MrcatRegisterLogoPNG}
            ></MrCatRegisterLogo>
            {this.state.step === 1 ? (
              <RegisterTitle>
                新用户 注册即送<TitleSpan>18</TitleSpan>元体验金
              </RegisterTitle>
            ) : (
              <RegisterSuccessTitle>
                恭喜您完成注册
                <br />
                获得体验金<TitleSpan>18</TitleSpan>元
              </RegisterSuccessTitle>
            )}
            <Splitter></Splitter>
          </HeaderBar>
          {this.state.step === 1 ? (
            <RegisterForm>
              <TextFiledWrap style={{ width: "700px" }}>
                <TextLabel>用户名</TextLabel>
                <CustomTextField
                  onChange={this.handleUsernameChange}
                  value={this.state.username}
                  variant="outlined"
                  placeholder="请输入用户名"
                  InputLabelProps={{ shrink: true, focused: false }}
                  inputProps={{
                    maxLength: 16,
                  }}
                />
              <UsernameLabel>用户名需为8-16码小写英文与数字</UsernameLabel>
              </TextFiledWrap>
              <TextFiledWrap style={{ width: "700px" }}>
                <TextLabel>密码</TextLabel>
                <CustomTextField
                  onChange={this.handlePasswordChange}
                  value={this.state.password}
                  placeholder="请输入密码"
                  type="password"
                  variant="outlined"
                  InputLabelProps={{ shrink: true, focused: false }}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </TextFiledWrap>
              <TextFiledWrap>
                <TextLabel>密码确认</TextLabel>
                <CustomTextField
                  onChange={this.handleConfirmPasswordChange}
                  value={this.state.confirmPassword}
                  id="password"
                  placeholder="请确认密码"
                  type="password"
                  variant="outlined"
                  InputLabelProps={{ shrink: true, focused: false }}
                  inputProps={{
                    maxLength: 20,
                  }}
                />
              </TextFiledWrap>
              <ErrorText>{this.state.errMsg}</ErrorText>
              <RegisterButtonContainer>
                <NextButton
                  onClick={this.handleRegisterClick}
                  variant="outlined"
                >
                  确定
                </NextButton>
              </RegisterButtonContainer>
            </RegisterForm>
          ) : (
            <>
              <RefText>
                体验金约需1-5分钟后到帐即刻前往 体验最佳电竞平台
              </RefText>
              <RegisterSuccessButtonContainer>
                <RegisterSuccessButton onClick={()=>{this.handleMrCatWebsite()}}>
                  前往猫先生平台
                </RegisterSuccessButton>
              </RegisterSuccessButtonContainer>
              <RefBottom>系统判定为关连账号者 无法领取体验金</RefBottom>
            </>
          )}
        </Container>
        <BoardImageBottom
         src={Decoration04}
        ></BoardImageBottom>
      </ContainerWrap>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(MrCatRegister)));
