import React, { Component } from "react";
import NavBar from "./NavBar";
import Slider from "./Slider";
import Marquee from "./Marquee";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { accountAction } from "../../../actions/accountAction";
import { withSnackbar } from "notistack";
const HeaderContainer = styled.div`
  position: relative;
`;
class Header extends Component {
  updateInterval = null;
  tickCount = 0;
  componentDidMount() {
    this.FireTimerToCheckAcc();
  }
  FireTimerToCheckAcc() {
    this.updateInterval = setInterval(() => {
      if (
        this.props.token !== null &&
        localStorage.getItem("acctoken") === null
      ) {
        this.props.setInitial();
        this.props.history.push("/login");
      } else if (
        this.props.token === null &&
        localStorage.getItem("acctoken") !== null
      ) {
        this.props.setToken(localStorage.getItem("acctoken"));
        this.props
          .getProfile(localStorage.getItem("acctoken"))
          .then((result) => {
            if (result.IsSuccess) {
              this.props.enqueueSnackbar("登录成功", {
                variant: "success",
              });
              this.props.history.push("/");
            } else {
              this.props.accountLogout();
              this.props.history.push("/login");
            }
          });
      }
    }, 1000);
  }

  render() {
    return (
      <HeaderContainer>
        <NavBar></NavBar>
        <Slider main_pictures={this.props.main_pictures}></Slider>
        {/* <Marquee marquee={this.props.marquee}></Marquee> */}
      </HeaderContainer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setInitial: () => dispatch(accountAction.setInitial()),
    accountLogout: (token) => dispatch(accountAction.accountLogout(token)),
    setToken: (token) => dispatch(accountAction.setToken(token)),
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(Header)));
