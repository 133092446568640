import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const NavText = styled.div`
  margin-right: 20px;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  &:hover {
    color: #e6e6e6;
  }
`;
class GuestContainer extends Component {
  onhandleLogin = () => {
    this.props.history.push("/login");
  };
  render() {
    return (
      <>
        <NavText
          onClick={() => {
            this.onhandleLogin();
          }}
        >
          登录/注册
        </NavText>
      </>
    );
  }
}
export default connect(null, null)(withRouter(GuestContainer));
