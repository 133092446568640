import axios from "axios";
import { MainHistory } from "../history/History";
import Promise from "promise-polyfill";
export default {
  setupInterceptors: (store) => {
    axios.interceptors.response.use(
      function (response) {
        if (response.data.code === 0) {
          response.data.IsSuccess = true;
        } else if (parseInt(response.data.code) === 50008) {
          localStorage.clear();
          store.dispatch({ type: "UNAUTHORIZE" });

          MainHistory.replace("/");
        } else if (parseInt(response.data.code) === 50000) {
        }

        return response.data;
      },
      function (error, b, c) {
        return Promise.reject(error);
      }
    );
  },
};
