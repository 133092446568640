import React, { Component } from "react";
import styled from "styled-components";
import { Router, Route, Switch, Redirect } from "react-router";
import { MainHistory } from "../../history/History";
import { connect } from "react-redux";
import { accountAction } from "../../actions/accountAction";
import { weiboAction } from "../../actions/weiboAction";
import Common from "../../utils/Common";
import Loading from "../Web/Loading";
import Header from "./Header";
import BottomNavBar from "./BottomNavBar";
import Login from "./Login";
import ActivityRules from "./ActivityRules/index";
import Account from "./Account/index";
import Reward from "./Reward/index";
import Home from "./Home/index";
import MyPredict from "./MyPredict";
import Predict from "./Predict";
import { CommonService } from "../../services/CommonService";
import AboutUs from "./AboutUs";
const Main = styled.div`
  display: block;
  width: 100%;
  padding-top: 10px;
`;

class App extends Component {
  state = {
    isIpRestrict: true,
    banner: true,
    // board: [],
    // marquee: [],
    qrcode: "",
    main_pictures: [],
  };
  updateInterval = null;
  tickCount = 0;
  async componentDidMount() {
    Common.loadScript("/js/config.js", () => {
      Common.loadScript("/js/iovation.js?v=1601548638642", () => {
        // do mail api stuff here
      });
    });
    //get marquee
    // this.callGetMarquee();
    // this.FireTimerToCheckMarquee();
    //get banner
    CommonService.GetBasicElement().then((response) => {
      if (response) {
        if (response.IsSuccess) {
          this.setState({
            // board: response.data.user_rank,
            main_pictures: response.data.banners,
            qrcode: response.data.qr_code.path
          });
          this.setState({ ...this.state, isIpRestrict: false });
        }
      }
    });
    const localStorageToken = localStorage.getItem("acctoken");
    if (localStorageToken != null && this.props.token == null) {
      await this.props.setToken(localStorageToken);
      await this.props.getProfile(localStorageToken);
    } else {
      if (this.checkIsInAuthPage()) {
        MainHistory.push("/");
      }
    }
    var splitUrl = window.location.href.split("/");
    var lastParam = splitUrl[splitUrl.length - 1];
    //check promote queryString
    var checkPromoteValues1 = lastParam.includes("?a=");
    var checkPromoteValues2 = lastParam.includes("?u=");
    if (checkPromoteValues1 || checkPromoteValues2) {
      var splitPromoteCode = window.location.href.split("=");
      var promoteCode = splitPromoteCode[splitPromoteCode.length - 1];
      MainHistory.push("/" + promoteCode);
    }
    //check weibo
    var checkCodeValues = lastParam.includes("code");
    if (checkCodeValues) {
      var splitCode = window.location.href.split("=");
      var code = splitCode[splitCode.length - 1];
      const resultWeiboToken = await this.props.getWeiboToken(code);
      if (resultWeiboToken.IsSuccess) {
        const resultWeiboProfile = await this.props.getWeiboProfile(
          resultWeiboToken.data.access_token,
          resultWeiboToken.data.uid
        );
        if (resultWeiboProfile.IsSuccess) {
        } else {
          // alert(resultWeiboProfile.msg.message);
          MainHistory.push("/");
        }
      } else {
        // alert(resultWeiboToken.msg.message);
        MainHistory.push("/");
      }
    }
  }
  checkIsInAuthPage = () => {
    const authPages = ["/predict", "/mypredict", "/account", "/reward"];
    let isInAuthPage = false;
    for (let page of authPages) {
      if (MainHistory.location.pathname.includes(page) === true) {
        isInAuthPage = true;
        break;
      }
    }
    return isInAuthPage;
  };
  handleOpenBanner = () => {
    this.setState({
      ...this.state,
      banner: true,
    });
  };
  handleCloseBanner = () => {
    this.setState({
      ...this.state,
      banner: false,
    });
  };
  // FireTimerToCheckMarquee() {
  //   this.updateInterval = setInterval(() => {
  //     this.callGetMarquee();
  //   }, 60000);
  // }
  // callGetMarquee = () => {
    // CommonService.GetBroadCast().then((response) => {
    //   if (response) {
    //     if (response.IsSuccess) {
    //       this.setState({
    //         marquee: response.data,
    //       });
    //     }
    //   }
    // });
  // };
  render() {
    return (
      <Router history={MainHistory}>
        {(() => {
          if (this.state.isIpRestrict) {
            return <Loading show={true} opacity={1}></Loading>;
          } else {
            return (
              <>
                <Header
                  // marquee={this.state.marquee}
                  main_pictures={this.state.main_pictures}
                ></Header>
                <Main>
                  <Switch>
                    {/* <Route
                      exact
                      path={["/"]}
                      render={(props) => (
                        <Home
                          // board={this.state.board}
                          qrcode={this.state.qrcode} 
                          banner={this.state.banner}
                          handleOpenBanner={this.handleOpenBanner}
                          handleCloseBanner={this.handleCloseBanner}
                        />
                      )}
                    /> */}
                    <Route exact path={"/rules"} component={ActivityRules} />
                    <Route exact path={"/account"} component={Account} />
                    <Route exact path={"/reward"} component={Reward} />
                    {/* <Route exact path={"/predict"} component={Predict} /> */}
                    <Route exact path={"/mypredict"} component={MyPredict} />
                    <Route exact path={"/aboutus"} component={AboutUs} />
                    <Route
                      exact
                      path={["/login", "/:code" ,"/"]}
                      render={(props) => <Login />}
                    />
                    <Redirect to={"/"} />
                  </Switch>
                </Main>
                <BottomNavBar></BottomNavBar>
              </>
            );
          }
        })()}
      </Router>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setToken: (token) => dispatch(accountAction.setToken(token)),
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
    getWeiboToken: (code) => dispatch(weiboAction.getWeiboToken(code)),
    getWeiboProfile: (token, uid) =>
      dispatch(weiboAction.getWeiboProfile(token, uid)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
