module.exports = {
  baseUrl: "",
  api: "",
  apiImgSrc: "",
  weiboUrl: "https://api.weibo.com",
  weiboClientID: "1764265078",
  from: "",
  wechatSharUrl: "https://baichib.com",
  raceID: 2
};
