import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { accountAction } from "../../actions/accountAction";
import { connect } from "react-redux";
import Register from "./Register";
import ForgetPassword from "./ForgetPassword";
import Dialog from "@material-ui/core/Dialog";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import Decoration04 from "../../../src/assets/images/decoration_04.png";
import { SHOW_REGISTER } from "../../reducers/uiReducer";
const StyledDialog = withStyles({
  root: {
    boxShadow: "none",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0, boxShadow: "none" },
})(Dialog);
const HeaderBar = styled.div`
  width: 100%;
  height: 90px;
  position: relative;
`;
const ContainerWrap = styled.div`
  width: 750px;
  height: 700px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 150px;
`;
const Container = styled.div`
  margin: 0 20px;
  height: 700px;
  position: relative;
  background-color: #1a2285;
`;
const LoginForm = styled.div`
  margin: 40px auto;
  width: 700px;
`;
const LoginTitle = styled.div`
  font-size: 40px;
  padding-top: 30px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 6px;
  width: 100px;
  background-color: #fff;
  margin: 10px auto;
`;
const SectionButton = styled.div`
  font-size: 30px;
  color: #aba8a8;
  width: 200px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease-out;
  &:hover {
    border-bottom: 3px solid #fff;
    color: #fff;
  }
`;
const LoginButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "3.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 75,
    fontSize: 25,
    padding: "0 100px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const ButtonContainer = styled.div`
  width: 450px;
  margin: 20px auto 0px auto;
  display: flex;
  justify-content: space-between;
  height: 60px;
`;
const TextLabel = styled.div`
  font-size: 30px;
  color: #fff;
  width: 200px;
  margin: 20px 0px 0px 100px;
`;
const CustomTextField = withStyles({
  root: {
    marginLeft: "100px",
    width: "500px",
    height: "90px",
    margin: 0,
    marginTop: "20px",
    background: "transparent",
    "& .MuiOutlinedInput-input": {
      fontSize: "30px",
      color: "#fff",
      lineHeight: "45px",
      height: "45px",
    },

    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        borderWidth: "4px",
        height: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "4px",
        height: "80px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "4px",
        height: "80px",
      },
    },
  },
})(TextField);
const ErrorText = styled.div`
  text-align: left;
  height: 30px;
  color: #fff;
  font-size: 25px;
  width: 500px;
  margin: 20px auto 0 100px;
`;
const BoardImageBottom = styled.img`
  width: 500px;
  height: 26px;
  margin-left: 110px;
`;
class Login extends Component {
  state = {
    phone: "",
    password: "",
    errMsg: "",
    checkVal: false,
    openDialog: false,
    openDialogName: "",
  };
  componentDidMount() {
    if (this.props.token) {
      this.props.history.push("/mypredict");
    } else {
      //check promote code
      var splitUrl = window.location.href.split("/");
      let promoteCode = "";
      if (splitUrl.length === 4) {
        if (splitUrl[3].includes("a") || splitUrl[3].includes("u")) {
          document.title = "电猫赛讯-应援推荐-好友分享";
          promoteCode = splitUrl[3] || "";
          this.onhandleRegister();
        }
      }
      localStorage.setItem("inviteCode", promoteCode);
      if(this.props.ui.showRegister === true){
        this.onhandleRegister()
      }
    }
  }
  handlePhoneChange = (e) => {
    this.setState({ ...this.state, phone: e.target.value });
  };
  handlePasswordChange = (e) => {
    this.setState({ ...this.state, password: e.target.value });
  };
  handleLoginClick = () => {
    const phone = this.state.phone;
    const password = this.state.password;
    if (phone === "") {
      this.setState({ errMsg: "请输入电话号码" });
      return;
    }
    if (password === "") {
      this.setState({ errMsg: "请输入密码" });
      return;
    }
    this.props
      .accountLogin(this.state.phone, this.state.password)
      .then((result) => {
        if (result.IsSuccess) {
          this.handleLoginSuccess(result.data);
        } else {
          this.handleLoginFailed(result);
        }
      });
  };
  onhandleRegister = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      openDialogName: "register",
    });
  };
  onhandleForgetPassword = () => {
    this.setState({
      ...this.state,
      openDialog: true,
      openDialogName: "forgetpassword",
    });
  };
  onhandleCloseDialog = () => {
    this.props.openRegisterModel(false)
    this.setState({ ...this.state, openDialog: false });
  };
  handleLoginSuccess = (token) => {
    this.props.getProfile(token).then((result) => {
      if (result.IsSuccess) {
        this.props.enqueueSnackbar("登录成功", {
          variant: "success",
        });
        this.props.history.push("/mypredict");
      }
    });
  };
  handleLoginFailed = (response) => {
    this.setState({ errMsg: response.msg });
  };

  render() {
    return (
      <ContainerWrap>
        <Container>
          <HeaderBar>
            <LoginTitle>会员登陆 - 开始应援领奖励</LoginTitle>
            <Splitter></Splitter>
          </HeaderBar>
          <LoginForm>
            <TextLabel>手机号码</TextLabel>
            <CustomTextField
              onChange={this.handlePhoneChange}
              value={this.state.phone}
              variant="outlined"
              placeholder="请输入手机号码"
              InputLabelProps={{ shrink: true, focused: false }}
              inputProps={{
                maxLength: 16,
              }}
            />

            <TextLabel>密码</TextLabel>
            <CustomTextField
              onChange={this.handlePasswordChange}
              value={this.state.password}
              placeholder="请输入密码"
              type="password"
              variant="outlined"
              InputLabelProps={{ shrink: true, focused: false }}
              inputProps={{
                maxLength: 20,
              }}
            />

            <ErrorText>{this.state.errMsg}</ErrorText>
            <ButtonContainer>
              <SectionButton onClick={this.onhandleForgetPassword}>
                忘记密码
              </SectionButton>
              <SectionButton onClick={this.onhandleRegister}>
                会员注册
              </SectionButton>
            </ButtonContainer>
            <LoginButtonContainer>
              <NextButton onClick={this.handleLoginClick} variant="outlined">
                确定
              </NextButton>
            </LoginButtonContainer>
          </LoginForm>
        </Container>
        <BoardImageBottom
           src={Decoration04}
        ></BoardImageBottom>
        <StyledDialog
          open={this.state.openDialog}
          onClose={this.onhandleCloseDialog}
          fullWidth={true}
          maxWidth={false}
        >
          {(() => {
            if (this.state.openDialogName === "register") {
              return (
                <Register
                  onhandleCloseDialog={this.onhandleCloseDialog}
                ></Register>
              );
            } else if (this.state.openDialogName === "forgetpassword") {
              return (
                <ForgetPassword
                  onhandleCloseDialog={this.onhandleCloseDialog}
                ></ForgetPassword>
              );
            } else {
              return <div></div>;
            }
          })()}
        </StyledDialog>
      </ContainerWrap>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    ui: state.ui,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    accountLogin: (username, password) =>
      dispatch(accountAction.accountLogin(username, password)),
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
    openRegisterModel: (payload) => {
      dispatch({ type: SHOW_REGISTER, payload });
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(Login)));
