import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { RewardService } from "../../../services/RewardService";
import { connect } from "react-redux";
import { withSnackbar } from "notistack";
import RewardDialog from "./RewardDialog";
import Dialog from "@material-ui/core/Dialog";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
const Container = styled.div`
  width: 1200px;
  margin: 50px auto 100px auto;
  background-color: #1a2285;
  position: relative;
`;
const Splitter = styled.div`
  height: 4px;
  width: 80px;
  background-color: #fff;
  margin: 8px auto;
`;
const Title = styled.div`
  font-size: 34px;
  padding-top: 20px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Reminder = styled.div`
  color: #8e95ef;
  padding: 20px;
  width: 600px;
  margin: 0 auto;
  text-align: center;
`;
const AffiliateContainer = styled.div`
  background-color: #040a78;
  width: 800px;
  height: 160px;
  margin: 0 auto;
  position: relative;
`;
const AffiliateCodeTitle = styled.div`
  color: #34b3da;
  font-size: 24px;
  position: absolute;
  top: 30px;
  left: 50px;
`;
const AffiliateLinkContainer = styled.div`
  width: 750px;
  height: 60px;
  position: absolute;
  top: 80px;
  left: 50px;
`;
const AffiliateLink = styled.div`
  float: left;
  margin-top: 4px;
  width: 435px;
`;
const CodeLinkSpan = styled.input`
  outline: none;
  line-height: 35px;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  border-width: 0px;
  width: 95%;
  background-color: transparent;
`;
const ShareButtonContainer = styled.div`
  width: 100px;
  margin-left: 10px;
  float: left;
`;
const ShareSplitter = styled.div`
  float: left;
  background-color: #fff;
  width: 4px;
  height: 20px;
  margin-top: 12px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    fontSize: "12px",
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 40,
    padding: "0 10px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);

const ShareSection = styled.div`
  height: 50px;
  float: left;
`;
const ShareContainer = styled.div`
  margin: 10px 0px 0px 10px;
  width: 140px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  float: right;
  color: #fff;
`;
const ShareIcon = styled.i`
  color: white;
  cursor: pointer;
`;
const ContainerBorder = styled.img`
  width: 950px;
  height: 35px;
  position: absolute;
  bottom: -35px;
  left: 100px;
`;
const RewardInfo = styled.div`
  width: 250px;
  height: 80px;
  margin: 20px auto;
  border: solid 2px #a9a7a7;
`;
const RewardTitle = styled.div`
  text-align: center;
  color: #a9a7a7;
  padding-top: 10px;
  font-size: 20px;
`;
const RewardText = styled.div`
  text-align: center;
  color: #a9a7a7;
  padding-top: 5px;
  font-size: 16px;
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "none",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0, boxShadow: "none" },
})(Dialog);
class Affiliate extends Component {
  state = {
    linkURl: "",
    openDialog: false,
    num: "",
    is_achieved: "",
  };
  copyText = (e) => {
    var copyText = document.getElementById("codelinkspan");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  getWeiBoProfile = () => {
    window.open(
      "https://service.weibo.com/share/share.php?title=电猫赛讯-好友分享-邀你来相约一场盛大的比赛，除此之外还会带来许多惊喜，马上点击链接注册吧！&url=" +
        this.state.linkURl+"&pic=https://baichib.com/static/media/sharePic.a3150013.jpg",
      "_blank"
    );
  };
  handleShareWechat = () => {
    window.open("/wechatshare?url=" + this.state.linkURl, "");
  };
  componentDidMount() {
    if (this.props.token === null) {
      this.props.history.push("/login");
    } else {
      RewardService.GetAffiliate(this.props.token).then((response) => {
        if (response.IsSuccess) {
          this.setState({
            linkURl: response.data.url,
            num: response.data.num,
            is_achieved: response.data.is_achieved,
          });
        }
      });
    }
  }
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };
  render() {
    return (
      <Container style={{ height: "460px" }}>
        <Title>好友邀请</Title>
        <Splitter></Splitter>
        <Reminder>
          只要朋友透过您的分享链接注册，即会算入一个成功邀请。
        </Reminder>
        <AffiliateContainer>
          <AffiliateCodeTitle>好友分享链接</AffiliateCodeTitle>
          <AffiliateLinkContainer>
            <AffiliateLink>
              <CodeLinkSpan
                id="codelinkspan"
                readOnly={true}
                value={this.state.linkURl}
              ></CodeLinkSpan>
            </AffiliateLink>
            <ShareButtonContainer>
              <NextButton onClick={this.copyText} variant="outlined">
                点击复制
              </NextButton>
            </ShareButtonContainer>
            <ShareSplitter></ShareSplitter>
            <ShareSection>
              <ShareContainer>
                分享到
                <ShareIcon
                  className="fa fa-weibo"
                  style={{ fontSize: "28px", marginTop: "-2px" }}
                  onClick={this.getWeiBoProfile}
                ></ShareIcon>
                <ShareIcon
                  className="fa fa-wechat"
                  style={{ fontSize: "28px", marginTop: "-2px" }}
                  onClick={this.handleShareWechat}
                ></ShareIcon>
              </ShareContainer>
            </ShareSection>
          </AffiliateLinkContainer>
        </AffiliateContainer>
        {/* <RewardInfo>
          <RewardTitle>
            {this.state.is_achieved === 1 ? "条件达成" : "条件未达成"}
          </RewardTitle>
          <RewardText>*已成功邀请 {this.state.num}/5 人</RewardText>
        </RewardInfo> */}
        <ContainerBorder
          src={Decoration04}
        ></ContainerBorder>
        <StyledDialog
          open={this.state.openDialog}
          onClose={this.onhandleCloseDialog}
          fullWidth={true}
          maxWidth={false}
        >
          <RewardDialog
            onhandleCloseDialog={this.onhandleCloseDialog}
          ></RewardDialog>
        </StyledDialog>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(Affiliate)));
