import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App/index";
import Web from "./components/Web/index";
import * as serviceWorker from "./serviceWorker";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import Interceptor from "./services/Interceptor";
import { Provider } from "react-redux";
import ProfilePoster from "./components/App/ProfilePoster";
import { MainHistory } from "./history/History";
import { Router, Route, Switch, Redirect } from "react-router";
import PredictPoster from "./components/App/PredictPoster";
import WechatShare from "./components/Web/WechatShare";
import Config from "./config/config";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
require("es6-promise").polyfill();
const store = createStore(rootReducer, applyMiddleware(thunk));
Interceptor.setupInterceptors(store);
Config.from = isMobile ? "m" : "pc";
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const WebUseStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: "#202aa4!important",
    minHeight: "60px!important",
    fontSize: "16px!important",
  },
  error: {
    backgroundColor: "#fb443e!important",
    minHeight: "60px!important",
    fontSize: "16px!important",
  },
}));
const MobUseStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: "#202aa4!important",
    minHeight: "45px!important",
    fontSize: "16px!important",
  },
  error: {
    backgroundColor: "#fb443e!important",
    minHeight: "45px!important",
    fontSize: "16px!important",
  },
}));
const Dismiss = styled.a`
  margin-right: 12px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;

// console.log(
//   "version: " +
//     process.env.REACT_APP_VERSION +
//     " (" +
//     process.env.REACT_APP_CUSTOM_NODE_ENV +
//     ")"
// );
console.log(
  "%c  不要在此操作，以保障资讯安全!",
  "color: red; font-size: 50px; font-family:serif"
);
if (process.env.REACT_APP_CUSTOM_NODE_ENV !== "development") {
  let url = window.location.hostname;
  let new_sub = "api";
  let baseUrl = "";
  if (url.split(/[\/\.]+/).length >= 3) {
    baseUrl = url.replace(url.split(/[\/\.]+/)[0], new_sub);
  } else {
    baseUrl = new_sub + "." + url;
  }
  let imgUrl = window.location.protocol + "//" + baseUrl;
  baseUrl = window.location.protocol + "//" + baseUrl+"/api.php";
  Config.baseUrl = window.location.protocol + "//" +url
  Config.api = baseUrl;
  Config.apiImgSrc = imgUrl;
  switch(url){
    case "www.baichib.com":
      Config.weiboClientID = "1764265078"
    break;
    case "baichib.com":
      Config.weiboClientID = "1764265078"
    break;
    case "mrcat-event-lol.wokerspace.com":
      Config.weiboClientID = "1392841744"
    break;
  }
}
const AppWrapper = () => {
  const mobileClasses = WebUseStyles();
  const webClasses = MobUseStyles();
  return (
    <Provider store={store}>
      <SnackbarProvider
        classes={{
          variantSuccess: isMobile ? mobileClasses.success : webClasses.success,
          variantError: isMobile ? mobileClasses.success : webClasses.error,
        }}
        maxSnack={3}
        action={(key) => <Dismiss onClick={onClickDismiss(key)}>x</Dismiss>}
        autoHideDuration={1000}
        anchorOrigin={
          isMobile
            ? {
                vertical: "top",
                horizontal: "center",
              }
            : {
                vertical: "top",
                horizontal: "right",
              }
        }
        hideIconVariant
        ref={notistackRef}
      >
        <Router history={MainHistory}>
          <Switch>
            <Route path={"/profile/:userid"} component={ProfilePoster} />
            <Route path={"/record/:userid/:postid"} component={PredictPoster} />
            <Route path={"/wechatshare"} component={WechatShare} />
            <Route
              path={"/"}
              render={(props) => (isMobile ? <App /> : <Web />)}
            />
            <Redirect to={"/"} />
          </Switch>
        </Router>
      </SnackbarProvider>
    </Provider>
  );
};
ReactDOM.render(<AppWrapper></AppWrapper>, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
