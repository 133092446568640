import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MrCatRegister from "./MrCatRegister";
import CloseIcon from "@material-ui/icons/Close";
import BannerBottomGIF from "../../../../src/assets/images/banner_bottom-m.gif";
import MrCatLogoPNG from "../../../../src/assets/images/mrcat-logo.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ImageSc from "../../../../src/assets/images/sharePic.jpg";
export const moveFromLeft = keyframes`
  from {
    -webkit-transform: translateX(150%); }
  to {
    -webkit-transform: translateX(0%); }
`;
const Icon = styled(CloseIcon)`
  z-index: 20;
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const ContainerWrap = styled.div`
  position: fixed;
  z-index: 99;
  bottom: 50px;
  margin: 0 auto;
  width: 100%;
`;
const FixedBannerIconContainer = styled.div`
  position: fixed;
  z-index: 99;
  bottom: 100px;
  right: 15px;
`;
const Container = styled.div`
  width: 674px;
  margin: 40px auto;
  color: white;
  height: 160px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(247, 247, 247, 0.068);
  box-shadow: 1px 1px 11px #3e3b3b;
  overflow: hidden;
  position: relative;
  -webkit-animation: ${moveFromLeft} 2000ms ease;
`;
const BannerImage = styled.div`
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 159px;
  background-size: cover;
  background-position: center;
  background-image: url(${BannerBottomGIF});
  transition: all 0.5s ease-out;
`;
const BannerIcon = styled.img`
  width: 90px;
  border-radius: 50%;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.4);
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "none",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0, boxShadow: "none" },
})(Dialog);
class Banner extends Component {
  state = {
    openDialog: false,
    status: 0,
    linkUrl: ""
  };
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };
  onhandleSuccessRegister = () => {
    this.setState({ ...this.state, status: 2 });
  };
  onhandleGoMrcat = (mrCatToken) => {
    this.setState({ ...this.state, status: 3, linkUrl:"https://www.mct188.com/cn/?do=login_token&goto=&token="+mrCatToken});
  };
  handleRegisterClick = () => {
    if(this.props.mrcat_username===""){
      this.setState({
        ...this.state,
        openDialog: true,
        status: 1,

      });
    }
    else{
      var isBlocked = false;
      var ua = navigator.userAgent.toLowerCase();
      if(ua.match(/MicroMessenger/i)=="micromessenger") { 
        isBlocked = true
      }
      else if (/\sQQ/i.test(navigator.userAgent)){
        isBlocked = true
      }
      else if (/MQQBrowser/i.test(navigator.userAgent) && /\sQQ/i.test((navigator.userAgent).split('MQQBrowser'))){
        isBlocked = true
      }
      if(isBlocked === true){
        this.setState({
          ...this.state,
          openDialog: true,
          status: 3,
          linkUrl: "https://www.mrt188.com/cn/t/527591/2341?open=/gb_sport"
        });
      }
      else{
        window.open("https://www.mrt188.com/cn/t/527591/2341?open=/gb_sport", "");
      }
    }
  };
  render() {
    return (
      <>
        {this.props.banner ? (
          <ContainerWrap>
            <Container>
              <Icon
                viewBox="4 4 16 16"
                onClick={this.props.handleCloseBanner}
              ></Icon>
              <BannerImage onClick={this.handleRegisterClick}></BannerImage>
            </Container>
            <StyledDialog
              open={this.state.openDialog}
              onClose={this.onhandleCloseDialog}
              fullWidth={true}
              maxWidth={false}
            >
              <MrCatRegister
                linkUrl = {this.state.linkUrl}
                status ={this.state.status}
                onhandleGoMrcat={this.onhandleGoMrcat}
                onhandleSuccessRegister= {this.onhandleSuccessRegister}
                onhandleCloseDialog={this.onhandleCloseDialog}
              ></MrCatRegister>
            </StyledDialog>
          </ContainerWrap>
        ) : (
          <FixedBannerIconContainer>
            <BannerIcon
              src={MrCatLogoPNG}
              onClick={this.props.handleOpenBanner}
            ></BannerIcon>
          </FixedBannerIconContainer>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    mrcat_username: state.account.mrcat_username,
  };
};
export default connect(
  mapStateToProps,
  null
)(withRouter(Banner));

