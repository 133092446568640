import React, { Component } from "react";
import styled from "styled-components";
import GuestContainer from "./GuestContainer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { accountAction } from "../../../actions/accountAction";
import LogoPNG from "../../../../src/assets/images/logo.png";

const Appbar = styled.div`
  display: block;
  width: 100%;
  min-width: 1240px;
  background-color: rgba(17, 48, 102, 0.9);
  height: 50px;
  margin: auto 0;
  display: flex;
  justify-content: center;
`;
const AppbarContainer = styled.div`
  width: 1200px;
  height: 100%;
`;
const LogoContainer = styled.div`
  width: 180px;
  float: left;
  height: 100%;
`;
const NavLogo = styled.span`
  width: 180px;
  height: 50px;
  background-image: url(${LogoPNG});
  background-position: left center;
  background-size: auto 80%;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  cursor: pointer;
`;
const NavItems = styled.ul`
  float: left;
  width: 500px;
  margin-block-start: 12px;
  padding-inline-start: 30px;
`;
const NavItem = styled.li`
  position: static;
  float: left;
  width: 20%;
  text-align: center;
  display: block;
  font-size: 20px;
`;

const LogoutButton = styled.div`
  color: #0a2484;
  font-size: 20px;
  cursor: pointer;
  width: 85px;
  height: 35px;
  background-color: #44e9fd;
  text-align: center;
  line-height: 35px;
  font-weight: 600;
  transition: 0.3s;
  &:hover {
    background-color: #03bfd6;
    color: #34468a;
  }
`;
const NavText = styled.div`
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  width: 80px;
  &:hover {
    color: #e6e6e6;
  }
  ${(props) =>
    props.active
      ? `
      color: #3cd1e6;
      &:hover {
        color: #3cd1e6;
      }
    `
      : `
    
    `}
`;
const LoginContainer = styled.div`
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
`;
const AccountButton = styled.div`
  color: #fff;
  color: rgb(242, 230, 125)
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  margin-left:10px;
  &:hover {
    color: #3cd1e6;
  }
  
`;
const AccountText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 20px;
  letter-spacing: 2px;
`;
const AccountTextLabel = styled(AccountText)`
  margin-right: 10px;
  margin-left: 5px;
`;
class NavBar extends Component {
  state = {
    activePage: "",
    navBarItem: [
      { name: "我的应援", route: "/mypredict" },
      { name: "活动说明", route: "/rules" },
      { name: "奖励兑换", route: "/reward" },
      { name: "关于我们", route: "/aboutus" },
    ],
  };
  componentDidMount() {
    this.setState({
      activePage: this.props.location.pathname,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activePage !== this.props.location.pathname) {
      this.setState({
        activePage: this.props.location.pathname,
      });
    }
  }
  activePage = this.props.location.pathname;
  onhandleLogoClick = () => {
    this.props.history.push("/");
  };
  onhandleRoute = (route) => {
    this.props.history.push(route);
  };
  handleLogoutClick = () => {
    this.props.accountLogout();
    this.props.history.push("/login");
  };
  render() {
    return (
      <Appbar>
        <AppbarContainer>
          <LogoContainer>
            <NavLogo onClick={this.onhandleLogoClick}></NavLogo>
          </LogoContainer>

          <NavItems>
            {this.state.navBarItem.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <NavItem>
                    <NavText
                      onClick={() => {
                        this.onhandleRoute(row.route);
                      }}
                      active={this.state.activePage === row.route}
                    >
                      {row.name}
                    </NavText>
                  </NavItem>
                </React.Fragment>
              );
            })}
          </NavItems>
          <LoginContainer>
            {this.props.token ? (
              <>
                <AccountText>Hi 用户:</AccountText>
                <AccountButton
                  onClick={() => {
                    this.onhandleRoute("/account");
                  }}
                >
                  {this.props.username}
                </AccountButton>
                <AccountTextLabel>,您好</AccountTextLabel>
                {/* <Splitter></Splitter> */}
                <LogoutButton
                  onClick={() => {
                    this.handleLogoutClick();
                  }}
                >
                  登出
                </LogoutButton>
              </>
            ) : (
              <GuestContainer></GuestContainer>
            )}
          </LoginContainer>
        </AppbarContainer>
      </Appbar>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
    username: state.account.username,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    accountLogout: (token) => dispatch(accountAction.accountLogout(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar));
