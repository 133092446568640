import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Config from "../../../config/config";
import { accountAction } from "../../../actions/accountAction";
import { CommonService } from "../../../services/CommonService";
import { withSnackbar } from "notistack";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
const ContainerWrap = styled.div`
  width: 680px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 680px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const HeaderBar = styled.div`
  width: 100%;
  height: 90px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const Title = styled.div`
  font-size: 40px;
  padding-top: 30px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 6px;
  width: 100px;
  background-color: #fff;
  margin: 10px auto;
`;
const BoardImageBottom = styled.img`
  width: 500px;
  height: 25px;
  margin-left: 80px;
`;
const ConfirmButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 50px;
  padding-bottom: 40px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "3.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 75,
    fontSize: 25,
    padding: "0 100px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const ProfileImageContainer = styled.div`
  width: 615px;
  margin: 50px auto 0px auto;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  box-sizing: border-box;
`;
const AvatarImg = styled.img`
  width: 160px;
  height: 160px;
`;
const ProfileImage = styled.div`
  background-color: white;
  width: 160px;
  height: 160px;
  float: left;
  margin: 15px 12px 12px 12px;
  border: 4px solid transparent;
  ${(props) =>
    props.active
      ? `
      border: 4px solid #37bfd9;
    `
      : `
    
    `}
`;
class ChangeProfileImage extends Component {
  state = {
    selected: 0,
    image: [],
  };
  componentDidMount() {
    CommonService.GetAvatarImage(this.props.token).then((response) => {
      if (response.IsSuccess) {
        this.setState(
          {
            image: response.data,
          },
          () => {
            if (this.props.avatar_url === "") {
              this.setState({
                selected: 1,
              });
            } else {
              for (var i = 0; i < this.state.image.length; i++) {
                if (this.props.avatar_url === this.state.image[i].avatar_url) {
                  this.setState({
                    selected: this.state.image[i].avatar_id,
                  });
                }
              }
            }
          }
        );
      }
    });
  }
  handleChangeProfileImage = (value) => {
    this.setState({ ...this.state, selected: value });
  };
  handleUpdateProfileImage = () => {
    const selected = this.state.selected;
    const token = this.props.token;
    CommonService.ChangeProfileImage(token, selected).then(async (response) => {
      if (response.IsSuccess) {
        this.props.enqueueSnackbar(response.msg, {
          variant: "success",
        });
        await this.props.getProfile(token);
        this.props.onhandleCloseDialog();
      }
    });
  };
  render() {
    return (
      <ContainerWrap>
        <Container>
          <HeaderBar>
            <Icon
              viewBox="4 4 16 16"
              onClick={this.props.onhandleCloseDialog}
            ></Icon>
            <Title>头像选择</Title>
            <Splitter></Splitter>
          </HeaderBar>
          <ProfileImageContainer>
            {this.state.image.map((row, index) => {
              return (
                <ProfileImage
                  key={index}
                  active={this.state.selected === row.avatar_id ? true : false}
                  onClick={() => {
                    this.handleChangeProfileImage(row.avatar_id);
                  }}
                >
                  <AvatarImg
                    src={Config.apiImgSrc + row.avatar_url}
                  ></AvatarImg>
                </ProfileImage>
              );
            })}
          </ProfileImageContainer>
          <ConfirmButtonContainer>
            <NextButton
              onClick={this.handleUpdateProfileImage}
              variant="outlined"
            >
              确定
            </NextButton>
          </ConfirmButtonContainer>
        </Container>
        <BoardImageBottom
          src={Decoration04}
        ></BoardImageBottom>
      </ContainerWrap>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    avatar_url: state.account.avatar_url,
    token: state.account.token,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(ChangeProfileImage)));
