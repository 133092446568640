import { ApiService } from "./ApiService";
import config from "../config/config";
export const RewardService = {
  GetRewardList,
  TakeReward,
  PrizeRecord,
  GetAffiliate,
};

function GetRewardList(token) {
  const result = ApiService.post( config.api+"/lol_prize/achievementList", {
    token: token,
    race_id: config.raceID,
  });
  return result;
}
function TakeReward(token, type) {
  const result = ApiService.post( config.api+"/lol_prize/receivePrize", { token: token, type: type, race_id: config.raceID, });
  return result;
}
function PrizeRecord(token, page, limit) {
  const result = ApiService.post( config.api+"/lol_prize/receiveHistory", {
    token: token,
    page: page,
    limit: limit,
    race_id: config.raceID,
  });
  return result;
}
function GetAffiliate(token) {
  const result = ApiService.post( config.api+"/lol_prize/inviteFriend", { token: token });
  return result;
}
