import { ApiService } from "./ApiService";
import config from "../config/config";
export const CommonService = {
  GetBasicElement,
  ShareProfileSuccess,
  SharePostSuccess,
  GetAvatarImage,
  ChangeProfileImage,
  GetBroadCast
};

function GetBasicElement() {
  const result = ApiService.get(
    config.api + "/lol_index/basicElement?from=" + config.from
  );
  return result;
}
function GetBroadCast() {
  const result = ApiService.get(
    config.api + "/lol_index/getBroadcast"
  );
  return result;
}
function ShareProfileSuccess(token) {
  const result = ApiService.post(config.api + "/lol_user/shareInfoCB", {
    token: token,
    race_id: config.raceID,
  });
  return result;
}

function SharePostSuccess(token) {
  const result = ApiService.post(config.api + "/lol_user/shareOrderCB", {
    token: token,
    race_id: config.raceID,
  });
  return result;
}
function GetAvatarImage(token) {
  const result = ApiService.post(config.api + "/lol_user/getAvatars", {
    token: token,
  });
  return result;
}
function ChangeProfileImage(token, avatar_id) {
  const result = ApiService.post(config.api + "/lol_user/updateAvatar", {
    token: token,
    avatar_id: avatar_id,
  });
  return result;
}
