import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { accountAction } from "../../actions/accountAction";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { AccountService } from "../../services/AccountService";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import Decoration04 from "../../../src/assets/images/decoration_04.png";
const HeaderBar = styled.div`
  width: 100%;
  height: 90px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const ContainerWrap = styled.div`
  width: 680px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 680px;
  min-height: 1100px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const RegisterForm = styled.div`
  margin: 35px auto 0 auto;
  width: 680px;
`;
const RegisterTitle = styled.div`
  font-size: 40px;
  padding-top: 30px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 6px;
  width: 100px;
  background-color: #fff;
  margin: 10px auto;
`;
const RegisterButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;
  height: 110px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "3.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 75,
    fontSize: 25,
    padding: "0 100px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);

const TextLabel = styled.div`
  font-size: 30px;
  color: #fff;
  width: 200px;
  margin: 20px 0px 0px 100px;
`;
const CustomTextField = withStyles({
  root: {
    marginLeft: "100px",
    width: "500px",
    height: "90px",
    margin: 0,
    marginTop: "20px",
    background: "transparent",
    "& .MuiOutlinedInput-input": {
      fontSize: "30px",
      color: "#fff",
      lineHeight: "45px",
      height: "45px",
    },

    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        borderWidth: "4px",
        height: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "4px",
        height: "80px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "4px",
        height: "80px",
      },
    },
  },
})(TextField);
const CodeContainer = styled.div`
  width: 500px;
  height: 50px;
  margin: 0 auto;
`;
const CodeButtonContainer = styled.div`
  float: right;
  border-bottom-style: dotted;
  ${(props) =>
    props.deactive
      ? `
    border-bottom-color: #a2a0a0;
  `
      : `
    border-bottom-color: #a4a9c8;
  `}
`;
const PasswordLabel = styled.div`
  width: 485px;
  color: #6c72d0;
  font-size: 25px;
  margin: 10px auto 0 auto;
`;
const CodeButton = withStyles((theme) => ({
  root: {
    background: "transparent",
    color: "#d6d2d2",
    marginTop: "10px",
    height: "45px",
    fontSize: 20,
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      color: "#fff",
    },
    "&:disabled": {
      color: "#989898",
    },
  },
}))(Button);
const CountDownText = styled.div`
  float: left;
  text-align: left;
  height: 45px;
  color: #6c72d0;
  line-height: 24px;
  font-size: 20px;
  width: 310px;
  margin: 20px 0 0 15px;
`;
const ErrorText = styled.div`
  text-align: left;
  min-height: 40px;
  color: #fff;
  font-size: 25px;
  width: 500px;
  margin: 20px auto 0 100px;
`;
const BoardImageBottom = styled.img`
  width: 500px;
  height: 25px;
  margin-left: 80px;
`;
class Register extends Component {
  state = {
    phone: "",
    verCode: "",
    password: "",
    confirmPassword: "",
    email:"",
    qq: "",
    ver: "",
    errMsg: "",
    countdownPhone: 0,
    valType: true,
    inviteCode:
      localStorage.getItem("inviteCode") === "" ||
      localStorage.getItem("inviteCode") === null
        ? ""
        : localStorage.getItem("inviteCode"),
  };
  timerPhone = null;
  componentDidMount() {}
  componentWillUnmount() {
    if (this.timerPhone != null) {
      clearTimeout(this.timerPhone);
    }
  }
  handlePhoneChange = (e) => {
    this.setState({ ...this.state, phone: e.target.value });
  };
  handleVerifyCodeChange = (e) => {
    this.setState({ ...this.state, verCode: e.target.value });
  };
  handleEmailChange = (e) => {
    this.setState({ ...this.state, email: e.target.value });
  };
  handleQQChange = (e) => {
    this.setState({ ...this.state, qq: e.target.value });
  };
  handlePasswordChange = (e) => {
    this.setState({ ...this.state, password: e.target.value });
  };
  handleConfirmPasswordChange = (e) => {
    this.setState({ ...this.state, confirmPassword: e.target.value });
  };
  handleRegisterClick = () => {
    var reQQ = new RegExp("^[1-9]\\d{4,14}$");
    var reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phone = this.state.phone;
    const verCode = this.state.verCode;
    const password = this.state.password;
    const email = this.state.email;
    const qq = this.state.qq;
    const confirmPassword = this.state.confirmPassword;
    const inviteCode = this.state.inviteCode;
    if (phone === "") {
      this.setState({ valType: false, errMsg: "请输入电话号码" });
      return;
    }
    if (verCode === "") {
      this.setState({ valType: false, errMsg: "请输入验证码" });
      return;
    }
    if (email === "") {
      this.setState({ valType: false, errMsg: "请输入邮箱地址" });
      return;
    }
    if (!reEmail.test(email)) {
      this.setState({ valType: false, errMsg: "邮箱格式不真确" });
      return;
    }
    if (qq === "") {
      this.setState({ valType: false, errMsg: "请输入QQ号" });
      return;
    }
    if (!qq.match(reQQ)) {
      this.setState({ valType: false, errMsg: "QQ号码格式不正确" });
      return;
    }
    if (password === "") {
      this.setState({ valType: false, errMsg: "请输入密码" });
      return;
    }
    if (
      password.length < 6 ||
      password.length > 12 ||
      password.match(/[^A-Za-z0-9]+/g) 
    ) {
      this.setState({ valType: false, errMsg: "密码需为6-12位英数区分大小写" });
      return;
    }
    if (confirmPassword === "") {
      this.setState({ valType: false, errMsg: "请输入确认密码" });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({ valType: false, errMsg: "密码不相符，请再试一次" });
      return;
    }
    this.props
      .registerAccount(phone, verCode, password, confirmPassword, inviteCode, email, qq)
      .then((result) => {
        if (result.IsSuccess) {
          this.handleLoginSuccess(result.data);
        } else {
          this.setState({ valType: false, errMsg: result.msg });
        }
      });
  };
  handleLoginSuccess = (token) => {
    this.props.getProfile(token).then((result) => {
      if (result.IsSuccess) {
        this.props.enqueueSnackbar("注册成功", {
          variant: "success",
        });
        this.props.history.push("/mypredict");
      }
    });
  };
  onhandleGetPhoneCodeClick = () => {
    if (this.state.countdownPhone <= 0) {
      AccountService.GetPhoneVerificationCode(
        this.state.phone,
        "register"
      ).then((response) => {
        if (response.IsSuccess) {
          this.setState({
            ...this.state,
            countdownPhone: response.data,
            errMsg: "",
          });
          this.checkCountdownPhone();
        } else {
          this.setState({
            ...this.state,
            errMsg: response.msg,
          });
        }
      });
    }
  };
  checkCountdownPhone = () => {
    if (this.state.countdownPhone > 0) {
      this.timerPhone = setTimeout(() => {
        this.setState({
          ...this.state,
          countdownPhone: this.state.countdownPhone - 1,
        });
        this.checkCountdownPhone();
      }, 1000);
    }
  };
  render() {
    return (
      <ContainerWrap>
        <Container>
          <HeaderBar>
            <Icon
              viewBox="4 4 16 16"
              onClick={this.props.onhandleCloseDialog}
            ></Icon>
            <RegisterTitle>会员注册</RegisterTitle>
            <Splitter></Splitter>
          </HeaderBar>
          <RegisterForm>
            <TextLabel>手机号码</TextLabel>
            <CustomTextField
              onChange={this.handlePhoneChange}
              value={this.state.phone}
              variant="outlined"
              placeholder="请输入手机号码"
              InputLabelProps={{ shrink: true, focused: false }}
              inputProps={{
                maxLength: 16,
              }}
            />
            <CodeContainer>
              <CodeButtonContainer
                deactive={
                  this.state.countdownPhone > 0 || this.state.phone === ""
                }
              >
                <CodeButton
                  onClick={this.onhandleGetPhoneCodeClick}
                  disabled={
                    this.state.countdownPhone > 0 || this.state.phone === ""
                  }
                >
                  发送验证码
                </CodeButton>
              </CodeButtonContainer>
              {this.state.countdownPhone > 0 ? (
                <CountDownText>
                  验证码已发送，倒数 {this.state.countdownPhone}秒
                </CountDownText>
              ) : (
                <></>
              )}
            </CodeContainer>
            <TextLabel>验证码</TextLabel>
            <CustomTextField
              onChange={this.handleVerifyCodeChange}
              value={this.state.verCode}
              variant="outlined"
              placeholder="请输入验证码"
              InputLabelProps={{ shrink: true, focused: false }}
              inputProps={{
                maxLength: 16,
              }}
            />
            <TextLabel>邮箱地址</TextLabel>
            <CustomTextField
              onChange={this.handleEmailChange}
              value={this.state.email}
              variant="outlined"
              placeholder="请输入邮箱地址"
              InputLabelProps={{ shrink: true, focused: false }}
            />
            <TextLabel>QQ</TextLabel>
            <CustomTextField
              onChange={this.handleQQChange}
              value={this.state.qq}
              variant="outlined"
              placeholder="请输入QQ号"
              InputLabelProps={{ shrink: true, focused: false }}
            />
            <TextLabel>密码</TextLabel>
            <CustomTextField
              onChange={this.handlePasswordChange}
              value={this.state.password}
              placeholder="请输入密码"
              type="password"
              variant="outlined"
              InputLabelProps={{ shrink: true, focused: false }}
              inputProps={{
                maxLength: 12,
              }}
            />
            <PasswordLabel>密码需为6-12位英数区分大小写</PasswordLabel>

            <TextLabel>密码确认</TextLabel>
            <CustomTextField
              onChange={this.handleConfirmPasswordChange}
              value={this.state.confirmPassword}
              id="password"
              placeholder="请确认密码"
              type="password"
              variant="outlined"
              InputLabelProps={{ shrink: true, focused: false }}
              inputProps={{
                maxLength: 12,
              }}
            />

            <ErrorText>{this.state.errMsg}</ErrorText>
            <RegisterButtonContainer>
              <NextButton onClick={this.handleRegisterClick} variant="outlined">
                确定
              </NextButton>
            </RegisterButtonContainer>
          </RegisterForm>
        </Container>
        <BoardImageBottom
          src={Decoration04}
        ></BoardImageBottom>
      </ContainerWrap>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    registerAccount: (
      phoneNumber,
      verifyCode,
      password,
      confirmPassword,
      inviteCode,
      email,
      qq
    ) =>
      dispatch(
        accountAction.registerAccount(
          phoneNumber,
          verifyCode,
          password,
          confirmPassword,
          inviteCode,
          email,
          qq
        )
      ),
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(withRouter(withSnackbar(Register)));
