import axios from "axios";
var qs = require("qs");
export const ApiService = {
  get,
  post,
};
const custmHeader = {};

function get(apiEndpoint) {
  return axios
    .get(apiEndpoint, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        ...custmHeader,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
}

function post(apiEndpoint, payload) {
  let request = null;
  request = axios
    .post(
      apiEndpoint,

      qs.stringify(payload),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
  return request;
}
