import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { AccountService } from "../../../services/AccountService";
import { withRouter } from "react-router-dom";
import { withSnackbar } from "notistack";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
import MrcatRegisterLogoPNG from "../../../../src/assets/images/mrcatregisterlogo-m.png";
import { accountAction } from "../../../actions/accountAction";
import QRCode from "qrcode.react";
import Loading from "../../Web/Loading";
const HeaderBar = styled.div`
  width: 100%;
  height: 160px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const ContainerWrap = styled.div`
  width: 680px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 680px;
  min-height: 800px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const RegisterForm = styled.div`
  margin: 0 auto;
  width: 680px;
  padding: 50px 0;
`;
const RegisterTitle = styled.div`
  font-size: 40px;
  padding-top: 70px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const TitleSpan = styled.span`
  color: #ff0000;
`;
const Splitter = styled.div`
  height: 6px;
  width: 100px;
  background-color: #fff;
  margin: 25px auto;
`;
const RegisterButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 40px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    borderWidth: "3.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 75,
    fontSize: 25,
    padding: "0 100px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);

const TextLabel = styled.div`
  font-size: 30px;
  color: #fff;
  width: 200px;
  margin: 20px 0px 0px 100px;
`;
const CustomTextField = withStyles({
  root: {
    marginLeft: "100px",
    width: "500px",
    height: "90px",
    margin: 0,
    marginTop: "20px",
    background: "transparent",
    "& .MuiOutlinedInput-input": {
      fontSize: "30px",
      color: "#fff",
      lineHeight: "45px",
      height: "45px",
    },

    "& label.Mui-focused": {
      color: "#b6b6b6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#b6b6b6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
        borderWidth: "4px",
        height: "80px",
      },
      "&:hover fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "4px",
        height: "80px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#b6b6b6",
        borderWidth: "4px",
        height: "80px",
      },
    },
  },
})(TextField);

const ErrorText = styled.div`
  text-align: left;
  min-height: 30px;
  color: #fff;
  font-size: 25px;
  width: 500px;
  margin: 20px auto 0 100px;
`;
const BoardImageBottom = styled.img`
  width: 500px;
  height: 25px;
  margin-left: 80px;
`;
const LogoContainer = styled.div`
  display: flex;
  padding: 0px 0px 30px 0;
  justify-content: center;
`;
const MrCatRegisterLogo = styled.img`
  width: 330px;
`;
const IssueTitle = styled.div`
  font-size: 30px;
  padding-top: 50px;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 700;
  line-height: 40px;
`;
const IssueText = styled.div`
  font-size: 24px;
  padding-top: 10px;
  color: #fff;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 20px;
`;
const RegisterSuccessTitle = styled.div`
  font-size: 40px;
  padding-top: 70px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
  margin-bottom: 20px;
  line-height: 65px;
`;
const RefText = styled.div`
  width: 454px;
  margin: 95px auto 30px auto;
  font-size: 30px;
  color: #fff;
  letter-spacing: 6px;
  font-weight: 600;
  text-align: center;
  line-height: 65px;
`;
const RegisterSuccessButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  padding-bottom: 50px;
`;
const UsernameLabel = styled.div`
  width: 485px;
  color: #6c72d0;
  font-size: 25px;
  margin: 10px auto 0 auto;
`;
const QRCodeImage = styled.div`
  margin: 40px auto 20px auto;
  display: flex;
  justify-content: center;
`;
const QRCodeContainer = styled.div`
  background-color: #fff;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AffiliateLink = styled.div`
  margin: 30px 40px;
`;
const CodeLinkSpan = styled.input`
  outline: none;
  line-height: 35px;
  color: #fff;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  border-width: 0px;
  width: 95%;
  background-color: transparent;
  font-size: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ShareButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 ;
`;
const RegisterSuccessButton = withStyles((theme) => ({
  root: {
    backgroundColor: "#dddeed",
    color: "#b03744",
    height: 75,
    fontSize: 25,
    padding: "0 100px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      backgroundColor: "#b03744",
      color: "#dddeed",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
class MrCatRegister extends Component {
  state = {
    step: 1,
    username: "",
    password: "",
    confirmPassword: "",
    errMsg: "",
    successMsg: "",
    mrCatToken: "",
    show: false,
  };

  handleUsernameChange = (e) => {
    this.setState({ ...this.state, username: e.target.value });
  };
  handleVerifyCodeChange = (e) => {
    this.setState({ ...this.state, verCode: e.target.value });
  };
  handlePasswordChange = (e) => {
    this.setState({ ...this.state, password: e.target.value });
  };
  handleConfirmPasswordChange = (e) => {
    this.setState({ ...this.state, confirmPassword: e.target.value });
  };
  handleMrCatWebsite = () => {
    var isBlocked = false;
    var ua = navigator.userAgent.toLowerCase();
    if(ua.match(/MicroMessenger/i)=="micromessenger") { 
      isBlocked = true
    }
    else if (/\sQQ/i.test(navigator.userAgent)){
      isBlocked = true
    }
    else if (/MQQBrowser/i.test(navigator.userAgent) && /\sQQ/i.test((navigator.userAgent).split('MQQBrowser'))){
      isBlocked = true
    }
    if(isBlocked === true){
      this.props.onhandleGoMrcat(this.state.mrCatToken)
    }
    else{
      window.open("https://www.mct188.com/cn/?do=login_token&goto=&token="+this.state.mrCatToken, "");
    }
  };
  copyText = (e) => {
    var copyText = document.getElementById("mrCatlink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  handleRegisterClick = () => {
    const username = this.state.username;
    const password = this.state.password;
    const confirmPassword = this.state.confirmPassword;
    if (!this.props.token) {
      this.setState({ errMsg: "请登录" });
      return;
    }
    if (username === "") {
      this.setState({ errMsg: "请输入用户名" });
      return;
    }
    if ( 
      username.length < 8 ||
      username.length > 16 ||
      !username.match(/([0-9].*[a-z])|([a-z].*[0-9])/)||
      username.match(/[A-Z]+/)
    )
    {
      this.setState({ errMsg: "用户名需为8-16码小写英文与数字" });
      return;
    }
    if (password === "") {
      this.setState({ errMsg: "请输入密码" });
      return;
    }
    if (confirmPassword === "") {
      this.setState({ errMsg: "请输入确认密码" });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({ errMsg: "密码不一致" });
      return;
    }
    this.setState({
      errMsg: "",
      successMsg: "",
      show: true
    });
    AccountService.RegisterMrCat(
      this.props.token,
      this.state.username,
      this.state.password,
      this.state.confirmPassword
    ).then((result) => {
      if (result.IsSuccess) {
        this.props.getProfile(this.props.token)
        this.props.onhandleSuccessRegister();
        this.setState({
          mrCatToken: result.data,
          show: false,
          errMsg: ""
        });
      } else {
        this.setState({ errMsg: result.msg, successMsg: "",  show: false });
      }
    });
  };
  render() {
    return (
      <ContainerWrap>
        <Loading show={this.state.show}></Loading>
        <Container>
          <HeaderBar>
            <Icon
              viewBox="4 4 16 16"
              onClick={this.props.onhandleCloseDialog}
            ></Icon>
            {this.props.status === 1 ? (
              <RegisterTitle>
                新用户 注册即送<TitleSpan>18</TitleSpan>元体验金
              </RegisterTitle>
            ) :this.props.status === 2 ? (
              <RegisterSuccessTitle>
                恭喜您完成注册
                <br />
                获得体验金<TitleSpan>18</TitleSpan>元
              </RegisterSuccessTitle>
            ) :this.props.status === 3 ?(
              <>
              <IssueTitle>无法访问该页面，请尝试使用其他的浏览器</IssueTitle>
              <IssueText>如Google Chrome, Safari, Firefox等</IssueText>
              </>
            ):<></>
          }
            <Splitter></Splitter>
          </HeaderBar>
          {this.props.status === 1 ? (
            <RegisterForm>
              <TextLabel>用户名</TextLabel>
              <CustomTextField
                onChange={this.handleUsernameChange}
                value={this.state.username}
                variant="outlined"
                placeholder="请输入用户名"
                InputLabelProps={{ shrink: true, focused: false }}
                inputProps={{
                  maxLength: 16,
                }}
              />
              <UsernameLabel>用户名需为8-16码小写英文与数字</UsernameLabel>
              <TextLabel>密码</TextLabel>
              <CustomTextField
                onChange={this.handlePasswordChange}
                value={this.state.password}
                placeholder="请输入密码"
                type="password"
                variant="outlined"
                InputLabelProps={{ shrink: true, focused: false }}
                inputProps={{
                  maxLength: 20,
                }}
              />
              <TextLabel>密码确认</TextLabel>
              <CustomTextField
                onChange={this.handleConfirmPasswordChange}
                value={this.state.confirmPassword}
                id="password"
                placeholder="请确认密码"
                type="password"
                variant="outlined"
                InputLabelProps={{ shrink: true, focused: false }}
                inputProps={{
                  maxLength: 20,
                }}
              />
              <ErrorText>{this.state.errMsg}</ErrorText>
              <RegisterButtonContainer>
                <NextButton
                  onClick={this.handleRegisterClick}
                  variant="outlined"
                >
                  确定
                </NextButton>
              </RegisterButtonContainer>
            </RegisterForm>
          ) : this.props.status === 2 ?(
            <>
              <RefText>
                体验金约需1-5分钟后到帐即刻前往 体验最佳电竞平台
              </RefText>
              <RegisterSuccessButtonContainer>
                <RegisterSuccessButton onClick={()=>{this.handleMrCatWebsite()}}>
                  前往猫先生平台
                </RegisterSuccessButton>
              </RegisterSuccessButtonContainer>
            </>
          ): this.props.status === 3 ?(
            <>
            <QRCodeImage>
              <QRCodeContainer>
                <QRCode
                  value={this.props.linkUrl}
                  style={{ height: "200px", width: "200px" }}
                />
              </QRCodeContainer>
          </QRCodeImage>
          <AffiliateLink>
            <CodeLinkSpan
              id="mrCatlink"
              readOnly={true}
              value={this.props.linkUrl}
            ></CodeLinkSpan>
          </AffiliateLink>
             < ShareButtonContainer>
              <NextButton onClick={this.copyText} variant="outlined">
                点击复制
              </NextButton>
            </ShareButtonContainer>
            </>
          )
          :<></>
        }
          <LogoContainer>
            <MrCatRegisterLogo
              src={MrcatRegisterLogoPNG}
            ></MrCatRegisterLogo>
          </LogoContainer>
        </Container>
        <BoardImageBottom
         src={Decoration04}
        ></BoardImageBottom>
      </ContainerWrap>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(MrCatRegister)));
