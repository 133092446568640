import React, { Component } from "react";
import styled from "styled-components";
import Board from "./Board";
import Player from "./Player";
import Banner from "./Banner";
import { PredictService } from "../../../services/PredictService";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const Container = styled.div`
  display: block;
  width: 1200px;
  margin: 0 auto;
  height: auto;
  min-height: 200px;
`;
const LeftContainer = styled.div`
  width: 900px;
  float: left;
`;
const RightContainer = styled.div`
  width: 300px;
  float: left;
`;
class Home extends Component {
  state = {
    list: [],
    checkBannerValid: false,
  };
  componentDidMount() {
    PredictService.GetGuruPredictList(this.props.token, "").then((response) => {
      if (response.IsSuccess) {
        this.setState({
          list: response.data.slice(0, 3),
        });
      }
    });
    PredictService.GetMyPredictList(this.props.token, "").then((response) => {
      if (response.IsSuccess) {
        if (response.data.length >= 2) {
          this.setState({ checkBannerValid: true });
        }
      }
    });
  }
  render() {
    return (
      <Container>
        <LeftContainer>
          <Player list={this.state.list}></Player>
        </LeftContainer>
        <RightContainer>
          <Board 
            qrcode = {this.props.qrcode}
          // board={this.props.board}
          ></Board>
        </RightContainer>
        {this.state.checkBannerValid && this.props.token ? (
          <Banner></Banner>
        ) : (
          <></>
        )}
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.account.token,
  };
};
export default connect(mapStateToProps, null)(withRouter(Home));
