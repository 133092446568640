import React, { Component } from "react";
import styled from "styled-components";
import { accountAction } from "../../actions/accountAction";
import { connect } from "react-redux";
import QRCode from "qrcode.react";
import WechatHelpPNG from "../../../src/assets/images/wechat_help.png";
import SharePNG from "../../../src/assets/images/share.png";
const Container = styled.div`
  width: 735px;
  min-height: 500px;
  margin: 50px auto 0px auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px #ddd;
  display: flex;
  position: relative;
`;
const LeftContainer = styled.div`
  height: 500px;
  width: 350px;
  position: relative;
`;
const BorderRight = styled.div`
  height: 450px;
  position: absolute;
  top: 30px;
  bottom: 0;
  position: relative;
  border-right: 2px solid grey;
`;
const RightContainer = styled.div`
  height: 500px;
  width: 450px;
`;
const Title = styled.div`
  font-size: 30px;
  color: black;
  position: absolute;
  top: 50px;
  left: 84px;
`;
const ProfileImg = styled.img`
  width: 90%;
  height: 100%;
`;
const QRCodeImage = styled.div`
  position: absolute;
  top: 130px;
  left: 80px;
`;
const LolImage = styled.img`
  width: 430px;
  position: absolute;
  left: -70px;
  bottom: -87px;
  z-index: 90;
`;
class WechatShare extends Component {
  state = {
    qrcodeUrl: "",
  };
  componentDidMount() {
    var checkUrlValues = window.location.href.includes("url");
    if (checkUrlValues) {
      var splitUrl = window.location.href.split("=");
      var url = splitUrl[splitUrl.length - 1];
      this.setState({ qrcodeUrl: url });
    }
  }
  render() {
    return (
      <Container>
        <LolImage src={SharePNG}></LolImage>
        <LeftContainer>
          <BorderRight></BorderRight>
          <Title>分享到微信</Title>
          <QRCodeImage>
            {this.state.qrcodeUrl !== "" ? (
              <QRCode
                value={this.state.qrcodeUrl}
                style={{ height: "160px", width: "160px" }}
              />
            ) : (
              <></>
            )}
          </QRCodeImage>
        </LeftContainer>
        <RightContainer>
          <ProfileImg
            src={WechatHelpPNG}
          ></ProfileImg>
        </RightContainer>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    weiboName: state.account.weiboName,
    weiboPic: state.account.weiboImage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (token) => dispatch(accountAction.getProfile(token)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WechatShare);
