import { accountReducer } from "./accountReducer";
import {uiReducer} from "./uiReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  account: accountReducer,
  ui: uiReducer,
});

export default rootReducer;
