import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import Pagination from "@material-ui/lab/Pagination";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
const HeaderBar = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
`;
const Icon = styled(CloseIcon)`
  position: absolute;
  right: 0px;
  border: solid 2px #bec3de;
  color: #bec3de;
  margin: 10px 10px 0 0;
  &:hover {
    cursor: pointer;
  }
`;
const ContainerWrap = styled.div`
  width: 1000px;
  height: 600px;
  position: relative;
  margin: 0 auto;
`;
const Container = styled.div`
  width: 1000px;
  height: 550px;
  position: relative;
  margin: 0 auto;
  background-color: #1a2285;
`;
const Title = styled.div`
  font-size: 28px;
  padding-top: 30px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 3px;
  width: 50px;
  background-color: #fff;
  margin: 8px auto;
  position: absolute;
  top: 70px;
  left: 470px;
`;
const TableContainer = styled.div`
  height: 400px;
  width: 900px;
  margin: 40px auto;
  user-select: text;
`;
const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: "transparent",
    color: "#fff",
    fontSize: 22,
    paddingBottom: "20px",
  },
  body: {
    padding: "10px",
    fontSize: 22,
    color: "#fff",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#0d0c69!important",
      cursor: "pointer",
    },
  },
}))(TableRow);
const BottomPagination = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
}))(Pagination);

const BoardImageBottom = styled.img`
  width: 650px;
  height: 30px;
  margin-left: 80px;
`;
const PaginationContainer = styled.div`
  margin: 30px auto;
  bottom: 0px;
  position: absolute;
  width: 900px;
`;
function PrizeRecordList(props) {
  const handleChangePage = (event, newPage) => {
    props.onhandleChangePage(newPage);
  };
  return (
    <ContainerWrap>
      <Container>
        <HeaderBar>
          <Icon viewBox="4 4 16 16" onClick={props.onhandleCloseDialog}></Icon>
          <Title>获奖记录</Title>
          <Splitter></Splitter>
        </HeaderBar>
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" style={{ width: "33%" }}>
                  兑换奖品
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "33%" }}>
                  获奖时间
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "33%" }}>
                  附注
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.rows.map((row, index) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    style={
                      index % 2 === 0
                        ? { backgroundColor: "#040a78" }
                        : { backgroundColor: "transparent" }
                    }
                  >
                    <StyledTableCell align="center" style={{ width: "33%" }}>
                      {row.title}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "33%" }}>
                      {row.received_time}
                    </StyledTableCell>
                    <StyledTableCell align="center" style={{ width: "33%" }}>
                      {row.remark}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
          <PaginationContainer>
            <BottomPagination
              count={props.total}
              page={props.page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
            />
          </PaginationContainer>
        </TableContainer>
      </Container>
      <BoardImageBottom
        src={Decoration04}
      ></BoardImageBottom>
    </ContainerWrap>
  );
}
export default PrizeRecordList;
