import React, { Component } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MrCatRegister from "./MrCatRegister";
import BannerBottomGIF from "../../../../src/assets/images/banner_bottom-pc.gif";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
const Container = styled.div`
  width: 100%;
  margin: 20px 0px;
  color: white;
  height: 153px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: rgba(247, 247, 247, 0.068);
  box-shadow: 1px 1px 11px #1d1d1d;
  overflow: hidden;
  position: relative;
`;
const BannerImage = styled.div`
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 153px;
  background-size: cover;
  background-position: center;
  background-image: url(${BannerBottomGIF});
  transition: all 0.5s ease-out;
  cursor: pointer;
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "none",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0, boxShadow: "none" },
})(Dialog);
class Banner extends Component {
  state = {
    openDialog: false,
  };
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };
  handleRegisterClick = () => {
    if(this.props.mrcat_username===""||!this.props.mrcat_username){
      this.setState({
        ...this.state,
        openDialog: true,
      });
    }
    else{
      window.open("https://www.mrt188.com/cn/t/527591/2341?open=/gb_sport", "");
    }
  };
  render() {
    return (
      <>
        <Container>
          <BannerImage onClick={this.handleRegisterClick}></BannerImage>
        </Container>
        <StyledDialog
          open={this.state.openDialog}
          onClose={this.onhandleCloseDialog}
          fullWidth={true}
          maxWidth={false}
        >
          <MrCatRegister
            onhandleCloseDialog={this.onhandleCloseDialog}
          ></MrCatRegister>
        </StyledDialog>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    mrcat_username: state.account.mrcat_username,
  };
};
export default connect(
  mapStateToProps,
  null
)(withRouter(Banner));
