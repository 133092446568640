import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { withRouter } from "react-router-dom";
import RecordList from "./RecordList";
import ChangePassword from "./ChangePassword";
import Poster from "./Poster";
import { connect } from "react-redux";
import { WeiboService } from "../../../services/WeiboService";
import Config from "../../../config/config";
import { AccountService } from "../../../services/AccountService";
import ChangeProfileImage from "./ChangeProfileImage";
import { CommonService } from "../../../services/CommonService";
import Loading from "../../Web/Loading";
import Decoration04 from "../../../../src/assets/images/decoration_04.png";
import DefaultAvatar from "../../../../src/assets/images/default-avatar.jpg";
import Avatar from "../../../../src/assets/images/avatar.png";
import TitleBG from "../../../../src/assets/images/titlebg.jpg";
export const fadeIn = keyframes`
  from {
    opacity:0; }
  to {
    -opacity:1; }
`;
const Container = styled.div`
  width: 680px;
  margin: 50px auto 150px auto;
  background-color: #1a2285;
  position: relative;
`;
const Title = styled.div`
  font-size: 40px;
  padding-top: 40px;
  color: #fff;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 700;
`;
const Splitter = styled.div`
  height: 6px;
  width: 100px;
  background-color: #fff;
  margin: 10px auto;
`;
const AccountContainer = styled.div`
  height: 270px;
  width: 680px;
  margin: 20px auto;
  position: relative;
`;
const AvatarContainer = styled.div`
  height: 190px;
  width: 180px;
  position: absolute;
  top: 38px;
  left: 30px;
  background-image: url(${Avatar});
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AvatarImage = styled.img`
  height: 140px;
  width: 140px;
  margin-left: 4px;
  margin-top: 2px;
`;
const AccountDetailContainer = styled.div`
  height: 210px;
  width: 400px;
  position: absolute;
  left: 260px;
  top: 40px;
`;
const NextButton = withStyles((theme) => ({
  root: {
    fontSize: 20,
    borderWidth: "1.5px",
    borderColor: "white",
    borderRadius: 3,
    color: "white",
    height: 60,
    padding: "0 40px",
    fontFamily: "PingFangTC-Medium",
    "&:hover": {
      background: "#6c72d0;",
      boxShadow: "0 3px 5px 2px rgba(255, 255, 255, 0.3)",
    },
  },
}))(Button);
const ButtonContainer = styled.div`
  position: absolute;
  padding-top: 30px;
  width: 350px;
  justify-content: space-between;
  display: flex;
`;
const UserText = styled.div`
  color: #fff;
  font-size: 30px;
`;
const EvaluationBox = styled.div`
  background-image: url(${TitleBG});
  position: absolute;
  width: 640px;
  height: 180px;
  background-color: #0d0c69;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
`;
const EvaluationText = styled.div`
  color: white;
  margin: 5px auto 0 auto;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  background: -webkit-linear-gradient(#fb6066, #ff7d70, #ffe3a1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${fadeIn} ease 8s;
`;
const ShareContainer = styled.div`
  color: #fff;
  width: 300px;
  height: 40px;
  font-size: 30px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
`;

const ShareIcon = styled.i`
  color: white;
  cursor: pointer;
`;
const RecordContainer = styled.div`
  width: 90%;
  margin: 20px auto;
`;
const ContainerBorder = styled.img`
  width: 500px;
  height: 35px;
  position: absolute;
  bottom: -35px;
  left: 100px;
`;
const StyledDialog = withStyles({
  root: {
    boxShadow: "none",
  },
  paper: { backgroundColor: "transparent", borderRadius: 0, boxShadow: "none" },
})(Dialog);

class Account extends Component {
  state = {
    show: false,
    openDialog: false,
    openDialogName: "",
    posterDialog: false,
    accountList: { data: [], last_page: 0 },
    page: 1,
  };
  componentDidMount() {
    if (this.props.token === null) {
      this.props.history.push("/login");
    } else {
      this.setState({
        show: true,
      });
      var splitUrl = window.location.href.split("/");
      var lastParam = splitUrl[splitUrl.length - 1];
      var checkCodeValues = lastParam.includes("code");
      if (checkCodeValues) {
        this.setState({
          ...this.state,
          posterDialog: true,
        });
      }
      this.watchHistoryRecord();
      this.setState({ show: false });
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }
  onhandleCloseDialog = () => {
    this.setState({ ...this.state, openDialog: false });
  };
  onhandleClosePosterDialog = () => {
    this.setState({ ...this.state, posterDialog: false });
  };
  onhandleRoute = (route) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.props.history.push(route);
  };
  handleChangePage = (page) => {
    this.setState({ ...this.state, page: page }, () => {
      this.watchHistoryRecord();
    });
  };
  watchHistoryRecord = () => {
    var page = this.state.page;
    var token = this.props.token;
    AccountService.GetHistoryPredictList(token, page, "5").then((response) => {
      if (response.IsSuccess) {
        // response.data.data.sort(
        //   (a, b) =>
        //     this.stringToDate(b.match_time) - this.stringToDate(a.match_time)
        // );
        this.setState({
          accountList: response.data,
          page: parseInt(response.data.current_page),
        });
      }
    });
  };
  onhandleChangeProfile = () => {
    this.setState({
      ...this.state,
      openDialogName: "chgProfile",
      openDialog: true,
    });
  };
  onhandleChangePassword = () => {
    this.setState({
      ...this.state,
      openDialogName: "chgPassword",
      openDialog: true,
    });
  };
  handleShareWechat = () => {
    window.open(
      "/wechatshare?url=" + Config.wechatSharUrl + "/profile/" + this.props.id,
      ""
    );
    CommonService.ShareProfileSuccess(this.props.token);
  };
  getWeiBoProfile = () => {
    WeiboService.WeiboLogin("/account");
  };
  stringToDate(_date) {
    var formatLowerCase = "mm/dd".toLowerCase();
    var formatItems = formatLowerCase.split("/");
    var SplitDate = _date.split(" ");
    var TimeItems = SplitDate[1].split(":");
    var dateItems = SplitDate[0].split("/");
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      2020,
      month,
      dateItems[dayIndex],
      TimeItems[0],
      TimeItems[1]
    );
    return formatedDate;
  }
  render() {
    return (
      <>
        <Loading show={this.state.show}></Loading>
        <Container style={{ height: "500px" }}>
          <Title>用户资料</Title>
          <Splitter></Splitter>
          <AccountContainer>
            <AvatarContainer>
              <AvatarImage
                src={
                  this.props.avatar_url === ""
                    ? DefaultAvatar
                    : Config.apiImgSrc + this.props.avatar_url
                }
              ></AvatarImage>
            </AvatarContainer>
            <AccountDetailContainer>
              <UserText>帐号：{this.props.username}</UserText>
              <ButtonContainer>
                <NextButton
                  onClick={this.onhandleChangeProfile}
                  variant="outlined"
                >
                  头像选择
                </NextButton>
                <NextButton
                  onClick={this.onhandleChangePassword}
                  variant="outlined"
                >
                  更改密码
                </NextButton>
              </ButtonContainer>
            </AccountDetailContainer>
          </AccountContainer>
          <ShareContainer>
            分享到
            <ShareIcon
              className="fa fa-weibo"
              style={{ fontSize: "50px", marginTop: "-5px" }}
              onClick={this.getWeiBoProfile}
            ></ShareIcon>
            <ShareIcon
              className="fa fa-wechat"
              style={{ fontSize: "48px", marginTop: "-4px" }}
              onClick={this.handleShareWechat}
            ></ShareIcon>
          </ShareContainer>
          <ContainerBorder
           src={Decoration04}
          ></ContainerBorder>
        </Container>
        <Container>
          <Title>历史推荐</Title>
          <Splitter></Splitter>
          <RecordContainer>
            <RecordList
              page={this.state.page}
              rows={this.state.accountList.data}
              total={this.state.accountList.last_page}
              onhandleChangePage={this.handleChangePage}
            ></RecordList>
          </RecordContainer>
          <ContainerBorder
            src={Decoration04}
          ></ContainerBorder>
        </Container>
        <StyledDialog
          fullWidth={true}
          maxWidth={false}
          open={this.state.openDialog}
          onClose={this.onhandleCloseDialog}
        >
          {(() => {
            if (this.state.openDialogName === "chgPassword") {
              return (
                <ChangePassword
                  onhandleCloseDialog={this.onhandleCloseDialog}
                ></ChangePassword>
              );
            } else if (this.state.openDialogName === "chgProfile") {
              return (
                <ChangeProfileImage
                  onhandleCloseDialog={this.onhandleCloseDialog}
                ></ChangeProfileImage>
              );
            } else {
              return <div></div>;
            }
          })()}
        </StyledDialog>
        <StyledDialog
          fullWidth={true}
          maxWidth={false}
          open={this.props.weiboName && this.state.posterDialog ? true : false}
          // open={true}
          onClose={this.onhandleClosePosterDialog}
        >
          <Poster
            onhandleClosePosterDialog={this.onhandleClosePosterDialog}
          ></Poster>
        </StyledDialog>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    id: state.account.id,
    avatar_url: state.account.avatar_url,
    weiboName: state.account.weiboName,
    token: state.account.token,
    username: state.account.username,
    winRate: state.account.winRate,
    evaluation: state.account.evaluation,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Account));
