import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
const StyledTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
  },
  head: {
    backgroundColor: "transparent",
    color: "#fff",
    fontSize: 22,
    paddingBottom: "20px",
  },
  body: {
    padding: "10px",
    fontSize: 22,
    color: "#fff",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(TableCell);

const BottomPagination = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
}))(Pagination);
const StyledTableRow = withStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#0d0c69!important",
      cursor: "pointer",
    },
  },
}))(TableRow);
const InnerWrap = styled.div`
  width: 100%;
  float: left;
  margin-top: 8px;
  box-sizing: border-box;
`;
const PaginationContainer = styled.div`
  margin: 40px auto;
  bottom: 0px;
  position: absolute;
  width: 1080px;
`;
const PredictResult = styled.div`
  width: 200px;
  float: left;
`;
const PredictItem = styled.div`
  background-color: ${(props) => props.colorAcitve};
  color: #fff;
  padding: 0px 8px;
  margin: 0px 10px;
  width: 111px;
  text-align: center;
  float: right;
`;
function RecordList(props) {
  const handleChangePage = (event, newPage) => {
    props.onhandleChangePage(newPage);
  };
  return (
    <InnerWrap>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{ width: "20%" }}>
              时间
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: "45%" }}>
              比赛项目
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: "45%" }}>
              组合
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row, index) => {
            return (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
                style={
                  index % 2 === 0
                    ? { backgroundColor: "#040a78" }
                    : { backgroundColor: "transparent" }
                }
              >
                <StyledTableCell align="center" style={{ width: "20%" }}>
                  {row.match_time}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "45%" }}>
                  {row.race_title}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ width: "45%" }}>
                  {row.team_a} 对 {row.team_b}
                </StyledTableCell>
                {/* <StyledTableCell align="center" style={{ width: "25%" }}>
                {row.prediction
                    .sort(function (a, b) {
                      if (a.type_id.toString() === "2") return 1;
                      else return -1;
                    }).slice(0, 1)
                    .map((value, index) => {
                      return (
                        <PredictResult key={index}>
                          <PredictItem
                            colorAcitve={
                              row.predict_result.toString() === "-1" ||
                              row.match_result[0].team_id.toString() === "-1" ||
                              row.match_result[1].team_id.toString() === "-1"
                                ? "transparent"
                                : row.predict_result.toString() === "1"
                                ? "#8c000e"
                                : (value.type_id.toString() ===  row.match_result[0].type_id.toString() &&
                                    value.team_id.toString() === row.match_result[0].team_id.toString() )
                                  ||
                                  (
                                  value.type_id.toString() ===  row.match_result[1].type_id.toString() &&
                                  value.team_id.toString() === row.match_result[1].team_id.toString() )
                                ? "#8c000e"
                                : "#000201"
                            }
                          >
                            {value.team_id.toString()===row.team_a_id.toString()?row.team_a:row.team_b}
                          </PredictItem>
                        </PredictResult>
                      );
                    })} */}
                  {/* {row.prediction
                    .sort(function (a, b) {
                      if (a.type_id.toString() === "1") return 1;
                      else return -1;
                    })
                    .map((value, index) => {
                      return (
                        <PredictResult key={index}>
                          {value.type_id === "1" ? "独赢" : "五杀"}:
                          <PredictItem
                            colorAcitve={
                              row.predict_result.toString() === "-1" ||
                              row.match_result[0].team_id.toString() === "-1" ||
                              row.match_result[1].team_id.toString() === "-1"
                                ? "transparent"
                                : row.predict_result.toString() === "1"
                                ? "#8c000e"
                                : (value.type_id.toString() ===  row.match_result[0].type_id.toString() &&
                                    value.team_id.toString() === row.match_result[0].team_id.toString() )
                                  ||
                                  (
                                  value.type_id.toString() ===  row.match_result[1].type_id.toString() &&
                                  value.team_id.toString() === row.match_result[1].team_id.toString() )
                                ? "#8c000e"
                                : "#000201"
                            }
                          >
                            {value.team_id.toString()===row.team_a_id.toString()?row.team_a:row.team_b}
                          </PredictItem>
                        </PredictResult>
                      );
                    })} */}
                {/* </StyledTableCell> */}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
      <PaginationContainer>
        <BottomPagination
          count={props.total}
          page={props.page}
          onChange={handleChangePage}
          variant="outlined"
          shape="rounded"
          size="large"
        />
      </PaginationContainer>
    </InnerWrap>
  );
}
export default RecordList;
